import React, { useCallback, useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../ui/helpers.js'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input, TextArea } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import CodeCopy, { copyOnClick } from '../ui/CodeCopy.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Toggle from '../ui/Toggle.js'
import Select from '../ui/Select.js'
import ColorPicker from '../ui/ColorPicker.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useModal } from '../Modal.js'
import ModalContainer from '../ui/ModalContainer.js'
import { useAuth } from '../Auth.js'
import api from '../api.js'
import * as LR from '@uploadcare/blocks'
import '@uploadcare/blocks/web/lr-basic.min.css'
import st from '../uploadcare.module.css'
const FormInput = formikFormWrapper(Input)

const ImageBox = styled(Flex)`
  height: 80px;
  width: 200px;
  max-width: 200px;
  border: 1px dashed #a6b7d4;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

LR.registerBlocks(LR)

const PreviewImage = styled(Image)`
  width: 600px;
  height: 300px;
  object-fit: contain;
`

const AddLogoModal = ({ showAddLogoModal, setShowAddLogoModal }) => {
  if (!showAddLogoModal) {
    return null
  }

  const { user, setUser } = useAuth()
  let dataOutputRef = useRef()
  let uploaderButton = useRef()
  const [file, setFile] = useState([])

  const classNames = ['lr-wgt-common', st.uploaderCfg].join(' ')

  const handleUploaderEvent = useCallback((e) => {
    const { data } = e.detail
    setFile(data[0])
  }, [])

  const saveImageToUser = async (logoUrl) => {
    const res = await api.post('/auth/organisation/update', { logoUrl })
    if (res.data.success) {
      const authRes = await api.get('/auth')
      setUser(authRes.data.payload)
      toast('Logo saved')
      setShowAddLogoModal(false)
    } else {
      toast('Error updating user')
    }
  }

  useEffect(() => {
    let el = dataOutputRef.current
    el.addEventListener('lr-data-output', handleUploaderEvent)
    return () => {
      el.removeEventListener('lr-data-output', handleUploaderEvent)
    }
  }, [handleUploaderEvent])

  return (
    <ModalContainer interiorClose width="990px" height="550px" isOpen={true} setIsOpen={setShowAddLogoModal}>
      <Flex height="100%" justifyContent="space-between">
        <Flex flexDirection="column" maxWidth="211px" borderRight="1px solid #EDF2F7;" pr="16px">
          <H3 fontSize="20px" lineHeight="32px">
            Brand your video player
          </H3>
          <Text light mb="16px">
            Add your branding. Try to use an image with a transparent background behind your image.
          </Text>
        </Flex>
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            {!user.logoUrl && !file?.uuid ? (
              <ImageBox
                onClick={() => {
                  document.querySelector('#uploadcare-uploader button').click()
                }}
              >
                <Icon width={16} height={16} icon="plus-grey" />
              </ImageBox>
            ) : (
              <>
                <PreviewImage src={file?.uuid ? `https://ucarecdn.com/${file.uuid}/` : user.logoUrl} alt="Preview" />
              </>
            )}
          </Flex>
          <Flex justifyContent="flex-end" height="auto">
            <Flex>
              <Button
                ml="40px"
                variant="white"
                shadow
                onClick={() => {
                  saveImageToUser('')
                }}
                label="Remove"
              />
            </Flex>
            <Button
              mr="16px"
              variant="grey"
              onClick={() => {
                document.querySelector('#uploadcare-uploader button').click()
              }}
              label="Change"
            />
            <Button variant="black" label="Save" onClick={() => saveImageToUser(`https://ucarecdn.com/${file.uuid}/`)} />
          </Flex>
        </Flex>
        <Flex flexDirection="column" justifyContent="center" alignItems="center" width="0px" height="0px" overflow="hidden">
          <lr-file-uploader-regular id="uploadcare-uploader" ref={uploaderButton} class={classNames}></lr-file-uploader-regular>

          <lr-data-output ref={dataOutputRef} use-event class={classNames} onEvent={handleUploaderEvent}></lr-data-output>
        </Flex>
      </Flex>
    </ModalContainer>
  )
}

export default AddLogoModal
