import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label, H2, LineClamp } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { Formik, Field } from 'formik'
import SelectComponent from '../ui/Select.js'
import { currencyOptions } from './types.js'
import { Input, TextArea } from '../ui/Input.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Button from '../ui/Button.js'
import ImageUploadModal from '../ui/ImageUploadModal.js'
import Image from '../ui/Image.js'
import Icon from '../ui/Icon.js'
import { useTheme } from '../ThemeProvider.js'
import CircleToggle from '../ui/CircleToggle.js'
import { currencySymbols, minutesFormat, toTitleCase } from '../ui/formatters.js'
import { props } from 'bluebird'
import { useContent } from '../ContentProvider.js'
import Carousel from '../ui/Carousel.js'

const FontText = styled(Text)`
  color: ${(props) => props.config.secondaryFontColor};
  font-family: ${(props) => (props.light ? props.config?.secondaryFontFamily : props.config?.primaryFontFamily)} !important;
`

const PrimaryText = styled(Text)`
  color: ${(props) => (props.light ? props.config.secondaryFontColor : props.config.primaryFontColor)};
  font-family: ${(props) => (props.light ? props.config?.secondaryFontFamily : props.config?.primaryFontFamily)} !important;
`
const PrimaryLineClamp = styled(LineClamp)`
  color: ${(props) => (props.light ? props.config.secondaryFontColor : props.config.primaryFontColor)};
  font-family: ${(props) => (props.light ? props.config?.secondaryFontFamily : props.config?.primaryFontFamily)} !important;
`

const Styler = styled(Flex)`
  h1,
  h2,
  h3 {
    color: ${(props) => props.config.primaryFontColor};
    font-family: ${(props) => props.config.primaryFontFamily} !important;
  }
  button.primary {
    height: 48px;
    p {
      font-size: 16px;
    }
    border-radius: ${(props) => {
      if (props.config.primaryButtonRound === 'PILL') {
        return '50px'
      }
      if (props.config.primaryButtonRound === 'ROUNDED') {
        return '8px'
      }
      if (props.config.primaryButtonRound === 'SQUARE') {
        return '0px'
      }
    }} !important;
    ${(props) => {
      if (props.config.primaryButtonFill === 'FILL') {
        return `background-color: ${props.config.primaryButtonColor};
      color: ${props.config.primaryButtonFontColor};`
      }
      if (props.config.primaryButtonFill === 'OUTLINE') {
        return `border: 1px solid ${props.config.primaryButtonColor};
      background-color: transparent;
      color: ${props.config.primaryButtonFontColor};`
      }
    }} !important;
    font-family: ${(props) => props.config.secondaryFontFamily} !important;
  }
  button.secondary {
    height: 48px;

    p {
      font-size: 16px !important;
    }

    border-radius: ${(props) => {
      if (props.config.secondaryButtonRound === 'PILL') {
        return '50px'
      }
      if (props.config.secondaryButtonRound === 'ROUNDED') {
        return '8px'
      }
      if (props.config.secondaryButtonRound === 'SQUARE') {
        return '0px'
      }
    }} !important;
    ${(props) => {
      if (props.config.secondaryButtonFill === 'FILL') {
        return `background-color: ${props.config.secondaryButtonColor};
      color: ${props.config.secondaryButtonFontColor};`
      }
      if (props.config.secondaryButtonFill === 'OUTLINE') {
        return `border: 1px solid ${props.config.secondaryButtonColor};
      background-color: transparent;
      color: ${props.config.secondaryButtonFontColor};`
      }
    }} !important;
    font-family: ${(props) => props.config.secondaryFontFamily} !important;
  }
`

const CookingTime = ({ totalTime, config }) => {
  return (
    <Flex alignItems="center">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
          fill={config.secondaryFontColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.5 3C7.77614 3 8 3.22386 8 3.5V8.70984L11.2481 10.5659C11.4878 10.7029 11.5711 11.0083 11.4341 11.2481C11.2971 11.4878 10.9917 11.5711 10.7519 11.4341L7.25193 9.43412C7.09614 9.3451 7 9.17943 7 9V3.5C7 3.22386 7.22386 3 7.5 3Z"
          fill={config.secondaryFontColor}
        />
      </svg>
      <FontText config={config} ml="8px" fontSize="14px" light>
        {minutesFormat(totalTime)}
      </FontText>
    </Flex>
  )
}

const ResponsiveContainer = styled(Flex)`
  @media (min-width: 1600px) {
    height: 800px;
    width: 414px;
  }
`

const HeroImageContainer = styled(Flex)`
  position: relative;
  height: 657px;
  border-radius: 16px;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
`

const HeroImage = styled(Image)`
  position: absolute;
  left: 0;
  top: 0;
`

const HeroDarken = styled(Box)`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 100%);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
`

const Title = styled(H1)`
  color: white;
  font-weight: 900;
  line-height: 100%;
  font-size: 50px;
`

const Preview = ({ values: config }) => {
  let { user, setUser } = useAuth()
  const { recipes } = useContent()

  const recipesToShow =
    !recipes || recipes.length < 10
      ? [...(recipes || []), {}, {}, {}, {}, {}, {}, {}].filter((el) => el.publishedAt)
      : recipes.filter((el) => el.publishedAt).slice(0, 10)
  const lastestRecipe = recipesToShow && recipesToShow[0]
  const latestRecipeCoverImage = lastestRecipe?.coverImageUrl
    ? `${lastestRecipe?.coverImageUrl}?width=600`
    : lastestRecipe?.playbackId
    ? `https://image.mux.com/${lastestRecipe?.playbackId}/thumbnail.jpg?time=0&width=600`
    : undefined
  return (
    <Styler config={config} width="auto">
      <ResponsiveContainer
        backgroundColor={config.backgroundColor || 'white'}
        height="657px"
        width="370px"
        borderRadius="32px"
        boxShadow="0px 4px 8px 4px rgba(118, 118, 118, 0.25)"
        flexDirection="column"
        alignItems="center"
        overflowY="scroll"
      >
        <Flex justifyContent="space-between" alignItems="center" px="16px" height="60px" minHeight="60px">
          <Box height="40px">
            <Image src={config.logo ? `${config.logo}?width=300` : '/logo.svg'} width="100%" height="100%" objectFit="contain" />
          </Box>
          <Flex width="100%" alignItems="center" justifyContent="flex-end">
            <Box height="24px">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.75 18C14.3063 18 18 14.3063 18 9.75C18 5.19365 14.3063 1.5 9.75 1.5C5.19365 1.5 1.5 5.19365 1.5 9.75C1.5 14.3063 5.19365 18 9.75 18ZM19.5 9.75C19.5 15.1348 15.1348 19.5 9.75 19.5C4.36522 19.5 0 15.1348 0 9.75C0 4.36522 4.36522 0 9.75 0C15.1348 0 19.5 4.36522 19.5 9.75Z"
                  fill={config.accentColor}
                />
                <path
                  d="M15.5158 17.6132C15.5601 17.6732 15.6093 17.7307 15.6636 17.785L21.4393 23.5607C22.0251 24.1465 22.9749 24.1465 23.5607 23.5607C24.1465 22.9749 24.1465 22.0251 23.5607 21.4393L17.785 15.6636C17.7307 15.6093 17.6732 15.5601 17.6132 15.5158C17.0248 16.3169 16.3169 17.0248 15.5158 17.6132Z"
                  fill={config.accentColor}
                />
              </svg>
            </Box>
            <Box width="16px" />
            <Box height="24px">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3 3C3 1.34315 4.34315 0 6 0H18C19.6569 0 21 1.34315 21 3V23.25C21 23.5266 20.8478 23.7807 20.6039 23.9113C20.36 24.0418 20.0641 24.0275 19.834 23.874L12 19.6514L4.16603 23.874C3.93588 24.0275 3.63997 24.0418 3.39611 23.9113C3.15224 23.7807 3 23.5266 3 23.25V3ZM6 1.5C5.17157 1.5 4.5 2.17157 4.5 3V21.8486L11.584 18.126C11.8359 17.958 12.1641 17.958 12.416 18.126L19.5 21.8486V3C19.5 2.17157 18.8284 1.5 18 1.5H6Z"
                  fill={config.accentColor}
                />
              </svg>
            </Box>
            <Box width="24px" />

            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="23" viewBox="0 0 30 23" fill="none">
              <path
                d="M0 0.75C0 0.335786 0.335786 0 0.75 0H29.25C29.6642 0 30 0.335786 30 0.75C30 1.16421 29.6642 1.5 29.25 1.5H0.749999C0.335786 1.5 0 1.16421 0 0.75Z"
                fill={config.accentColor}
              />
              <path
                d="M0 11.25C0 10.8358 0.335786 10.5 0.75 10.5H29.25C29.6642 10.5 30 10.8358 30 11.25C30 11.6642 29.6642 12 29.25 12H0.749999C0.335786 12 0 11.6642 0 11.25Z"
                fill={config.accentColor}
              />
              <path
                d="M0 21.75C0 21.3358 0.335786 21 0.75 21H29.25C29.6642 21 30 21.3358 30 21.75C30 22.1642 29.6642 22.5 29.25 22.5H0.749999C0.335786 22.5 0 22.1642 0 21.75Z"
                fill={config.accentColor}
              />
            </svg>
          </Flex>
        </Flex>
        <Flex flexDirection="column" mb="80px">
          <Flex px="16px" flexDirection="column">
            <HeroImageContainer alignItems="center" justifyContent="center" mb="80px">
              <HeroImage
                src={config.homepageImageUrl + '?width=2000'}
                srcset={
                  config.homepageImageUrl +
                  '?width=3000 2000w,' +
                  config.homepageImageUrl +
                  '?width=2000 1000w,' +
                  config.homepageImageUrl +
                  '?width=1400 700w'
                }
                alt="homepage-image"
                sizes="100vmin"
                width="100%"
                height="100%"
                objectFit="cover"
              />
              <HeroDarken />
              <Flex
                flexDirection="column"
                zIndex={1}
                position="relative"
                height="100%"
                alignItems="center"
                justifyContent="center"
                maxWidth="1000px"
              >
                <Title mb="32px" color="white !important" textAlign="center">
                  {config.homepageTitle}
                </Title>
                <FontText
                  config={config}
                  light
                  fontWeight="500"
                  fontSize="18px"
                  lineHeight="136.023%"
                  mb="32px"
                  maxWidth="800px"
                  color="white !important"
                  textAlign="center"
                >
                  {config.homepageBody}
                </FontText>

                <Button className="primary" label={config.homepageCta} />
              </Flex>
            </HeroImageContainer>
          </Flex>
          <Flex noScrollBar overflowX="scroll" alignItems="center" mb="24px">
            <Button
              color={config.backgroundColor}
              backgroundColor={config.primaryFontColor}
              fontFamily={config.primaryFontFamily}
              borderRadius="40px"
              height="40px"
              fontSize="14px !important"
              label="Latest"
              width="80px"
              minWidth="80px"
              fontWeight="500"
              className="primary"
              ml="16px"
              mr="24px"
            />
            <PrimaryText
              color={config.primaryFontColor}
              config={config}
              fontSize="14px"
              fontFamily={config.primaryFontFamily}
              fontWeight="500"
              mr="32px"
            >
              Favourites
            </PrimaryText>

            <PrimaryText
              color={config.primaryFontColor}
              config={config}
              fontSize="14px"
              fontFamily={config.primaryFontFamily}
              fontWeight="500"
              mr="32px"
            >
              Plans
            </PrimaryText>

            <PrimaryText
              color={config.primaryFontColor}
              config={config}
              fontSize="14px"
              fontFamily={config.primaryFontFamily}
              fontWeight="500"
              mr="32px"
              whiteSpace="nowrap"
            >
              All recipes
            </PrimaryText>
          </Flex>
          <Carousel
            noChevron
            slideWidth={240}
            spacing={16}
            cards={recipesToShow?.map((recipe) => {
              const image = recipe.coverImageUrl
                ? `${recipe.coverImageUrl}?width=600`
                : recipe.playbackId
                ? `https://image.mux.com/${recipe.playbackId}/thumbnail.jpg?time=0&width=600`
                : undefined

              return (
                <Box key={recipe.id} width="240px">
                  <Flex
                    height="300px"
                    bg="rgba(238, 238, 238, 1)"
                    mb="16px"
                    borderRadius={config.cardStyle === 'EXTRA_ROUNDED' ? '16px' : config.cardStyle === 'ROUNDED' ? '8px' : '0px'}
                  >
                    <Image
                      alt={recipe.name}
                      src={image || '/illustrations/placeholder.png'}
                      width="100%"
                      height="100%"
                      objectFit="cover"
                      borderRadius={
                        config.cardStyle === 'EXTRA_ROUNDED' ? '16px' : config.cardStyle === 'ROUNDED' ? '8px' : '0px'
                      }
                    />
                  </Flex>
                  <PrimaryLineClamp
                    wordBreak="break-word"
                    lines={2}
                    fontFamily={`${config.primaryFontFamily} !important`}
                    color={config.primaryFontColor}
                    config={config}
                    fontSize="14px"
                    fontWeight="500"
                    mb="8px"
                    lineHeight="130%"
                  >
                    {recipe?.name || 'Recipe'}
                  </PrimaryLineClamp>
                  <CookingTime config={config} totalTime={(recipe.totalTime || 0) + (recipe.cookingTime || 0) || 30} />
                </Box>
              )
            })}
            pl="16px"
          />
        </Flex>

        <Flex flexDirection="column" mb="40px" px="16px">
          <FontText config={config} light fontSize="14px" mb="8px">
            Latest Recipe
          </FontText>
          <H2 config={config} wordBreak="break-word" fontWeight="800" fontSize="40px" mb="8px" lineHeight="normal">
            {lastestRecipe ? lastestRecipe.name : 'My favourite dish'}
          </H2>
          <FontText wordBreak="break-word" config={config} fontWeight="400" light fontSize="18px" lineHeight="136.023%" mb="32px">
            {lastestRecipe ? lastestRecipe.intro : 'Simple to cook and delicious to eat'}
          </FontText>
          <Flex alignItems="center">
            <Box>
              <Button mr="16px" className="primary" label="Cook now" />
            </Box>
            <Flex width="auto" alignItems="center">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                  fill={config.secondaryFontColor}
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.5 3C7.77614 3 8 3.22386 8 3.5V8.70984L11.2481 10.5659C11.4878 10.7029 11.5711 11.0083 11.4341 11.2481C11.2971 11.4878 10.9917 11.5711 10.7519 11.4341L7.25193 9.43412C7.09614 9.3451 7 9.17943 7 9V3.5C7 3.22386 7.22386 3 7.5 3Z"
                  fill={config.secondaryFontColor}
                />
              </svg>
              {lastestRecipe?.totalTime || lastestRecipe?.cookingTime ? (
                <FontText ml="4px" config={config} fontWeight="400" light fontSize="16px" lineHeight="normal">
                  {minutesFormat((lastestRecipe?.totalTime || 0) + (lastestRecipe?.cookingTime || 0))}
                </FontText>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
        <Flex width="100%" height="540px" minHeight="540px" px="16px" mb="80px">
          <Image
            src={latestRecipeCoverImage || '/illustrations/placeholderImage.png'}
            alt="homepage-image"
            width="100%"
            height="100%"
            objectFit="cover"
            borderRadius="16px"
          />
        </Flex>
      </ResponsiveContainer>
    </Styler>
  )
}

export default Preview
