import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, formatSeconds } from '../ui/helpers'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography'
import { Flex, Box, Container } from '../ui/Layout'
import Image from '../ui/Image'
import { Input } from '../ui/Input'
import Icon from '../ui/Icon'
import Button from '../ui/Button'
import { useContent, useVideos } from '../ContentProvider'
import ModalContainer from '../ui/ModalContainer'
import { uniq, difference } from 'ramda'

const fonts = [
  {
    type: 'Serif',
    fonts: [
      'Alegreya',
      'Bitter',
      'Cardo',
      'Cormorant Garamond',
      'Crimson Text',
      'EB Garamond',
      'Libre Baskerville',
      'Lora',
      'Merriweather',
      'Noto Serif',
      'Playfair Display',
      'PT Serif',
      'Roboto Slab',
      'Rokkitt',
      'Source Serif Pro',
      'Spectral',
      'Tinos',
      'Vollkorn',
      'Zilla Slab'
    ]
  },
  {
    type: 'Sans Serif',
    fonts: [
      'Arial',
      'Asap',
      'Assistant',
      'Barlow',
      'Cabin',
      'Fira Sans',
      'Heebo',
      'IBM Plex Sans',
      'Inter',
      'Karla',
      'Lato',
      'Manrope',
      'Merriweather Sans',
      'Montserrat',
      'Muli',
      'Mulish',
      'Noto Sans',
      'Nunito',
      'Open Sans',
      'Overpass',
      'Oxygen',
      'Poppins',
      'Quicksand',
      'Raleway',
      'Roboto',
      'Rubik',
      'Source Sans Pro',
      'Titillium Web',
      'Ubuntu',
      'Varela Round',
      'Work Sans'
    ]
  },
  {
    type: 'Display',
    fonts: [
      'Abril Fatface',
      'Anton',
      'Archivo Black',
      'Chewy',
      'Concert One',
      'Cookie',
      'Fjalla One',
      'Fredoka One',
      'Great Vibes',
      'Josefin Slab',
      'Lobster',
      'Oswald',
      'Pacifico',
      'Righteous',
      'Sacramento',
      'Satisfy',
      'Teko'
    ]
  },
  {
    type: 'Monospace',
    fonts: [
      'Anonymous Pro',
      'Courier Prime',
      'Cousine',
      'Cutive Mono',
      'DM Mono',
      'Fira Code',
      'IBM Plex Mono',
      'Inconsolata',
      'JetBrains Mono',
      'Lekton',
      'Nova Mono',
      'Overpass Mono',
      'Oxygen Mono',
      'Roboto Mono',
      'Share Tech Mono',
      'Source Code Pro',
      'Space Mono',
      'Ubuntu Mono',
      'VT323'
    ]
  },
  {
    type: 'Handwritten & Script',
    fonts: [
      'Alex Brush',
      'Allura',
      'Amatic SC',
      'Bad Script',
      'Caveat',
      'Courgette',
      'Dancing Script',
      'Handlee',
      'Indie Flower',
      'Kalam',
      'Kaushan Script',
      'Lobster Two',
      'Patrick Hand',
      'Rancho',
      'Shadows Into Light',
      'Tangerine'
    ]
  }
]

const FontDisplay = styled(Text)`
  font-family: ${(props) => props.fontFamily};
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  ${(props) => (props.selected ? `background-color: ${colors.weakGreen};` : '')}
  &:hover {
    background-color: rgba(235, 242, 250, 1);
  }
`

const FontPicker = ({ saveFunction, isOpen, setIsOpen, selectedFont: inputSelectedFont }) => {
  const [selectedFont, setSelectedFont] = useState(inputSelectedFont)

  const save = (font) => {
    saveFunction && saveFunction(font)
  }

  return (
    <ModalContainer noClose p="0px" width="440px" autoHeight isOpen={isOpen} setIsOpen={setIsOpen}>
      <Flex flexDirection="column">
        <Box px="24px" py="16px" borderBottom="1px solid #E4ECF7">
          <H3 fontWeight="600" mb="0px">
            Choose a font
          </H3>
        </Box>
        <Flex flexDirection="column" p="24px" height="400px" overflowY="scroll">
          {fonts.map(({ type, fonts }) => {
            return (
              <Flex key={type} flexDirection="column" mb="24px">
                <Text light fontSize="12px" fontWeight="600" ml="8px">
                  {type.toUpperCase()}
                </Text>
                {fonts.map((font) => (
                  <FontDisplay
                    onClick={() => {
                      setSelectedFont(font)
                      save(font)
                    }}
                    key={font}
                    selected={selectedFont === font}
                    fontFamily={font}
                    cursor="pointer"
                    py="16px"
                    px="10px"
                  >
                    {font}
                  </FontDisplay>
                ))}
              </Flex>
            )
          })}
        </Flex>
      </Flex>
      <Flex px="24px" py="8px" borderTop="1px solid #E4ECF7">
        <Button
          zIndex={1}
          variant="green"
          label="Close"
          width="100%"
          onClick={() => {
            setIsOpen(false)
          }}
        />
      </Flex>
    </ModalContainer>
  )
}

export default FontPicker
