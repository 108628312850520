import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../ui/helpers.js'
import { H1, H2, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'

window.URL = window.URL || window.webkitURL

const UpgradeContainer = styled(Flex)`
  background: linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%);
  height: 37px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 8px;
  width: calc(100% - 40px);
  width: 191px;
  margin-top: 50px;
  justify-content: center;
`

export const SpinningIcon = styled(Icon)`
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  ${(props) =>
    !props.finished &&
    `-webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;`}
`

export const ProgressBar = styled(Box)`
  background-color: ${colors.backgroundGrey};
  height: 16px;
  border-radius: 16px;
  -webkit-transition: width 1s;
  -moz-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
`

export const ProgressBarProgress = styled(ProgressBar)`
  background-color: rgba(91, 179, 249, 1);
  ${(props) => (props.finished ? 'background-color:rgba(140, 223, 179, 1);' : null)}
`
