import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors, w } from '../ui/helpers.js'
import { H1, H2, Text, Span, GradientFont, Error } from '../ui/Typography.js'
import { Flex, Box } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import ChatButton from '../Components/ChatButton.js'
import Button from '../ui/Button.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api, { setAuthCookie } from '../api.js'
import { useAuth } from '../Auth.js'

const SignInText = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`

const PrivacyTermsFont = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`

const PrivacyTerms = styled(Flex)`
  width: auto;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  left: 76px;
  bottom: 24px;
`

export const PrivacyTermsComponent = () =>
  null
  // <PrivacyTerms>
  //   <a target="_blank" href="https://clipara.notion.site/Terms-of-Service-d325043415294d87b368b03bc399c30c" rel="noreferrer">
  //     <PrivacyTermsFont light>Terms of Service</PrivacyTermsFont>
  //   </a>
  //   <PrivacyTermsFont mx="8px">|</PrivacyTermsFont>
  //   <a
  //     target="_blank"
  //     href="https://clipara.notion.site/clipara/Privacy-Policy-bcd2f58772cd41d08a56edc839657b59"
  //     rel="noreferrer"
  //   >
  //     <PrivacyTermsFont light>Privacy Policy</PrivacyTermsFont>
  //   </a>
  // </PrivacyTerms>

const FormInput = formikFormWrapper(Input)

const Login = (props) => {
  let auth = useAuth()
  const [error, setError] = useState('')
  const [search, setSearch] = useSearchParams()

  let navigate = useNavigate()

  return (
    <Formik
      initialValues={{ email: search.get('email') || '', password: '', shopifyShopId: search.get('shopifyShopId') || undefined }}
      validate={(values) => {
        const errors = {}
        if (!values.email) {
          errors.email = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address'
        }
        if (!values.password) {
          errors.password = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setError('')
        setSubmitting(true)
        try {
          const res = await api.post('/login', values)
          console.log(res.data)
          setAuthCookie(res.data.token)
          if (res.data.success) {
            const authRes = await api.get('/auth')
            auth.setUser(authRes.data.payload)
            return navigate('/')
          } else {
            setError(res.data.message)
          }
        } catch (e) {
          console.log(e)
        }
        setSubmitting(false)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
        /* and other goodies */
      }) => (
        <Flex>
          <ChatButton topRightFixed />
          <Flex
            display={w < 981 ? 'none' : 'flex'}
            flexDirection="column"
            p="76px"
            width="586px"
            minWidth="586px"
            maxWidth="50%"
            height="100%"
            minHeight="100vh"
            bg={colors.darkGreen}
          >
            <Image mb="160px" src="/logoWhite.svg" width={186} />
            <GradientFont mb="25px">Time to get creative.</GradientFont>
            <Text light color="white">
              What will you make today?
            </Text>
          </Flex>
          <Flex position="relative" flexDirection="column" alignItems="center" width="100%" p="76px">
            <Flex flexDirection="column" maxWidth="535px" height="100%" justifyContent="center">
              <H1 mb="12px" fontSize="28px">
                Welcome back!
              </H1>
              <H2 mb="55px">Let's build something great</H2>
              <Field
                name="email"
                label="Email"
                large
                mb="55px"
                placeholder="Type your email address"
                type="email"
                component={FormInput}
              />
              <Field
                mb="16px"
                name="password"
                label="Password"
                large
                placeholder="Type your password"
                type="password"
                component={FormInput}
              />
              <Link to="/send-password-reset">
                <Text>Forgot your password?</Text>
              </Link>
              <Button large mt="55px" mb="8px" variant="green" label="Login" onClick={handleSubmit} />
              <Error mb="8px">{error}</Error>
              <SignInText light>
                Don't have an account?{' '}
                <Link to={'/signup' + (window.location.href.includes('?') ? `?${window.location.href.split('?')[1]}` : '')}>
                  <Span link>Get started</Span>
                </Link>
              </SignInText>
            </Flex>

            <PrivacyTermsComponent />
          </Flex>
        </Flex>
      )}
    </Formik>
  )
}

export default Login
