import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors, w } from '../ui/helpers.js'
import { H1, H2, Text, Span, GradientFont, Error, Label } from '../ui/Typography.js'
import { Flex, Box } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import ChatButton from '../Components/ChatButton.js'
import Button from '../ui/Button.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api, { setAuthCookie } from '../api.js'
import { useAuth } from '../Auth.js'
import { PrivacyTermsComponent } from './Login.js'

const SignInText = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`

const PrivacyTermsFont = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`

const PrivacyTerms = styled(Flex)`
  width: auto;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  left: 76px;
  bottom: 24px;
`

const FormInput = formikFormWrapper(Input)

const Subdomain = (props) => {
  let auth = useAuth()
  const [error, setError] = useState('')
  const [search, setSearch] = useSearchParams()
  console.log(auth.user)
  let navigate = useNavigate()

  return (
    <Formik
      initialValues={{
        subdomain: auth?.user?.subdomain,
        name: auth?.user?.organisationName
      }}
      validate={(values) => {
        const errors = {}
        if (!values.subdomain) {
          errors.subdomain = 'Required'
        }
        if (values.subdomain && !values.subdomain.match(/^[a-zA-Z0-9-]*$/)) {
          errors.subdomain = 'No special characters or spaces allowed'
        }
        if (values.subdomain && values.subdomain.toLowerCase() === 'app') {
          errors.subdomain = 'App is a reserved subdomain'
        }
        if (values.subdomain && values.subdomain.toLowerCase() === 'apptest') {
          errors.subdomain = 'Apptest is a reserved subdomain'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setError('')
        setSubmitting(true)
        try {
          const res = await api.post('/auth/update-subdomain', { ...values, subdomain: values.subdomain.toLowerCase().trim() })
          console.log(res.data)
          if (res.data.success) {
            const authRes = await api.get('/auth')
            auth.setUser(authRes.data.payload)
            return navigate('/')
          } else {
            setError(res.data.message)
          }
        } catch (e) {
          console.log(e)
        }
        setSubmitting(false)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
        /* and other goodies */
      }) => (
        <Flex>
          <ChatButton topRightFixed />
          <Flex
            display={w < 981 ? 'none' : 'flex'}
            flexDirection="column"
            p="76px"
            width="586px"
            minWidth="586px"
            maxWidth="50%"
            height="100%"
            minHeight="100vh"
            bg={colors.darkGreen}
          >
            <Image mb="160px" src="/logoWhite.svg" width={186} />
            <GradientFont>Welcome to the Clubb</GradientFont>
          </Flex>
          <Flex position="relative" flexDirection="column" alignItems="center" width="100%" justifyContent="center" p="76px">
            <Flex flexDirection="column" maxWidth="535px">
              <H1 fontSize="28px" mb="24px">
                Give your Clubb a name
              </H1>
              <Field name="name" mb="55px" label="Name" large component={FormInput} largeTextPostFix />
              <Field
                name="subdomain"
                label="Domain"
                large
                mb="4px"
                textPostFix=".theclubb.co"
                component={FormInput}
                largeTextPostFix
              />
              <Text
                mt={errors && errors.subdomain && touched && touched.subdomain ? '16px' : '0px'}
                mb="51px"
                fontSize="12px"
                light
              >
                You can always change this at a later date
              </Text>

              <Button large mb="8px" variant="green" label="Next" onClick={handleSubmit} />
              <Error mb="8px">{error}</Error>
            </Flex>

            <PrivacyTermsComponent />
          </Flex>
        </Flex>
      )}
    </Formik>
  )
}

export default Subdomain
