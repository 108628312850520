export const BUTTON_ROUNDING = {
  PILL: 'PILL',
  ROUNDED: 'ROUNDED',
  SQUARE: 'SQUARE'
}

export const BUTTON_FILL = {
  OUTLINE: 'OUTLINE',
  FILL: 'FILL'
}
export const buttonOptions = [
  { buttonRound: BUTTON_ROUNDING.PILL, buttonFill: BUTTON_FILL.FILL },
  { buttonRound: BUTTON_ROUNDING.ROUNDED, buttonFill: BUTTON_FILL.FILL },
  { buttonRound: BUTTON_ROUNDING.SQUARE, buttonFill: BUTTON_FILL.FILL },
  { buttonRound: BUTTON_ROUNDING.PILL, buttonFill: BUTTON_FILL.OUTLINE },
  { buttonRound: BUTTON_ROUNDING.ROUNDED, buttonFill: BUTTON_FILL.OUTLINE },
  { buttonRound: BUTTON_ROUNDING.SQUARE, buttonFill: BUTTON_FILL.OUTLINE }
]

export const CARD_OPTIONS = {
  SQUARE: 'SQUARE',
  ROUNDED: 'ROUNDED',
  EXTRA_ROUNDED: 'EXTRA_ROUNDED'
}
