import React, { useRef, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const PopoverWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`

const Popover = ({ children, content, isOpen, onClose, position = 'right' }) => {
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 })
  const triggerRef = useRef(null)
  const popoverRef = useRef(null)

  useEffect(() => {
    if (isOpen && triggerRef.current && popoverRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect()
      const popoverRect = popoverRef.current.getBoundingClientRect()

      setPopoverPosition({
        top: triggerRect.bottom + window.scrollY + 8,
        left:
          position === 'left'
            ? triggerRect.left + window.scrollX - popoverRect.width + triggerRect.width
            : triggerRect.left + window.scrollX
      })
    }
  }, [isOpen, position])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target) && !triggerRef.current.contains(event.target)) {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, onClose])

  return (
    <>
      <div ref={triggerRef}>{children}</div>
      {isOpen &&
        ReactDOM.createPortal(
          <PopoverWrapper ref={popoverRef} style={{ top: popoverPosition.top, left: popoverPosition.left }}>
            {content}
          </PopoverWrapper>,
          document.body
        )}
    </>
  )
}

export default Popover
