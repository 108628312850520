import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { colors, formatSeconds } from './helpers'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from './Typography'
import { Flex, Box, Container } from './Layout'
import Icon from './Icon'
import Button from './Button'

import ModalContainer from './ModalContainer'
import { uniq, difference } from 'ramda'
import { toast } from 'react-toastify'
import { ReactSortable } from 'react-sortablejs'
import { useContent } from '../ContentProvider'
import { Input } from './Input'

const GrabIcon = styled(Icon)`
  cursor: grab;
`

const TagGroupModal = ({ inputTagGroup, setIsOpen, saveFunction, deleteGroup }) => {
  const { recipes, refresh } = useContent()
  const [newTagGroupName, setTagGroupName] = useState(inputTagGroup?.name)
  const allTags = uniq(
    recipes
      .map((r) => r.tags)
      .flat()
      .filter((t) => t && t.trim())
  )
  const [tags, setTags] = useState(inputTagGroup?.tags || [])
  const [isEditingName, setIsEditingName] = useState(!inputTagGroup?.name || false)
  const inputRef = useRef(null)
  console.log({ tags, newTagGroupName })

  useEffect(() => {
    setTags(inputTagGroup?.tags || [])
    setTagGroupName(inputTagGroup?.name || '')
    setIsEditingName(!inputTagGroup?.name || false)
  }, [!!inputTagGroup])

  const save = async () => {
    saveFunction({ tags, name: newTagGroupName })
  }

  return (
    <ModalContainer
      interiorClose
      p="0px"
      width={'700px'}
      autoHeight
      isOpen={!!inputTagGroup}
      setIsOpen={setIsOpen}
      onClose={() => setIsOpen(false)}
    >
      <Flex height="60px" alignItems="center" px="24px" borderBottom="1px solid #EDF2F7">
        {!isEditingName ? (
          <Flex width="auto">
            <H3 mb="0px">{newTagGroupName}</H3>
            <Icon mt="2px" icon="pencil-square-grey" ml="8px" onClick={() => setIsEditingName(true)} />
          </Flex>
        ) : (
          <Input
            autoFocus
            ref={inputRef}
            value={newTagGroupName}
            onChange={(e) => setTagGroupName(e.target.value)}
            width="300px"
            large
            placeholder="Give the group a name"
          />
        )}
      </Flex>
      <Flex>
        <Flex flexDirection="column" p="24px" pb="0px">
          <Text light mb="16px" fontWeight="500">
            Select tags:
          </Text>
          <Flex flexWrap="wrap" width="auto" maxHeight="310px" overflowY="scroll">
            {allTags.map((l) => {
              const isSelected = tags.includes(l)
              return (
                <Flex
                  alignItems="center"
                  backgroundColor={isSelected ? 'rgba(166, 183, 212, 1)' : 'rgba(225, 232, 255, 1)'}
                  width="auto"
                  height="28px"
                  mr="8px"
                  mb="8px"
                  borderRadius="14px"
                  px="16px"
                  key={l}
                  onClick={() => {
                    if (tags.includes(l)) {
                      setTags(uniq(tags).filter((t) => t !== l))
                    } else {
                      setTags(uniq(tags).concat(l))
                    }
                  }}
                  cursor="pointer"
                >
                  <Text mb="2px" color={isSelected ? 'white' : colors.textLight} fontWeight="500">
                    {l}
                  </Text>
                </Flex>
              )
            })}
          </Flex>
        </Flex>

        <Flex backgroundColor="#F7FAFC" flexDirection="column" p="24px" pb="0px" overflowY="scroll">
          <Text light mb="16px" fontWeight="500">
            Your selections:
          </Text>

          <Flex maxHeight="332px" height="232px" overflowY="scroll" flexDirection="column">
            {tags
              .sort((a, b) => {
                return a.toLowerCase().localeCompare(b.toLowerCase())
              })
              .map((l) => {
                return (
                  <Flex key={l} alignItems="center" mb="8px">
                    <Flex
                      alignItems="center"
                      backgroundColor={'rgba(225, 232, 255, 1)'}
                      width="auto"
                      height="28px"
                      mr="8px"
                      borderRadius="14px"
                      px="16px"
                      key={l}
                      onClick={() => {
                        if (tags.includes(l)) {
                          setTags(uniq(tags).filter((t) => t !== l))
                        } else {
                          setTags(uniq(tags).concat(l))
                        }
                      }}
                      cursor="pointer"
                    >
                      <Text mr="8px" mb="2px" color={colors.textLight} fontWeight="500">
                        {l}
                      </Text>
                      <Icon mr="-8px" width="14px" height="14px" icon="circle-cross-grey" />
                    </Flex>
                  </Flex>
                )
              })}
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent="flex-end" p="16px" py="8px" borderTop="1px solid rgba(237, 242, 247, 1)">
        {deleteGroup && (
          <Button
            zIndex={1}
            borderColor="white"
            variant="secondary"
            mr="16px"
            label="Delete group"
            onClick={() => {
              deleteGroup()
              setIsOpen(false)
            }}
          />
        )}
        <Button
          zIndex={1}
          borderColor="white"
          isDisabled={!newTagGroupName || tags.length === 0}
          variant="black"
          label="Save"
          width="100px"
          onClick={() => {
            save()
            setIsOpen(false)
          }}
        />
      </Flex>
    </ModalContainer>
  )
}

export default TagGroupModal
