import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors, w } from '../ui/helpers.js'
import { H1, H2, Text, Span, GradientFont, Error } from '../ui/Typography.js'
import { Flex } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Button from '../ui/Button.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { toast } from 'react-toastify'
import { PrivacyTermsComponent } from './Login.js'

const SignInText = styled(Text)`
  position: absolute;
  bottom: 24px;
  right: 76px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`

const FormInput = formikFormWrapper(Input)

const ResetPassword = (props) => {
  let auth = useAuth()
  const [error, setError] = useState('')
  let navigate = useNavigate()
  const [search, setSearch] = useSearchParams()
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validate={(values) => {
        const errors = {}
        if (!values.password) {
          errors.password = 'Required'
        } else if (values.password.length < 8) {
          errors.password = 'Must be 8 characters or more'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setError('')
        setSubmitting(true)
        const res = await api.post('/auth/password-reset', { password: values.password, token: search.get('token') })
        if (res.data.success) {
          console.log(res)
          toast('Password reset!')
          setTimeout(() => {
            navigate('/login')
          }, 500)
          return
        } else {
          setError(res.data.message)
        }
        setSubmitting(false)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
        /* and other goodies */
      }) => (
        <Flex>
          <Flex
            display={w < 981 ? 'none' : 'flex'}
            flexDirection="column"
            p="76px"
            width="586px"
            minWidth="586px"
            height="100vh"
            bg={colors.darkGreen}
          >
            <Image mb="160px" src="/logoWhite.svg" width={186} />
            <GradientFont mb="25px">Happens to the best of us.</GradientFont>
            <Text light color="white">
              You’ll be back where you belong soon.
            </Text>
          </Flex>
          <Flex position="relative" flexDirection="column" alignItems="center" width="100%" p="76px">
            <Flex flexDirection="column" maxWidth="535px" height="100%" justifyContent="center">
              <H1 mb="8px">Password Reset </H1>
              <H2 mb="30px">Enter your new password</H2>

              <Field
                name="password"
                label="Password"
                large
                mb="55px"
                placeholder="Type your password"
                type="password"
                component={FormInput}
              />

              <Button large mb="8px" variant="black" label="Update password" onClick={handleSubmit} />
              {error && <Error>{error}</Error>}
            </Flex>
            <SignInText light>
              Don't have an account?{' '}
              <Link to="/signup">
                <Span link>Get started</Span>
              </Link>
            </SignInText>
            <PrivacyTermsComponent />
          </Flex>
        </Flex>
      )}
    </Formik>
  )
}

export default ResetPassword
