import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { H1, Text } from '../ui/Typography.js'
import Button from '../ui/Button.js'
import SlideUpModal from '../Components/SlideUpModal.js'
import { Box, Flex } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'
import { useAuth } from '../Auth.js'
import Spinner, { GreenSpinner } from '../ui/Spinner.js'
import PreviewModalEmail from './PreviewModalEmail.js'
import { RemoveScroll } from 'react-remove-scroll'
import Popover from '../Components/ui/Popover.js'
import { Input } from '../ui/Input.js'
import api from '../api.js'
import Toggle from '../ui/Toggle.js'

const IFRAME_HEIGHT = 7000

const StyledIframe = styled.iframe`
  width: 100%;
  // height: ${IFRAME_HEIGHT}px;
  border: none;
  overflow: hidden;
`

const StyledIframeWrapper = styled(Flex)`
  position: relative;
  border-radius: 16px 16px 0 0;
  border: 1px solid var(--Gray-500, #a0aec0);
  border-bottom: none;
  overflow: scroll;
  ${({ isDesktop }) => !isDesktop && 'width: 390px;'}
`

const SpinnerWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
`

const PreviewModal = ({ isOpen, onClose, values, html }) => {
  const { user } = useAuth()
  const [isDesktop, setIsDesktop] = useState(true)
  const [isEmail, setIsEmail] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isTestPopoverOpen, setIsTestPopoverOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const [isFree, setIsFree] = useState(false)
  const [testEmail, setTestEmail] = useState(user.email)
  const [viewMode, setViewMode] = useState('desktop')
  const [contentType, setContentType] = useState('email')
  const [accessType, setAccessType] = useState('free')
  const [isSending, setIsSending] = useState(false)
  const [sendStatus, setSendStatus] = useState('')

  const handleSend = async () => {
    setIsSending(true)
    setSendStatus('')
    try {
      const res = await api.post(`/notifications/send-test-post`, {
        email: testEmail,
        postId: values.id
      })
      setSendStatus('success')
      setTimeout(() => setSendStatus(''), 2000) // Reset after 2 seconds
    } catch (error) {
      setSendStatus('error')
    } finally {
      setIsSending(false)
    }
  }

  const handleIframeLoad = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    if (isEmail) {
      setIsLoading(true)
    }
  }, [isEmail])

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(`https://${user.subdomain}.theclubb.co/post/${values.id}?isPreview=true`)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2000) // Reset after 2 seconds
  }, [user.subdomain, values.id])

  return (
    <RemoveScroll enabled={isOpen}>
      <SlideUpModal isOpen={isOpen} onClose={onClose}>
        <Flex width="auto" p="24px">
          <Flex>
            <Toggle
              width="100px"
              label1="Desktop"
              label2="Mobile"
              value1="desktop"
              value2="mobile"
              selected={viewMode}
              setSelected={setViewMode}
            />
            <Box width="24px" />
            <Toggle
              width="100px"
              label1="Email"
              label2="Web"
              value1="email"
              value2="website"
              selected={contentType}
              setSelected={setContentType}
              mr="40px"
            />
            <Box width="24px" />

            <Toggle
              width="100px"
              label1="Free"
              label2="Paid"
              value1="free"
              value2="paid"
              selected={accessType}
              setSelected={setAccessType}
              mr="40px"
            />
          </Flex>
          <Flex width="auto" mr="40px" position="relative">
            <Popover
              isOpen={isTestPopoverOpen}
              onClose={() => setIsTestPopoverOpen(false)}
              position="left"
              content={
                <Flex flexDirection="column" p="24px" width="450px">
                  <Flex alignItems="flex-end" mb="16px">
                    <Input
                      mr="8px"
                      label="Preview Link"
                      value={`https://${user.subdomain}.getclpr.com/post/${values.id}?isPreview=true`}
                    />
                    <Button
                      variant="softBlue"
                      label={isCopied ? 'Copied' : 'Copy'}
                      height="37px"
                      borderRadius="8px"
                      renderLeftIcon={() => <Icon icon="copy-blue-grey" width="12px" height="12px" />}
                      onClick={handleCopy}
                    />
                  </Flex>
                  <Text light>Anyone with this link will be able to see the latest version even if unpublished.</Text>
                  <Flex borderBottom="1px solid #EDF2F7" my="32px" />
                  <Flex alignItems="flex-end">
                    <Input mr="8px" label="Send test email" value={testEmail} onChange={(e) => setTestEmail(e.target.value)} />
                    <Button
                      variant={'softBlue'}
                      width="100px"
                      label={
                        isSending ? 'Sending...' : sendStatus === 'success' ? 'Sent' : sendStatus === 'error' ? 'Failed' : 'Send'
                      }
                      height="37px"
                      borderRadius="8px"
                      renderLeftIcon={
                        sendStatus !== 'success' ? () => <Icon icon="arrow-right-blue" width="12px" height="12px" /> : null
                      }
                      onClick={handleSend}
                      disabled={isSending}
                    />
                  </Flex>
                </Flex>
              }
            >
              <Button
                variant="secondary"
                label="Test"
                renderRightIcon={() => <Icon ml="8px" icon="chevron-down-green" width="14px" height="14px" />}
                onClick={() => setIsTestPopoverOpen(!isTestPopoverOpen)}
              />
            </Popover>
          </Flex>
        </Flex>
        <Flex px="100px" pt="70px" height="calc(100% - 88px)" position="relative" justifyContent="center">
          {contentType === 'email' ? (
            <PreviewModalEmail isDesktop={viewMode === 'desktop'} post={values} html={html} isFree={accessType === 'free'} />
          ) : (
            <StyledIframeWrapper isDesktop={viewMode === 'desktop'} noScrollBar>
              {isLoading && (
                <SpinnerWrapper justifyContent="center" alignItems="center">
                  <GreenSpinner size="large" />
                </SpinnerWrapper>
              )}

              <StyledIframe
                src={
                  process.env.NODE_ENV === 'development'
                    ? `http://localhost:3000/post/${values.id}?isPreview=true${
                        accessType === 'free' ? '&isFreePreview=true' : ''
                      }`
                    : `https://${user.subdomain}.getclpr.com/post/${values.id}?isPreview=true${
                        accessType === 'free' ? '&isFreePreview=true' : ''
                      }`
                }
                onLoad={handleIframeLoad}
              />
            </StyledIframeWrapper>
          )}
        </Flex>
      </SlideUpModal>
    </RemoveScroll>
  )
}

export default PreviewModal
