import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, LineClamp } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { useModal } from '../Modal.js'
const edjsHTML = require('editorjs-html')
const edjsParser = edjsHTML()
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import EditorLink from '@editorjs/link'
import EditorImage from '@editorjs/image'
import Paragraph from '@editorjs/paragraph'
import DragDrop from 'editorjs-drag-drop'
import AnyButton from 'editorjs-button'
import { Formik, Field } from 'formik'
import { useTheme } from '../ThemeProvider.js'
import ImageUploadModal, { getHeightAndWidthFromDataUrl } from '../ui/ImageUploadModal.js'
import formikFormWrapper from '../formikFormWrapper.js'
import TagsModal from '../ui/TagsModal.js'
import CustomButton, { CustomButtonStyles } from './CustomButton.js'
import SlideUpModal from '../Components/SlideUpModal.js'
import PreviewModal from './PreviewModal.js'
import initEditor from './editorConfig.js'
import { debounce } from 'lodash' // Add this import
import { colors } from '../ui/helpers.js'
import { SubscribePromptStyles } from './SubscribePrompt.js'
import Image from '../ui/Image.js'
import HorizontalStepper from './HorizontalStepper'
import Popover from '../Components/ui/Popover.js'
import PostDetailsPopover from './PostDetailsPopover.js'

const TitleInput = styled.textarea`
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 700;
  line-height: 136.023%;
  border: none;
  outline: none;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-left: 24px;
  font-family: 'Inter', sans-serif;
  ::-webkit-calendar-picker-indicator {
    background: none;
  }
  ::-webkit-input-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  ::-moz-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  :-ms-input-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  :-moz-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  resize: none;
  overflow: hidden;
  min-height: 48px;
  height: auto; // Add this line
  width: 100%;
`

const SubtitleInput = styled(TitleInput)`
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  min-height: 30px;
  color: ${colors.textLight};

  height: auto; // Add this line
  resize: none;
`

const AuthorInput = styled.input`
  border: none;
  outline: none;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: 'Inter', sans-serif;
  ::-webkit-calendar-picker-indicator {
    background: none;
  }
  ::-webkit-input-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  ::-moz-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  :-ms-input-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  :-moz-placeholder {
    color: #b5b5b5;
    font-family: 'Inter', sans-serif;
  }
  font-weight: 400;
  font-size: 14px;
  padding: 0px;
  color: ${colors.textLight};
  min-height: auto;
  margin-left: 0px;
`

const FieldSubtitleInput = formikFormWrapper(SubtitleInput)
const FieldTitleInput = formikFormWrapper(TitleInput)
const FieldAuthorInput = formikFormWrapper(AuthorInput)

export const EditorJSStyles = styled(Box)`
  ${CustomButtonStyles}
  ${SubscribePromptStyles}
  .button {
    border-style: solid;
    border-color: ${({ primaryButtonColor }) => primaryButtonColor};
    background: ${({ primaryButtonColor }) => primaryButtonColor};
    color: ${({ primaryButtonTextColor }) => primaryButtonTextColor};
    border-width: 0px;
    display: inline-block;
    border-radius: 30px;
    width: auto;
    text-decoration: none;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    mso-line-height-rule: exactly;
    color: #ffffff;
    font-size: 16px;
    padding: 15px 25px;
    display: inline-block;
    background: ${({ primaryButtonColor }) => primaryButtonColor};
    border-radius: 30px;
  }
  div {
    color: ${colors.textLight} !important;
    word-break: break-word;
  }
  .ce-header {
    color: ${colors.textDark} !important;
  }
  .ce-paragraph {
    a {
      text-decoration: underline;
    }
  }

  .codex-editor [data-placeholder-active]:empty:before,
  .codex-editor [data-placeholder-active][data-empty='true']:before {
    color: #b5b5b5 !important;
  }
`

const DEFAULT_INITIAL_DATA = {
  time: new Date().getTime(),
  blocks: []
}

const Posts = (props) => {
  let { user } = useAuth()
  const navigate = useNavigate()

  let { postId } = useParams()
  const ejInstance = useRef()
  const { theme } = useTheme()
  const [inputPost, setInputPost] = useState()

  const [isLoading, setIsLoading] = useState(true)
  const [thumbnailImageUploadOpen, setThumbnailImageUploadOpen] = useState(false)

  const [html, setHtml] = useState([])

  const [showTagsModal, setShowTagsModal] = useState(false)

  const [previewModalOpen, setPreviewModalOpen] = useState(false)

  const [saveStatus, setSaveStatus] = useState('') // Add this state

  const [isPostDetailsOpen, setIsPostDetailsOpen] = useState(false)

  const submit = async (values) => {
    const res = await api.post('/content/post-update', values)
    // this happens twice
    if (res.data.payload.id && !postId) {
      navigate(`/post/editor/${res.data.payload.id}`)
    }
    return res
  }

  const refresh = async () => {
    const res = await api.get(`/content/get-post?id=${postId}`)
    setInputPost(res.data.payload)
    setHtml(res.data.payload.html)
    setIsLoading(false)
  }

  useEffect(() => {
    console.log('postId', postId)
    if (postId) {
      refresh()
    } else {
      console.log('STOP LOADING2222')

      setIsLoading(false)
    }
  }, [])

  const autoResizeTextArea = (element) => {
    if (element) {
      element.style.height = 'auto'
      element.style.height = `${element.scrollHeight}px`
    }
  }

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center" flexDirection="column" backgroundColor="white">
      {isLoading ? (
        <Flex height="500px" justifyContent="center" alignItems="center">
          <BigDarkSpinner />
        </Flex>
      ) : (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={
            inputPost
              ? inputPost
              : {
                  title: 'New Post',
                  published: false,
                  author: user.firstName + ' ' + user.lastName
                }
          }
          validate={(values) => {
            let errors = {}
            if (!values.title || values.title.trim() === '') {
              errors.title = 'Title is required'
            }
            return errors
          }}
          onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
            setSubmitting(true)
            await submit(values)
            setSubmitting(false)
          }}
        >
          {({
            values,
            dirty,
            touched,
            resetForm,
            setSubmitting,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            validateForm,
            errors
          }) => {
            // Add this debounced save function
            const debouncedSave = useRef(
              debounce(async (values) => {
                setSaveStatus('Saving...')
                try {
                  const res = await submit(values)
                  setHtml(res.data.payload.html)
                  // this happens twice
                  console.log(res, 'resdd')
                  if (res.data.payload.id && !postId) {
                    setFieldValue('id', res.data.payload.id)
                    navigate(`/post/editor/${res.data.payload.id}`)
                  }
                } catch (error) {
                  console.error('Error saving:', error)
                }
              }, 1000)
            ).current

            const openPreviewModal = async () => {
              await handleSubmit()
              setPreviewModalOpen(true)
            }

            // Add this useEffect for auto-saving
            useEffect(() => {
              if (dirty) {
                debouncedSave(values)
              }
            }, [values, dirty])

            useEffect(() => {
              if (!ejInstance.current) {
                ejInstance.current = initEditor({
                  holder: 'editorjs',
                  content: values.content,
                  theme,
                  user,
                  onReady: () => {
                    new DragDrop(ejInstance.current)
                  },
                  onChange: async (api) => {
                    const data = await api.saver.save()
                    setFieldValue('content', data)
                  }
                })
              }

              return () => {
                ejInstance?.current?.destroy()
                ejInstance.current = null
              }
            }, [])

            useEffect(() => {
              const titleElement = document.querySelector('textarea[name="title"]')
              autoResizeTextArea(titleElement)
              const subtitleElement = document.querySelector('textarea[name="subtitle"]')

              autoResizeTextArea(subtitleElement)
            }, [values.title, values.subtitle])

            return (
              <>
                <Flex
                  minHeight="65px"
                  height="65px"
                  alignItems="center"
                  justifyContent="space-between"
                  backgroundColor="white"
                  borderBottom="2px solid #EEF2F7"
                  px="24px"
                  position="fixed"
                  top="0px"
                  zIndex={100}
                >
                  <Link to={!values.published ? `/content?showPublished=${values.published}` : `/post/${postId}`}>
                    <Flex cursor="pointer" width="auto">
                      <Icon mt="4px" mr="13px" width="16px" height="16px" icon="chevron-left-grey" />
                      <Text lightLight fontSize="14px">
                        {!values.published ? 'Close' : 'Back'}
                      </Text>
                    </Flex>
                  </Link>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    top="20px"
                    left="calc(50% - 100px)"
                    width="200px"
                  >
                    <Box
                      width="8px"
                      height="8px"
                      mr="8px"
                      borderRadius="8px"
                      backgroundColor={values.published ? 'rgba(102, 203, 159, 1)' : 'rgba(247, 147, 111, 1)'}
                    />
                    <Text light fontWeight="500">
                      {values.published ? 'Published' : 'Draft'}
                    </Text>
                  </Flex>
                  <Flex width="auto" alignItems="center">
                    {values.published ? null : <HorizontalStepper postId={postId} isValid={values.title} />}
                    {errors && Object.keys(errors).length > 0 ? <Error mr="16px">Please check form errors</Error> : null}

                    {!values.published && !values.publishedAt ? (
                      <Button
                        isLoading={isSubmitting}
                        variant={'green'}
                        label="Next"
                        onClick={async () => {
                          const formErrors = await validateForm()
                          console.log(formErrors, 'formErrors')
                          if (Object.keys(formErrors).length === 0) {
                            resetForm({ values: values })
                            handleSubmit()
                            navigate(`/post/schedule/${postId}`)
                          } else {
                            // Force all fields to be touched to show errors
                            Object.keys(formErrors).forEach((field) => setFieldTouched(field, true, false))
                          }
                        }}
                      />
                    ) : null}
                  </Flex>
                </Flex>

                <Flex flexDirection="column" alignItems="center" position="relative" height="100%">
                  <Flex position="fixed" top="77px" right="12px" width="auto">
                    <Button
                      borderRadius="8px"
                      color="#4A5568"
                      shadow
                      renderLeftIcon={() => <Icon icon="preview-grey" width="16px" height="16px" />}
                      variant="white"
                      label="Preview"
                      onClick={() => openPreviewModal()}
                    />
                    <Popover
                      isOpen={isPostDetailsOpen}
                      onClose={() => setIsPostDetailsOpen(false)}
                      position="left"
                      content={
                        <PostDetailsPopover
                          content={values.content} // Pass the entire content object
                        />
                      }
                    >
                      <Button
                        borderRadius="8px"
                        color="#4A5568"
                        shadow
                        renderCenterIcon={() => <Icon icon="info-circle-grey" width="16px" height="16px" />}
                        variant="white"
                        width="40px"
                        minWidth="40px"
                        padding="0px"
                        ml="16px"
                        onClick={() => setIsPostDetailsOpen(!isPostDetailsOpen)}
                      />
                    </Popover>
                  </Flex>
                  <Flex width="700px" height="100%" flexDirection="column" pt="137px" position="relative">
                    {values.thumbnailImageUrl ? (
                      <Flex pl="24px" width="800px" height="450px" minHeight="450px" mb="24px" position="relative">
                        <Image borderRadius="16px" objectFit="cover" src={values.thumbnailImageUrl} width="100%" height="100%" />
                        <Flex position="absolute" top="16px" right="16px" width="auto">
                          <Flex
                            width="40px"
                            height="40px"
                            backgroundColor="rgba(0, 0, 0, 0.5)"
                            borderRadius="20px"
                            mr="8px"
                            alignItems="center"
                            justifyContent="center"
                            cursor="pointer"
                            onClick={() => setThumbnailImageUploadOpen(true)}
                          >
                            <Icon icon="recycle-white" width="24px" height="24px" />
                          </Flex>
                          <Flex
                            width="40px"
                            height="40px"
                            backgroundColor="rgba(0, 0, 0, 0.5)"
                            borderRadius="20px"
                            alignItems="center"
                            justifyContent="center"
                            cursor="pointer"
                            onClick={() => setFieldValue('thumbnailImageUrl', '')}
                          >
                            <Icon icon="bin-white" width="18px" height="18px" />
                          </Flex>
                        </Flex>
                      </Flex>
                    ) : null}
                    <Flex pl="24px">
                      {!values.thumbnailImageUrl ? (
                        <Flex alignItems="center" width="auto" mb="16px">
                          <Icon mr="8px" icon="thumbnail-grey" />
                          <Text
                            cursor="pointer"
                            color="#B5B5B5"
                            fontWeight="500"
                            light
                            onClick={() => setThumbnailImageUploadOpen(true)}
                            mr="24px"
                          >
                            Cover Image
                          </Text>
                        </Flex>
                      ) : null}
                      <ImageUploadModal
                        isOpen={thumbnailImageUploadOpen}
                        setIsOpen={setThumbnailImageUploadOpen}
                        saveFunction={(urls) => {
                          setFieldValue('thumbnailImageUrl', urls[0])
                          setThumbnailImageUploadOpen(false)
                        }}
                      />
                      {!values.tags || !values.tags.length ? (
                        <Flex alignItems="center" width="auto" mb="16px">
                          <Icon mr="8px" icon="tag-grey" />
                          <Text
                            cursor="pointer"
                            color="#B5B5B5"
                            fontWeight="500"
                            light
                            onClick={() => setShowTagsModal(true)}
                            mr="16px"
                          >
                            Tags
                          </Text>
                        </Flex>
                      ) : null}
                      <TagsModal
                        inputTags={values.tags}
                        saveFunction={(tags) => setFieldValue('tags', tags)}
                        isOpen={showTagsModal}
                        setIsOpen={setShowTagsModal}
                        isRecipes
                      />
                    </Flex>
                    {values?.tags?.length ? (
                      <Flex flexWrap="wrap" noScrollBar ml="24px">
                        {(values.tags || []).map((tag) => {
                          return (
                            <Flex
                              alignItems="center"
                              backgroundColor="rgba(225, 232, 255, 1)"
                              width="auto"
                              height="28px"
                              mr="8px"
                              mb="8px"
                              borderRadius="14px"
                              px="16px"
                              key={tag}
                              cursor="pointer"
                              onClick={() => setShowTagsModal(true)}
                            >
                              <Text whiteSpace="nowrap" mb="2px" light fontWeight="500">
                                {tag}
                              </Text>
                            </Flex>
                          )
                        })}
                      </Flex>
                    ) : null}
                    <Flex flexDirection="column">
                      <Field
                        maxLength={200}
                        mb="24px"
                        large
                        name="title"
                        placeholder="New Post"
                        component={FieldTitleInput}
                        dark
                        rows={1}
                        onInput={(e) => {
                          autoResizeTextArea(e.target)
                        }}
                      />
                      {errors.title && touched.title && (
                        <Error pl="24px" mb="8px">
                          {errors.title}
                        </Error>
                      )}
                    </Flex>
                    <Flex>
                      <Field
                        mb="24px"
                        large
                        name="subtitle"
                        placeholder="Add a subtitle"
                        component={FieldSubtitleInput}
                        dark
                        onInput={(e) => autoResizeTextArea(e.target)}
                      />
                    </Flex>
                    <Flex alignItems="center" borderBottom="1px solid #EDF2F7" mb="40px" pb="16px">
                      <Text ml="24px" mr="4px" lineHeight="21px" fontSize="14px" light>
                        By{' '}
                      </Text>
                      <Field
                        large
                        name="author"
                        component={FieldAuthorInput}
                        dark
                        onInput={(e) => autoResizeTextArea(e.target)}
                      />
                    </Flex>

                    <EditorJSStyles
                      position="relative"
                      primaryButtonColor={theme.primaryButtonColor}
                      primaryButtonTextColor={theme.primaryButtonTextColor}
                      width="700px"
                      height="100%"
                      id="editorjs"
                    ></EditorJSStyles>
                  </Flex>

                  <PreviewModal
                    values={values}
                    isOpen={previewModalOpen}
                    html={html}
                    onClose={() => setPreviewModalOpen(false)}
                  />
                </Flex>
              </>
            )
          }}
        </Formik>
      )}
    </Flex>
  )
}

export default Posts
