import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '../ui/Layout.js'
import { Text } from '../ui/Typography.js'
import { Link, useLocation } from 'react-router-dom'
import { colors } from '../ui/helpers.js'
import Icon from '../ui/Icon.js'

const StepperContainer = styled(Flex)`
  width: auto;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-right: 24px;
`

const Step = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: auto;
`

const StepLabel = styled(Text)`
  color: ${(props) => (props.isActive ? colors.textDark : colors.textLight)};
  font-weight: ${(props) => (props.isActive ? '600' : '400')};
  font-size: 14px;
`

const StepConnector = styled(Box)`
  margin-left: 12px;
  margin-right: 12px;
  width: 20px;
  height: 1px;
  background-color: #e2e8f0;
`

const HorizontalStepper = ({ postId, isValid }) => {
  const location = useLocation()
  const currentPath = location.pathname

  const steps = [
    { label: 'Create', path: `/post/editor/${postId}` },
    { label: 'Publishing', path: `/post/schedule/${postId}` },
    { label: 'Settings', path: `/post/settings/${postId}` },
    { label: 'Review', path: `/post/review/${postId}` }
  ]

  const getCurrentStepIndex = () => {
    return steps.findIndex((step) => step.path === currentPath)
  }

  const currentStepIndex = getCurrentStepIndex()

  console.log(isValid, 'isValid')

  return (
    <StepperContainer>
      {steps.map((step, index) => (
        <React.Fragment key={step.label}>
          {isValid ? (
            <Link to={step.path}>
              <Step cursor={isValid ? 'pointer' : 'default'}>
                <StepLabel isActive={currentPath === step.path}>{step.label}</StepLabel>
              </Step>
            </Link>
          ) : (
            <Step>
              <StepLabel isActive={currentPath === step.path}>{step.label}</StepLabel>
            </Step>
          )}
          {index < steps.length - 1 && <StepConnector />}
        </React.Fragment>
      ))}
    </StepperContainer>
  )
}

export default HorizontalStepper
