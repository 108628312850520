import axios from 'axios'

axios.defaults.baseURL =
  process.env.NODE_ENV === 'production' ? 'https://api.getclipara.com/clubb' : 'http://localhost:8080/clubb'
axios.defaults.withCredentials = true

export const API_URL = process.env.NODE_ENV === 'production' ? 'https://api.getclipara.com/clubb' : 'http://localhost:8080/clubb'

export const AUTH_COOKIE = 'CLUBB_AUTH_DASHBOARD'

const getAuthCookie = () => {
  const cookies = document.cookie
  const maybeAuthCookie = cookies
    .split(';')
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith(`${AUTH_COOKIE}=`))

  return maybeAuthCookie ? maybeAuthCookie.split(`${AUTH_COOKIE}=`)[1] : undefined
}

export const setAuthCookie = (token) => {
  var now = new Date()
  var time = now.getTime()
  var expireTime = time + 1000 * 60 * 60 * 24 * 60 // 2 months
  now.setTime(expireTime)
  document.cookie = `${AUTH_COOKIE}=${token};expires=${now.toUTCString()};path=/`
}

export default {
  ...axios,
  get: (url, config) => {
    const authToken = getAuthCookie()
    return axios.get(url, {
      ...config,
      headers: { ...config?.headers, Authorization: authToken ? `Bearer ${authToken}` : undefined }
    })
  },
  post: (url, payload, config) => {
    const authToken = getAuthCookie()
    return axios.post(url, payload, {
      ...config,
      headers: { ...config?.headers, Authorization: authToken ? `Bearer ${authToken}` : undefined }
    })
  }
}
