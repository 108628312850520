import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { colors, formatSeconds } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import ModalContainer from '../ui/ModalContainer.js'
import { uniq, difference, intersection } from 'ramda'
import Tooltip from '../ui/Tooltip.js'
import ReactTooltip from 'react-tooltip'
import Search from './Search.js'
import { useModal } from '../Modal.js'
import PopoverDropdown from '../ui/PopoverDropdown.js'
import { useContent } from '../ContentProvider.js'

const ImageContainer = styled(Flex)`
  border-radius: 8px 8px 0px 0px;
`

const VideoImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
`

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 22px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  @media (min-width: 1800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 2200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

const Gradient = styled(Flex)`
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 80%, rgba(0, 0, 0, 0.3) 100%);
`

const GradientReverse = styled(Flex)`
  border-radius: 8px 8px 0px 0px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 80%, rgba(0, 0, 0, 0.3) 100%);
`

const ContainerWithHover = styled(Container)`
  box-shadow: none;
  background-color: transparent;
  border: ${(props) => (props.isSelected ? `4px solid rgba(120, 149, 255, 1)` : '4px solid #F5F8FA')};
  a {
    height: 100%;
  }
  // &&:hover {
  //   .hover {
  //     visibility: visible;
  //   }
  // }
  .hover {
    visibility: hidden;
  }
`

const PostCard = (post) => {
  let image = post.thumbnailImageUrl

  return (
    <Box key={post.id} minWidth="0px">
      <Link key={post.id} to={`/post/${post.id}`}>
        <ContainerWithHover
          mr="30px"
          p="0px"
          height="400px"
          justifyContent="space-between"
          position="relative"
          overflowX="hidden"
        >
          <Flex
            flexDirection="column"
            height="100%"
            boxShadow={'0px 0px 1px rgba(12, 26, 75, 0.3), 0px 4px 20px -2px rgba(50, 50, 71, 0.24)'}
          >
            <Flex cursor="pointer" noverflow="hidden" height="calc(100% - 100px)" position="relative">
              <ImageContainer>
                {image ? <VideoImage loading="lazy" src={image} /> : <Flex height="100%" backgroundColor="#5BB3F9" />}
              </ImageContainer>

              <Box position="absolute" top="12px" right="12px">
                <Icon icon="file-text-white" width={24} height={24} />
              </Box>

              <Gradient position="absolute" top="0px" left="0px" height="100%" />

              <Box position="absolute" bottom="8px" left={'8px'}>
                <Text color="white" fontSize="12px" fontWeight="600">
                  {post.createdAt ? dayjs(post.createdAt).format('DD MMM') : ''}
                </Text>
              </Box>
              <GradientReverse className="hover" position="absolute" top="0px" left="0px" height="100%" />
            </Flex>
            <Flex
              backgroundColor="white"
              flexDirection="column"
              py="8px"
              pb={'16px'}
              minHeight={'100px'}
              height={'100px'}
              justifyContent="space-between"
            >
              <H6 wordBreak="break-all" mb="0px" px={'8px'}>
                {post.title && post.title.length > 50 ? post.title.slice(0, 50) + '...' : post.title}
              </H6>
              <Flex overflowX="scroll" pl={'8px'} noScrollBar>
                {(post.tags || []).map((tag) => {
                  return (
                    <Flex
                      alignItems="center"
                      backgroundColor={'rgba(225, 232, 255, 1)'}
                      width="auto"
                      height="28px"
                      mr="8px"
                      borderRadius="14px"
                      px="16px"
                      key={tag}
                      cursor="pointer"
                    >
                      <Text whiteSpace="nowrap" mb="2px" light fontWeight="500">
                        {tag}
                      </Text>
                    </Flex>
                  )
                })}
              </Flex>
            </Flex>
          </Flex>
        </ContainerWithHover>
      </Link>
    </Box>
  )
}

export default PostCard
