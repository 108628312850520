import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { toast } from 'react-toastify'
import { useAuth } from '../Auth.js'
import baseUrl from '../baseUrl.js'
import { useModal } from '../Modal.js'
import Billing from '../Billing/Billing.js'
import Users from './Users'
import DeliveredMinutes from './DeliveredMinutes'
import { USER_ROLES } from '../Auth/types.js'
import ToggleRedGreen from '../ui/ToggleTwoOptions.js'

const FormInput = formikFormWrapper(Input)

const ImageBox = styled(Flex)`
  height: 80px;
  width: 200px;
  max-width: 200px;
  border: 1px dashed #a6b7d4;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const PreviewImageBox = styled(Flex)`
  width: auto;
  border: 1px solid #a6b7d4;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding: 4px;
`

const PreviewImage = styled(Image)`
  height: 150px;
  object-fit: contain;
`

const TABS = {
  BILLING: 'BILLING',
  ACCOUNT: 'ACCOUNT',
  USERS: 'USERS',
  DELIVERY: 'DELIVERY'
}

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && `border-bottom: 1px solid ${colors.green};`}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
    </TabText>
  )
}

const Account = (props) => {
  let { user, setUser } = useAuth()
  const [search, setSearch] = useSearchParams()
  const [activeTab, setActiveTabState] = useState(search.get('tab') || TABS.ACCOUNT)
  const [searchParams, setSearchParams] = useSearchParams()

  const setActiveTab = (tab) => {
    setActiveTabState(tab)
    setSearchParams({ tab })
  }

  const goToStripePortal = async () => {
    const res = await api.get('/stripe/create-portal-session')
    if (res.data.success) {
      window.location.href = res.data.payload
    }
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex position="relative" flexDirection="column" width="100%" p="40px" pb="0px">
        <Flex>
          <H1 mb="32px" width="100%">
            Account settings
          </H1>
        </Flex>
        <Flex>
          <Tab onClick={() => setActiveTab(TABS.ACCOUNT)} title="Your Account" isActive={activeTab === TABS.ACCOUNT} />
          <Tab onClick={() => setActiveTab(TABS.USERS)} title="Users" isActive={activeTab === TABS.USERS} />
        </Flex>
        {user.stripeCustomerId ? (
          <Button
            position="absolute"
            top="72px"
            right="36px"
            shadow
            large
            variant="white"
            label="Manage Payments"
            onClick={goToStripePortal}
          />
        ) : null}
      </Flex>
      <Flex bg={colors.backgroundGrey} pt="30px" flexDirection="column" alignItems="center">
        {activeTab === TABS.ACCOUNT ? <AccountTab /> : null}
        {activeTab === TABS.USERS ? <Users /> : null}
      </Flex>
    </Flex>
  )
}

const UpgradeContainer = styled(Flex)`
  background: linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%);
  height: 37px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 8px;
  width: 212px;
  justify-content: center;
`

const GreyedLabel = styled(Label)`
  ${(props) => (props.disabled ? 'color: rgba(160, 174, 192, 1);' : '')}
`

// USED ELSEWHERE
export const BrandingUploader = ({ hideBrandingToggle }) => {
  let { showAddLogoModal, setShowAddLogoModal } = useModal()
  let { user, setUser } = useAuth()

  const updateNoCliparaLogo = async (bool) => {
    const res = await api.post('/auth/user/update-no-clipara-logo', { noCliparaLogo: bool })
    if (res.data.success) {
      const authRes = await api.get('/auth')
      setUser(authRes.data.payload)
      toast('Changes saved')
      return
    } else {
      toast('Error updating user')
    }
  }

  if (user.logoUrl) {
    return (
      <>
        <Label mb="16px">Player Branding Logo</Label>
        <Flex alignItems="flex-end">
          <PreviewImageBox mr="16px" onClick={() => setShowAddLogoModal(true)}>
            <PreviewImage src={user.logoUrl} />
          </PreviewImageBox>

          <Button
            renderLeftIcon={() => <Icon width={16} height={16} icon="upload-black" />}
            variant="white"
            label="Upload"
            shadow
            onClick={() => setShowAddLogoModal(true)}
          />
        </Flex>
      </>
    )
  }
  return (
    <>
      <Label mb="16px">Player Branding Logo</Label>
      <ImageBox mb="8px" onClick={() => setShowAddLogoModal(true)}>
        <Icon width={16} height={16} icon="plus-grey" />
      </ImageBox>
    </>
  )
}

const AccountTab = (props) => {
  let { user, setUser } = useAuth()
  return (
    <Flex px="40px">
      <Formik
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          organisationName: user.organisationName
        }}
        validate={(values) => {
          const errors = {}
          if (!values.firstName) {
            errors.firstName = 'Required'
          }
          if (!values.lastName) {
            errors.lastName = 'Required'
          }
          return errors
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          const res = await api.post('/auth/user/update', values)
          if (res.data.success) {
            setUser({ ...user, ...res.data.payload })
            toast('Changes saved')
            return
          } else {
            toast('Error updating user')
          }
          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <Flex flexDirection="column">
            <Container p="24px" maxWidth="700px" mb="32px">
              <Flex mb="24px">
                <Field
                  dark
                  name="firstName"
                  placeholder="Enter your name"
                  label="First Name"
                  large
                  type="text"
                  component={FormInput}
                  mr="28px"
                />
                <Field
                  dark
                  name="lastName"
                  large
                  placeholder="Enter your last name"
                  label="Last Name"
                  type="text"
                  component={FormInput}
                />
              </Flex>
              <Field
                dark
                name="email"
                label="Email"
                disabled
                large
                mb="24px"
                placeholder="Type your email address"
                type="email"
                component={FormInput}
              />

              <Box>
                <Button
                  mb="8px"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  variant="green"
                  label="Save changes"
                  onClick={handleSubmit}
                />
              </Box>
            </Container>
            <Container p="24px" maxWidth="700px" mb="32px">
              <H3 mb="8px">Domain</H3>
              <Text light mb="24px">
                How people see your Clubb
              </Text>
              <Field
                dark
                disabled={user.role !== USER_ROLES.ADMIN}
                name="organisationName"
                label="Clubb Name"
                large
                mb="24px"
                placeholder=""
                component={FormInput}
              />
              <Field
                dark
                value={user.subdomain}
                name="Subdomain"
                label="Subdomain"
                disabled
                large
                mb="24px"
                type="email"
                component={FormInput}
              />

              <Link to="/clubb-name">
                <Button mb="8px" disabled={isSubmitting} isLoading={isSubmitting} variant="green" label="Edit" />
              </Link>
            </Container>
          </Flex>
        )}
      </Formik>
    </Flex>
  )
}

export default Account
