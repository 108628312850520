import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import Icon from '../ui/Icon' // Add this import

const ModalBackground = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  pointer-events: none;
`

const ModalContent = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  width: 100%;
  height: calc(100vh - 60px);
  border-radius: 24px 24px 0 0;
  padding: 0px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  pointer-events: auto;
`

const CloseButton = styled.div`
  position: absolute;
  top: 36px;
  right: 24px;
  cursor: pointer;
  z-index: 10;
`

const SlideUpModal = ({ isOpen, onClose, children }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <ModalBackground initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={onClose} />
          <ModalWrapper>
            <ModalContent
              initial={{ y: '100%' }}
              animate={{ y: 0 }}
              exit={{ y: '100%' }}
              transition={{ type: 'tween', duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <CloseButton onClick={onClose}>
                <Icon width="14px" height="14px" icon="close-cross-grey" />
              </CloseButton>
              {children}
            </ModalContent>
          </ModalWrapper>
        </>
      )}
    </AnimatePresence>
  )
}

export default SlideUpModal
