import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import baseUrl from '../baseUrl.js'
import PaymentsTab from './PaymentsTab.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import { Td, Tr, HTr, HeaderText, Table, Th } from '../ui/Table.js'
import { currencySymbols } from '../ui/formatters.js'
import ContactsTab from './Contacts.js'
import ModalContainer from '../ui/ModalContainer.js'
import CreateSubscriberModal from './CreateSubscriberModal.js'
import { useTheme } from '../ThemeProvider.js'
import Icon from '../ui/Icon.js'
import { mkConfig, generateCsv, download } from 'export-to-csv'
import { useSearchParams } from 'react-router-dom'
import Button from '../ui/Button.js'

const TABS = {
  SUBSCRIBERS: 'subscribers',
  CONTACTS: 'contacts',
  PAYMENTS: 'payments'
}

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && `border-bottom: 1px solid ${colors.green};`}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive} light={isActive ? undefined : true}>
      {title}
    </TabText>
  )
}

const Customers = (props) => {
  let { user, setUser } = useAuth()
  console.log(user)
  const [search, setSearch] = useSearchParams()
  const [activeTab, setActiveTabState] = useState(search.get('tab') || TABS.SUBSCRIBERS)
  const [page, setPage] = useState(1)

  const setActiveTab = (tab) => {
    setActiveTabState(tab)
    setSearch({ tab: tab.toLowerCase() })
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex position="relative" flexDirection="column" width="100%" p="40px" pb="0px">
        <Flex>
          <H1 mb="32px" width="100%">
            Customers
          </H1>
          {user?.stripeConnectAccountId ? (
            <a href="https://dashboard.stripe.com/subscriptions" target="_blank" rel="noopener noreferrer">
              <Button
                position="absolute"
                renderLeftIcon={() => <Icon icon="box-arrow-green" width="12px" height="12px" />}
                top="16px"
                right="16px"
                variant="secondary"
                label="Go to Stripe"
              />
            </a>
          ) : null}
        </Flex>
        <Flex>
          <Tab onClick={() => setActiveTab(TABS.SUBSCRIBERS)} title="Paid" isActive={activeTab === TABS.SUBSCRIBERS} />
          <Tab onClick={() => setActiveTab(TABS.CONTACTS)} title="All" isActive={activeTab === TABS.CONTACTS} />
          <Tab onClick={() => setActiveTab(TABS.PAYMENTS)} title="Payments" isActive={activeTab === TABS.PAYMENTS} />
        </Flex>
      </Flex>
      <Flex bg={colors.backgroundGrey} pt="30px" flexDirection="column" alignItems="center">
        {activeTab === TABS.SUBSCRIBERS && <SubscribersTab />}
        {activeTab === TABS.CONTACTS && <ContactsTab />}
        {activeTab === TABS.PAYMENTS && <PaymentsTab />}
      </Flex>
    </Flex>
  )
}

const PAGESIZE = 50
const LineClamp = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const SubscribersTab = (props) => {
  let { user, setUser } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [allSubscribers, setAllSubscribers] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  let { theme } = useTheme()

  const fetchSubscribers = async () => {
    setIsLoading(true)
    try {
      const res = await api.get(`/customers/get-subscribers`)
      setAllSubscribers(res.data.payload?.sort((a, b) => dayjs(b.billingCycleAnchor).diff(dayjs(a.billingCycleAnchor))))
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchSubscribers()
  }, [])

  const totalPages = Math.ceil(allSubscribers.length / PAGESIZE)
  const paginatedSubscribers = allSubscribers.slice((currentPage - 1) * PAGESIZE, currentPage * PAGESIZE)

  const currencySymbol = currencySymbols[theme.plans?.[0]?.currency]

  const renderPageNumbers = () => {
    const pageNumbers = []
    const maxVisiblePages = 5
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2))
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Text
          key={i}
          onClick={() => setCurrentPage(i)}
          light={i !== currentPage}
          fontWeight={i === currentPage ? '600' : '500'}
          px="8px"
          cursor="pointer"
        >
          {i}
        </Text>
      )
    }
    return pageNumbers
  }

  return (
    <Flex px="24px">
      {isLoading ? (
        <Flex height="300px" alignItems="center" justifyContent="center">
          <BigDarkSpinner />
        </Flex>
      ) : (
        <Flex flexDirection="column" pb="100px">
          <Container p="0px" pt="16px">
            <Flex justifyContent="space-between" px="24px" mb="16px">
              <SmallTitle>Users</SmallTitle>
              <Flex
                width="auto"
                alignItems="center"
                cursor="pointer"
                onClick={() => {
                  const csv = generateCsv(mkConfig({ useKeysAsHeaders: true }))(
                    allSubscribers.map((s) => {
                      return {
                        first_name: s.firstName,
                        last_name: s.lastName,
                        date_joined: dayjs(s.billingCycleAnchor).format('YYYY-MM-DD HH:mm:ss'),
                        email: s.email,
                        plan: `${currencySymbol}${(s.subscriptionPrice / 100).toFixed(2)}${
                          s.planType === 'MONTHLY' ? '/m' : '/yr'
                        }`,
                        subscription_price: s.subscriptionPrice / 100,
                        subscription_type: s.planType.toLowerCase(),
                        status: s.billingCycleAnchor > new Date().toISOString() ? 'Trial' : 'Active'
                      }
                    })
                  )
                  download(mkConfig({ useKeysAsHeaders: true }))(csv)
                }}
              >
                <Icon mr="8px" icon="download-black" width="16px" height="16px" />
                <Text fontWeight="500">Download CSV</Text>
              </Flex>
            </Flex>
            <Table>
              <HTr>
                <Th width="220px !important">
                  <HeaderText left>NAME</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>DATE JOINED</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>EMAIL</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>PLAN</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>STATUS</HeaderText>
                </Th>
              </HTr>
              {paginatedSubscribers &&
                paginatedSubscribers.map((subscriber) => {
                  const isOnTrial = subscriber.billingCycleAnchor > new Date().toISOString()
                  return (
                    <Tr key={subscriber.id} justifyContent="space-between">
                      <Td left>
                        <LineClamp>
                          {subscriber.firstName} {subscriber.lastName}
                        </LineClamp>
                      </Td>
                      <Td left lightLight>
                        {dayjs(subscriber.billingCycleAnchor).format('MMM DD, YYYY')}
                      </Td>
                      <Td left lightLight>
                        {subscriber.email}
                      </Td>
                      <Td left lightLight>
                        {currencySymbol}
                        {(subscriber.subscriptionPrice / 100).toFixed(2)}
                        {subscriber.planType === 'MONTHLY' ? '/m' : '/yr'}
                      </Td>
                      <Td left lightLight>
                        <Flex alignItems="center">
                          <Box
                            mr="12px"
                            width="10px"
                            borderRadius="10px"
                            height="10px"
                            bg={isOnTrial ? 'rgba(247, 147, 111, 1)' : 'rgba(102, 203, 159, 1)'}
                          />
                          {isOnTrial ? 'Trial' : 'Active'}
                        </Flex>
                      </Td>
                    </Tr>
                  )
                })}
            </Table>

            {!allSubscribers?.length ? (
              <>
                <Text light fontSize="16px" pt="120px" mb="8px" fontWeight="500" textAlign="center">
                  No subscribers yet
                </Text>
                <Text light fontSize="14px" pb="120px" textAlign="center">
                  You’ll find all your active subscribers here
                </Text>
              </>
            ) : null}
            <Flex>
              <Flex mt="16px" mb="16px" justifyContent="space-between">
                <Text fontWeight="300" width="375px" lightLight px="16px" italic>
                  Showing {!allSubscribers?.length ? 0 : (currentPage - 1) * PAGESIZE + 1} -{' '}
                  {Math.min(currentPage * PAGESIZE, allSubscribers.length)} of {allSubscribers?.length}
                </Text>
                <Flex cursor="pointer" justifyContent="center" alignItems="center">
                  <Text
                    onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
                    light={currentPage > 1}
                    lightLight={currentPage === 1}
                    fontWeight="500"
                    px="8px"
                    cursor={currentPage > 1 ? 'pointer' : 'default'}
                  >
                    {'<'}
                  </Text>
                  {renderPageNumbers()}
                  <Text
                    onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
                    light={currentPage < totalPages}
                    lightLight={currentPage === totalPages}
                    fontWeight="500"
                    px="8px"
                    cursor={currentPage < totalPages ? 'pointer' : 'default'}
                  >
                    {'>'}
                  </Text>
                </Flex>
                <Flex width="375px" justifyContent="flex-end" pr="32px"></Flex>
              </Flex>
            </Flex>
          </Container>
        </Flex>
      )}
    </Flex>
  )
}

export default Customers
