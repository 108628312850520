import List from '@editorjs/list'

export default class PatchedList extends List {
  /**
   * Returns list tag with items
   *
   * @returns {Element}
   * @public
   */
  render() {
    this._elements.wrapper = this.makeMainTag(this._data.style)

    // fill with data
    if (this._data.items.length) {
      this._data.items.forEach((item) => {
        this._elements.wrapper.appendChild(
          this._make('li', this.CSS.item, {
            innerHTML: item
          })
        )
      })
    } else {
      this._elements.wrapper.appendChild(this._make('li', this.CSS.item))
    }

    // The event listener attachment use to be inline here.
    this.detectKeyEvents()

    return this._elements.wrapper
  }

  /**
   * Settings
   *
   * @public
   * @returns {Array}
   */
  renderSettings() {
    return this.settings.map((item) => ({
      ...item,
      isActive: this._data.style === item.name,
      closeOnActivate: true,
      onActivate: () => {
        this.toggleTune(item.name)
        // Reattach key event listeners now that we've toggled to a new list type (ol or ul)
        this.detectKeyEvents()
      }
    }))
  }

  /**
   * Add keydown listeners for escaping from a list and
   * back space events.
   */
  detectKeyEvents() {
    if (!this.readOnly) {
      // detect keydown on the last item to escape List
      this._elements.wrapper.addEventListener(
        'keydown',
        (event) => {
          const [ENTER, BACKSPACE] = [13, 8] // key codes

          switch (event.keyCode) {
            case ENTER:
              this.getOutofList(event)
              break
            case BACKSPACE:
              this.backspace(event)
              break
          }
        },
        false
      )
    }
  }

  backspace(event) {
    const items = this._elements.wrapper.querySelectorAll(`.${this.CSS.item}`)
    const firstItem = items[0]

    if (this.currentItem.textContent.trim() === '') {
      const target = this.currentItem
      window.requestIdleCallback(() => {
        target.parentElement.removeChild(target)
      })
    }

    if (!firstItem) {
      return
    }
    /**
     * Save the last one.
     */
    if (items.length < 2 && !firstItem.innerHTML.replace('<br>', ' ').trim()) {
      event.preventDefault()
    }
  }
}
