import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate, useLocation } from 'react-router-dom'
import axios from './api'
import { useCookies } from 'react-cookie'
import Spinner from './ui/Spinner'
import { Flex, Box } from './ui/Layout'
import { GradientFont } from './ui/Typography'
import { colors } from './ui/helpers'
import AddLogoModal from './Account/AddLogoModal.js'
import ReferModal from './Account/ReferModal.js'

let ModalContext = React.createContext()

export const ModalProvider = ({ children }) => {
  const [showAddLogoModal, setShowAddLogoModal] = useState(false)
  const [showReferModal, setShowReferModal] = useState(false)
  const [showVideoPopover, setShowVideoPopover] = useState(false)
  const [videoPopoverId, setVideoPopoverId] = useState()
  const props = {
    showAddLogoModal,
    setShowAddLogoModal,
    showReferModal,
    setShowReferModal,
    showVideoPopover,
    setShowVideoPopover,
    videoPopoverId,
    setVideoPopoverId
  }
  return (
    <ModalContext.Provider value={props}>
      {children}
      <AddLogoModal showAddLogoModal={showAddLogoModal} setShowAddLogoModal={setShowAddLogoModal} />
      <ReferModal showReferModal={showReferModal} setShowReferModal={setShowReferModal} />
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  return React.useContext(ModalContext)
}
