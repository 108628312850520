export const getDisplaySize = (maxWidth, maxHeight, aspectRatioCalc) => {
  const isLandscape = aspectRatioCalc > 1
  const displayWidth = Math.round(isLandscape ? maxWidth : maxHeight * aspectRatioCalc)
  const displayHeight = Math.round(isLandscape ? maxWidth / aspectRatioCalc : maxHeight)
  return { isLandscape, displayWidth, displayHeight }
}

export const loadScript = (url) => {
  return new Promise(function (resolve, reject) {
    let script = document.createElement('script')
    script.src = url
    script.async = false
    script.onload = function () {
      resolve(url)
    }
    script.onerror = function () {
      reject(url)
    }
    document.body.appendChild(script)
  })
}

let scripts = ['https://cdn.jsdelivr.net/npm/hls.js@latest', 'https://src.litix.io/core/4/mux.js']

export function loadScripts() {
  let promises = []
  scripts.forEach(function (url) {
    promises.push(loadScript(url))
  })
  return Promise.all(promises)
}

export const loadVideo = async ({ campaign, setHasEnded, id }) => {
  const player = document.getElementById(id || '__clipara-player')

  player.addEventListener('ended', () => {
    setHasEnded(true)
  })
  player.addEventListener('play', () => {
    setHasEnded(false)
  })
  if (!window.Hls) {
    await loadScripts()
  }
  var src = `https://stream.mux.com/${campaign.video.playbackId}.m3u8#t=0.1`

  if (player.canPlayType('application/vnd.apple.mpegurl')) {
    // Some browsers (safari and ie edge) support HLS natively
    player.src = src
  } else if (window.Hls.isSupported()) {
    var hls = new window.Hls()
    hls.loadSource(src)
    hls.attachMedia(player)
  } else {
    console.error("This is a legacy browser that doesn't support MSE")
  }

  setTimeout(() => player.play(), 200)
}

export const loadVideoLite = async ({ id, video, campaign, playerName, useClip, setHasPlayed }) => {
  try {
    const player = document.getElementById(id)
    if (!window.Hls) {
      await loadScripts()
    }
    var src = `https://stream.mux.com/${useClip && video.clipPlaybackId ? video.clipPlaybackId : video.playbackId}.m3u8${
      useClip ? '?max_resolution=720p' : '?max_resolution=1080p'
    }`

    if (player.canPlayType('application/vnd.apple.mpegurl')) {
      player.src = src
    } else if (window.Hls && window.Hls.isSupported()) {
      var hls = new window.Hls()
      hls.loadSource(src)
      hls.attachMedia(player)
    } else {
      console.error("This is a legacy browser that doesn't support MSE")
    }
    player
      .play()
      .then(() => {
        setHasPlayed && setHasPlayed(true)
      })
      .catch((e) => {
        console.log(e)
        setHasPlayed && setHasPlayed(false)
      })
  } catch (e) {
    console.log(e)
  }
}

export const playPause = (playerId, player) => {
  if (!playerId && !player) {
    return
  }
  const element = player || document.getElementById(playerId || '__clipara-player')
  if (!element.paused && !element.ended) {
    element.pause()
  } else if (element.paused) {
    element.play()
  }
}

export const loadSpecificVideo = async ({
  campaignId,
  organisationId,
  video,
  setHasEnded,
  setHasPlayed,
  playerId,
  noAutoplay,
  inputPlayer,
  maxResolution
}) => {
  const player = inputPlayer || document.getElementById(playerId)
  //   player.addEventListener('loadeddata', () => {
  //     document.getElementById('__clipara-load-spinner').style.display = 'none'
  //   })
  //   player.addEventListener('canplay', () => {
  //     document.getElementById('__clipara-load-spinner').style.display = 'none'
  //   })
  player.addEventListener('ended', () => {
    setHasEnded(true)
  })
  player.addEventListener('play', () => {
    setHasEnded(false)
  })
  if (!window.Hls || !window.mux) {
    await loadScripts()
  }
  if (!player.src) {
    var src = `https://stream.mux.com/${video.playbackId}.m3u8${
      maxResolution ? `?max_resolution=${maxResolution}` : '?max_resolution=1080p'
    }`

    if (player.canPlayType('application/vnd.apple.mpegurl')) {
      // Some browsers (safari and ie edge) support HLS natively
      player.src = src
    } else if (window.Hls && window.Hls.isSupported()) {
      var hls = new window.Hls()
      hls.loadSource(src)
      hls.attachMedia(player)
    } else {
      console.error("This is a legacy browser that doesn't support MSE")
    }

    if (!noAutoplay) {
      player
        .play()
        .then(() => setHasPlayed(true))
        .catch((e) => {
          console.log(e)
          setHasPlayed(false)
        })
    }
  }
}

export const loadVideoMP4 = async ({ id, video, campaign, playerName, videoWidth, useClip, setHasPlayed, useHigh }) => {
  try {
    const player = document.getElementById(id)
    let mediumOrLow = videoWidth > 250 ? 'medium' : 'low'

    if (videoWidth > 450 || useHigh) {
      mediumOrLow = 'high'
    }
    if (video.maxHeight && video.maxHeight < 1000) {
      mediumOrLow = 'medium'
    }
    if (video.maxHeight && video.maxHeight < 500) {
      mediumOrLow = 'low'
    }

    if (!window.mux) {
      await loadScript('https://src.litix.io/core/4/mux.js')
    }
    var src = `https://stream.mux.com/${
      video.clipPlaybackId && useClip ? video.clipPlaybackId : video.playbackId
    }/${mediumOrLow}.mp4`
    player.src = src

    player
      .play()
      .then(() => {
        setHasPlayed && setHasPlayed(true)
      })
      .catch((e) => {
        console.log(e)
        setHasPlayed && setHasPlayed(false)
      })
  } catch (e) {
    console.log(e)
  }
}

export const baseUrl = process.env.NODE_ENV === 'production' ? 'https://widget-v2.getclipara.com' : 'http://localhost:5001'
export const serverUrl = process.env.NODE_ENV === 'production' ? 'https://api.getclipara.com' : 'http://localhost:8080'

export const toCamel = (str) => {
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

const isObject = function (obj) {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function'
}

export const camelObj = function (obj) {
  if (isObject(obj)) {
    const n = {}

    Object.keys(obj).forEach((k) => {
      n[toCamel(k)] = camelObj(obj[k])
    })

    return n
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return camelObj(i)
    })
  }

  return obj
}

export const formatCurrency = ({ price, currency, campaign }) => {
  if (!price || price === 0) {
    return price
  }
  if (!currency) {
    const formatted =
      campaign.shop &&
      campaign.shop.moneyFormat &&
      campaign.shop.moneyFormat
        .replace('{{amount}}', price.toFixed(2))
        .replace('{{amount_no_decimals}}', price.toFixed(2))
        .replace('{{amount_with_comma_separator}}', price.toFixed(2))
        .replace('{{amount_no_decimals_with_comma_separator}}', price.toFixed(2))
        .replace('{{amount_with_apostrophe_separator}}', price.toFixed(2))
    try {
      return formatted.split('>')[1].split('<')[0]
    } catch (e) {}
    return formatted
  }
  return new Intl.NumberFormat(undefined, { style: 'currency', currency: currency }).format(price).replace('US$', '$')
}

export const formatSeconds = (seconds) => {
  // Hours, minutes and seconds
  var hrs = ~~(seconds / 3600)
  var mins = ~~((seconds % 3600) / 60)
  var secs = ~~seconds % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = ''

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange'
  }
}

function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden'
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msHidden'
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden'
  }
}

export function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()]
}

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(' ')
}

export const getContainerElement = (campaignId) => {
  const containerElements = Array.from(document.querySelectorAll('#__clipara-embed'))
  if (containerElements && containerElements.length === 1) {
    return containerElements[0]
  }

  const campaignSpecificElement = document.querySelector(`#__clipara-embed[data-campaign-id="${campaignId}"]`)
  if (campaignSpecificElement) {
    return campaignSpecificElement
  }

  return document.getElementById('__clipara-embed')
}

export const loadSpecificVideoMP4 = async ({
  campaign,
  playerName,
  campaignId,
  organisationId,
  video,
  setHasEnded,
  setHasPlayed,
  playerId,
  noAutoplay,
  inputPlayer,
  widgetId,
  productIds,
  useHigh
}) => {
  const player = inputPlayer || document.getElementById(playerId)
  player.addEventListener('ended', () => {
    setHasEnded(true)
  })
  player.addEventListener('play', () => {
    setHasEnded(false)
  })

  try {
    player.setAttribute('oncontextmenu', 'return false;')
  } catch (e) {}

  let mediumOrLow = 'medium'

  if (useHigh) {
    mediumOrLow = 'high'
  }
  if (video.maxHeight && video.maxHeight < 1000) {
    mediumOrLow = 'medium'
  }
  if (video.maxHeight && video.maxHeight < 500) {
    mediumOrLow = 'low'
  }

  if (!window.mux) {
    await loadScript('https://src.litix.io/core/4/mux.js')
  }

  if (!player.src) {
    var src = `https://stream.mux.com/${video.playbackId}/${mediumOrLow}.mp4`
    player.src = src

    if (!noAutoplay) {
      try {
        player
          .play()
          .then(() => setHasPlayed(true))
          .catch((e) => {
            console.log(e)
            setHasPlayed(false)
          })
      } catch (e) {}
    }

    const blockAutoPlay = window && window.location.href.includes('block-clipara-auto-play')
    if (blockAutoPlay) {
      setHasPlayed(false)
    }
  }
}
