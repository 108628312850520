import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { Formik, Field } from 'formik'
import SelectComponent from '../ui/Select.js'
import { currencyOptions } from './types.js'
import { Input, TextArea } from '../ui/Input.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Button from '../ui/Button.js'
import ImageUploadModal from '../ui/ImageUploadModal.js'
import Image from '../ui/Image.js'
import Icon from '../ui/Icon.js'
import { defaultTheme, useTheme } from '../ThemeProvider.js'
import CircleToggle from '../ui/CircleToggle.js'
import { currencySymbols, toTitleCase } from '../ui/formatters.js'
import { props } from 'bluebird'
import { F } from 'ramda'

const TickSvg = ({ colorProp, config }) => {
  return (
    <Box minWidth="20px" mt="1px">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill={colorProp ? config[colorProp] : config.primaryButtonColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.7116 6.21209C14.0777 5.84597 14.6713 5.84597 15.0374 6.21209C15.399 6.57371 15.4034 7.15727 15.0507 7.52434L10.0604 13.7622C10.0532 13.7712 10.0455 13.7798 10.0374 13.7879C9.67126 14.154 9.07767 14.154 8.71155 13.7879L5.40349 10.4799C5.03738 10.1137 5.03738 9.52015 5.40349 9.15403C5.76961 8.78791 6.3632 8.78791 6.72932 9.15403L9.34622 11.7709L13.6867 6.24013C13.6944 6.23029 13.7027 6.22093 13.7116 6.21209Z"
          fill={colorProp ? config[colorProp] : config.primaryButtonColor}
        />
      </svg>
    </Box>
  )
}

const FontText = styled(Text)`
  color: ${(props) => props.config.secondaryFontColor};
  font-family: ${(props) => (props.light ? props.theme?.secondaryFontFamily : props.theme?.primaryFontFamily)} !important;
`

const Styler = styled(Flex)`
  h1,
  h2,
  h3 {
    font-family: ${(props) => props.config.primaryFontFamily} !important;
    color: ${(props) => props.config.primaryFontColor} !important;
  }
  button.primary {
    border-radius: ${(props) => {
      if (props.config.primaryButtonRound === 'PILL') {
        return '50px'
      }
      if (props.config.primaryButtonRound === 'ROUNDED') {
        return '8px'
      }
      if (props.config.primaryButtonRound === 'SQUARE') {
        return '0px'
      }
    }} !important;
    ${(props) => {
      if (props.config.primaryButtonFill === 'FILL') {
        return `background-color: ${props.config.primaryButtonColor};
      color: ${props.config.primaryButtonFontColor};`
      }
      if (props.config.primaryButtonFill === 'OUTLINE') {
        return `border: 1px solid ${props.config.primaryButtonColor};
      background-color: transparent;
      color: ${props.config.primaryButtonFontColor};`
      }
    }} !important;
    font-family: ${(props) => props.config.secondaryFontFamily} !important;
  }
  button.secondary {
    border-radius: ${(props) => {
      if (props.config.secondaryButtonRound === 'PILL') {
        return '50px'
      }
      if (props.config.secondaryButtonRound === 'ROUNDED') {
        return '8px'
      }
      if (props.config.secondaryButtonRound === 'SQUARE') {
        return '0px'
      }
    }} !important;
    ${(props) => {
      if (props.config.secondaryButtonFill === 'FILL') {
        return `background-color: ${props.config.secondaryButtonColor};
      color: ${props.config.secondaryButtonFontColor};`
      }
      if (props.config.secondaryButtonFill === 'OUTLINE') {
        return `border: 1px solid ${props.config.secondaryButtonColor};
      background-color: transparent;
      color: ${props.config.secondaryButtonFontColor};`
      }
    }} !important;
    font-family: ${(props) => props.config.secondaryFontFamily} !important;
  }
`
const ResponsiveContainer = styled(Flex)`
  @media (min-width: 1600px) {
    height: 800px;
    width: 414px;
  }
`
const ResponsiveContainer1 = styled(Flex)`
  @media (min-width: 1600px) {
    min-height: 250px;
  }
`

const Preview = ({ values }) => {
  let { user, setUser } = useAuth()
  const { theme } = useTheme()

  const plans = [
    {
      currency: values.currency,
      freeTrialDays: values.freeTrialDays,
      type: 'MONTHLY',
      price: values.monthlyPrice,
      features: [
        values.monthly && values.monthly['1'],
        values.monthly && values.monthly['2'],
        values.monthly && values.monthly['3']
      ].filter((el) => el)
    },
    {
      currency: values.currency,
      freeTrialDays: values.freeTrialDays,
      type: 'ANNUAL',
      price: values.annualPrice,
      features: [
        values.annual && values.annual['1'],
        values.annual && values.annual['2'],
        values.annual && values.annual['3']
      ].filter((el) => el)
    },
    {
      currency: values.currency,
      freeTrialDays: values.freeTrialDays,
      type: 'PREMIUM',
      name: values.premium && values.premium.name,
      price: values.premiumPrice,
      features: [
        values.premium && values.premium['1'],
        values.premium && values.premium['2'],
        values.premium && values.premium['3']
      ].filter((el) => el)
    }
  ]

  const [selectedPlanId, setSelectedPlanId] = useState(plans[1].price ? 'ANNUAL' : 'MONTHLY')
  const selectedPlan = plans.find((el) => el.type === selectedPlanId)

  return (
    <Styler config={theme} width="auto">
      <ResponsiveContainer
        backgroundColor={theme.backgroundColor}
        height="600px"
        width="340px"
        borderRadius="32px"
        boxShadow="0px 4px 8px 4px rgba(118, 118, 118, 0.25)"
        flexDirection="column"
        alignItems="center"
        overflowY="scroll"
      >
        <Flex flexDirection="column">
          <Flex
            px="16px"
            justifyContent="space-between"
            height="80px"
            borderBottom={`1px solid #E4ECF7`}
            alignItems="center"
            mb="48px"
          >
            <Box height="60px" pt="8px">
              <Image src={`${theme.logo}?width=300`} width="100%" height="100%" />
            </Box>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="23" viewBox="0 0 30 23" fill="none">
              <path
                d="M0 0.75C0 0.335786 0.335786 0 0.75 0H29.25C29.6642 0 30 0.335786 30 0.75C30 1.16421 29.6642 1.5 29.25 1.5H0.749999C0.335786 1.5 0 1.16421 0 0.75Z"
                fill={theme.accentColor}
              />
              <path
                d="M0 11.25C0 10.8358 0.335786 10.5 0.75 10.5H29.25C29.6642 10.5 30 10.8358 30 11.25C30 11.6642 29.6642 12 29.25 12H0.749999C0.335786 12 0 11.6642 0 11.25Z"
                fill={theme.accentColor}
              />
              <path
                d="M0 21.75C0 21.3358 0.335786 21 0.75 21H29.25C29.6642 21 30 21.3358 30 21.75C30 22.1642 29.6642 22.5 29.25 22.5H0.749999C0.335786 22.5 0 22.1642 0 21.75Z"
                fill={theme.accentColor}
              />
            </svg>
          </Flex>
          <H1 textAlign="center" mb="8px" fontSize="32px" fontWeight="700">
            Choose a plan
          </H1>
          {plans && plans[0]?.freeTrialDays ? (
            <FontText config={theme} light textAlign="center" fontSize="16px">
              Start your {plans[0].freeTrialDays} day free trial
            </FontText>
          ) : null}
          <Flex flexDirection="column" alignItems="center" px="24px" mt="32px">
            <Flex flexDirection="column">
              {plans
                .filter((p) => p.price)
                .map((plan) => {
                  return (
                    <Flex
                      key={plan.id}
                      flexDirection="row"
                      alignItems="center"
                      mb="16px"
                      onClick={() => setSelectedPlanId(plan.type)}
                    >
                      <Box mr="16px">
                        <CircleToggle color={theme.accentColor} toggled={plan.type === selectedPlanId} width={16} />
                      </Box>
                      <FontText config={theme} fontSize="16px" fontWeight="600" mr="8px">
                        {plan.name ? plan.name : toTitleCase(plan.type)}
                      </FontText>
                      <FontText config={theme} light fontSize="16px" fontWeight="400">
                        {currencySymbols[plan.currency]}
                        {plan.price}
                        {plan.type === 'MONTHLY' ? '/m' : '/yr'}
                        {plan.type !== 'MONTHLY' && plan.type !== 'PREMIUM'
                          ? ` (${currencySymbols[plan.currency]}${(Math.round((plan.price * 100) / 12) / 100).toFixed(2)}/m)`
                          : ''}
                      </FontText>
                    </Flex>
                  )
                })}
            </Flex>
            <Box borderBottom={`2px solid ${theme.accentColor}`} width="100%" mt="8px" mb="24px" />
            <ResponsiveContainer1 flexDirection="column">
              {selectedPlan?.features && selectedPlan?.features[0] ? (
                <Flex mb="16px">
                  <TickSvg config={theme} colorProp="accentColor" />
                  <FontText config={theme} lineHeight="normal" ml="16px" mt="2px" fontSize="14px" light>
                    {selectedPlan?.features[0]}
                  </FontText>
                </Flex>
              ) : null}
              {selectedPlan?.features && selectedPlan?.features[1] ? (
                <Flex mb="16px">
                  <TickSvg config={theme} colorProp="accentColor" />
                  <FontText config={theme} lineHeight="normal" ml="16px" mt="2px" fontSize="14px" light>
                    {selectedPlan?.features[1]}
                  </FontText>
                </Flex>
              ) : null}
              {selectedPlan?.features && selectedPlan?.features[2] ? (
                <Flex mb="16px">
                  <TickSvg config={theme} colorProp="accentColor" />
                  <FontText config={theme} lineHeight="normal" ml="16px" mt="2px" fontSize="14px" light>
                    {selectedPlan?.features[2]}
                  </FontText>
                </Flex>
              ) : null}
            </ResponsiveContainer1>
            <Button mt="24px" mb="48px" width="100%" className="primary" label="Select plan" />
          </Flex>
        </Flex>
        <Box width="100%" height="215px">
          <Image src={values.paywall.imageUrl || defaultTheme.homepageImageUrl} width="100%" height="100%" objectFit="cover" />
        </Box>
      </ResponsiveContainer>
    </Styler>
  )
}

export default Preview
