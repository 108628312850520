import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { Flex } from './Layout'
import { colors } from './helpers'

const StyledSvg = styled.svg`
  cursor: pointer;
`

const Checkbox = ({ checked, onClick }) => {
  if (!checked) {
    return (
      <StyledSvg onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.84583 2C2.92853 2 2.70418 2.04332 2.47793 2.16433C2.33758 2.23939 2.23939 2.33758 2.16433 2.47793C2.04332 2.70418 2 2.92853 2 3.84583V16.1542C2 17.0715 2.04332 17.2958 2.16433 17.5221C2.23939 17.6624 2.33758 17.7606 2.47793 17.8357C2.70418 17.9567 2.92853 18 3.84583 18H16.1542C17.0715 18 17.2958 17.9567 17.5221 17.8357C17.6624 17.7606 17.7606 17.6624 17.8357 17.5221C17.9567 17.2958 18 17.0715 18 16.1542V3.84583C18 2.92853 17.9567 2.70418 17.8357 2.47793C17.7606 2.33758 17.6624 2.23939 17.5221 2.16433C17.2958 2.04332 17.0715 2 16.1542 2H3.84583ZM3.84583 0H16.1542C17.4915 0 17.9764 0.139238 18.4653 0.400699C18.9542 0.66216 19.3378 1.04584 19.5993 1.53473C19.8608 2.02362 20 2.50855 20 3.84583V16.1542C20 17.4915 19.8608 17.9764 19.5993 18.4653C19.3378 18.9542 18.9542 19.3378 18.4653 19.5993C17.9764 19.8608 17.4915 20 16.1542 20H3.84583C2.50855 20 2.02362 19.8608 1.53473 19.5993C1.04584 19.3378 0.66216 18.9542 0.400699 18.4653C0.139238 17.9764 0 17.4915 0 16.1542V3.84583C0 2.50855 0.139238 2.02362 0.400699 1.53473C0.66216 1.04584 1.04584 0.66216 1.53473 0.400699C2.02362 0.139238 2.50855 0 3.84583 0Z"
          fill="#C9CED6"
        />
      </StyledSvg>
    )
  }
  return (
    <StyledSvg onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_14413_10227)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.32698 1.63766C0 2.2794 0 3.11948 0 4.79963V15.1996C0 16.8798 0 17.7199 0.32698 18.3616C0.614601 18.9261 1.07354 19.385 1.63803 19.6727C2.27976 19.9996 3.11984 19.9996 4.8 19.9996H15.2C16.8802 19.9996 17.7202 19.9996 18.362 19.6727C18.9265 19.385 19.3854 18.9261 19.673 18.3616C20 17.7199 20 16.8798 20 15.1996V4.79963C20 3.11948 20 2.2794 19.673 1.63766C19.3854 1.07318 18.9265 0.614234 18.362 0.326614C17.7202 -0.000366211 16.8802 -0.000366211 15.2 -0.000366211H4.8C3.11984 -0.000366211 2.27976 -0.000366211 1.63803 0.326614C1.07354 0.614234 0.614601 1.07318 0.32698 1.63766ZM14.7285 6.79292C14.338 6.40239 13.7048 6.40239 13.3143 6.79292L8.02137 12.0858L6.20723 10.2717C5.8167 9.88114 5.18354 9.88114 4.79302 10.2717C4.40249 10.6622 4.40249 11.2954 4.79302 11.6859L7.31426 14.2071C7.70479 14.5977 8.33795 14.5977 8.72848 14.2071L14.7285 8.20713C15.119 7.81661 15.119 7.18344 14.7285 6.79292Z"
          fill="#007D5B"
        />
      </g>
      <defs>
        <clipPath id="clip0_14413_10227">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </StyledSvg>
  )
}

export default Checkbox
