import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { colors, formatSeconds, formatStepTime } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { Formik, Field } from 'formik'
import SelectComponent from '../ui/Select.js'
import { Input, TextArea } from '../ui/Input.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Button from '../ui/Button.js'
import ImageUploadModal from '../ui/ImageUploadModal.js'
import Image from '../ui/Image.js'

import Icon from '../ui/Icon.js'
import ColorPicker, { ColorPickerElement, ColorPickerRow } from '../ui/ColorPicker.js'
import { BUTTON_FILL, BUTTON_ROUNDING, CARD_OPTIONS, buttonOptions } from './types.js'
import VideoUploadModal from '../ui/VideoUploadModal.js'
import VideoPlayer from '../Components/VideoPlayer.js'
import ToggleRedGreen from '../ui/ToggleTwoOptions.js'
import ProcessingPreview from '../Components/ProcessingPreview.js'
import RecipeSteps from './Steps.js'
import Ingredients from './Ingredients.js'
import CodeCopy from '../ui/CodeCopy.js'
import TagsModal from '../ui/TagsModal.js'
import usePrompt from '../ui/BlockerPrompt.js'
import CircleToggle from '../ui/CircleToggle.js'
import ModalContainer from '../ui/ModalContainer.js'
import TickBox from '../ui/TickBox.js'
import Nutrition from './Nutrition.js'
import Checkbox from '../ui/Checkbox.js'
import DateTimePicker from '../ui/DateTimePicker.js'
import { motion, AnimatePresence } from 'framer-motion'
const FormInput = formikFormWrapper(Input)
const FormTextArea = formikFormWrapper(TextArea)

const PublishModal = ({ isOpen, setIsOpen, setFieldValue, saveFunction, values }) => {
  const hasUnpublishedVideo = values.daysArray?.flat().some((r) => !r.published)
  return (
    <ModalContainer isOpen={isOpen} setIsOpen={setIsOpen} p="24px" width="465px" autoHeight interiorClose>
      <Flex flexDirection="column">
        <H3 mb="24px">Publish</H3>
        <Text light mb="8px" fontWeight="600" lineHeight="100%">
          Notifications
        </Text>
        <Flex mb="8px" alignItems="center">
          <Flex
            mr="16px"
            height="auto"
            alignItems="center"
            cursor="pointer"
            onClick={() => {
              setFieldValue('isScheduled', false)
              setFieldValue('sendTo', 'NONE')
            }}
          >
            <Box>
              <CircleToggle width={20} color={colors.green} toggled={values.sendTo === 'NONE'} />
            </Box>
            <Text ml="16px" light>
              Publish without notifying
            </Text>
          </Flex>
        </Flex>
        <Flex mb="8px" alignItems="center">
          <Flex
            mr="16px"
            height="auto"
            alignItems="center"
            cursor="pointer"
            onClick={() => {
              setFieldValue('sendTo', 'PAID')
            }}
          >
            <Box>
              <CircleToggle width={20} color={colors.green} toggled={values.sendTo === 'PAID'} />
            </Box>
            <Text ml="16px" light>
              Notify paid subscribers
            </Text>
          </Flex>
        </Flex>
        <Flex mb="8px" alignItems="center">
          <Flex
            mr="16px"
            height="auto"
            alignItems="center"
            cursor="pointer"
            onClick={() => {
              setFieldValue('sendTo', 'EVERYONE')
            }}
          >
            <Box>
              <CircleToggle width={20} color={colors.green} toggled={values.sendTo === 'EVERYONE'} />
            </Box>
            <Text ml="16px" light>
              Notify everyone
            </Text>
          </Flex>
        </Flex>

        <AnimatePresence>
          {values.sendTo !== 'NONE' && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Flex flexDirection="column" mt="40px" mb="24px">
                <Text light mb="8px" fontWeight="600" lineHeight="100%">
                  Scheduling
                </Text>
                <Flex
                  alignItems="center"
                  mb="8px"
                  cursor="pointer"
                  onClick={() => setFieldValue('isScheduled', !values.isScheduled)}
                >
                  <Checkbox checked={values.isScheduled} />
                  <Text ml="16px" light>
                    Schedule time for publishing
                  </Text>
                </Flex>
                <AnimatePresence>
                  {values.isScheduled && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <DateTimePicker
                        minDateTime={new Date()}
                        value={values.sendAt ? values.sendAt : new Date()}
                        onChange={(newValue) => setFieldValue('sendAt', newValue)}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </Flex>
            </motion.div>
          )}
        </AnimatePresence>

        <Flex justifyContent="flex-end" alignItems="center">
          {hasUnpublishedVideo ? (
            <Error mr="16px">
              You have selected unpublished recipes
              {values.isScheduled ? `. Ensure recipes are also scheduled to be published.` : ''}
            </Error>
          ) : null}
          <Button
            variant="green"
            label="Publish"
            isDisabled={hasUnpublishedVideo && !values.isScheduled}
            onClick={async () => {
              if (hasUnpublishedVideo && !values.isScheduled) {
                return
              }
              await saveFunction()
              setIsOpen(false)
            }}
          />
        </Flex>
      </Flex>
    </ModalContainer>
  )
}

export default PublishModal
