import React, { useState, useEffect, useRef, useCallback } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { colors, formatSeconds } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, LineClamp } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import ModalContainer from '../ui/ModalContainer.js'
import { uniq, difference, intersection, indexBy, prop } from 'ramda'
import Tooltip from '../ui/Tooltip.js'
import ReactTooltip from 'react-tooltip'
import { useModal } from '../Modal.js'
import PopoverDropdown from '../ui/PopoverDropdown.js'
import { useContent } from '../ContentProvider.js'
import { useSearchParams } from 'react-router-dom'
import { useInbox } from '../InboxProvider.js'
import { useTheme } from '../ThemeProvider.js'
import { currencySymbols } from '../ui/formatters.js'
import Comments from './Comments/Comments.js'

const TABS = {
  ALL: 'ALL',
  COMMENTS: 'COMMENTS'
}

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && `border-bottom: 1px solid ${colors.green};`}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
    </TabText>
  )
}

const EmptyState = () => {
  return (
    <>
      <Text textAlign="center" fontWeight="500" fontSize="16px" lineHeight="25px" pt="150px">
        No activity yet
      </Text>
      <Text textAlign="center" lineHeight="25px" px="40px">
        You’ll be notified here about new subscribers, comments and replies
      </Text>
    </>
  )
}

const formatTimeDifference = (date) => {
  const now = new Date()
  const diff = now - new Date(date)
  const minutes = Math.floor(diff / 60000)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)

  if (years > 0) return `${years}y`
  if (months > 0) return `${months}m`
  if (days > 0) return `${days}d`
  if (hours > 0) return `${hours}h`
  if (minutes > 0) return `${minutes}m`
  return 'now'
}

const ITEMS_PER_PAGE = 25 // Number of items to load per scroll

const Inbox = (props) => {
  let { user, refresh } = useAuth()
  let navigate = useNavigate()
  const { comments, customers, isLoaded } = useInbox()
  const { recipes, mealPlans, posts } = useContent()
  const contentMap = indexBy(prop('id'), [...recipes, ...mealPlans, ...posts])
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTab, setActiveTabState] = useState(searchParams.get('tab') || TABS.ALL)

  const contentToShow = [...comments, ...(activeTab !== TABS.COMMENTS ? customers : [])]
    .filter((el) => !el.isCreator)
    .sort((a, b) => {
      if ((a.subscribedAt || a.createdAt) > (b.subscribedAt || b.createdAt)) {
        return -1
      }
      return 1
    })

  const [activeContent, setActiveContent] = useState(contentToShow?.[0])
  const activeRelevantContent = activeContent ? contentMap[activeContent.contentId] : null

  useEffect(() => {
    if (contentToShow.length && !activeContent) {
      setActiveContent(contentToShow[0])
    }
  }, [contentToShow])

  let { theme } = useTheme()

  const currencySymbol = currencySymbols[theme.plans?.[0]?.currency]

  const setActiveTab = (tab) => {
    setActiveTabState(tab)
    setDisplayedItems([])
    setPage(1)
    setSearchParams({ tab })
  }

  const updateLastRead = async () => {
    await api.post('/organisations/update', { inboxLastRead: new Date().toISOString() })
    await refresh()
  }
  useEffect(() => {
    setTimeout(updateLastRead, 1000)
  }, [])

  const [displayedItems, setDisplayedItems] = useState([])
  const [page, setPage] = useState(1)
  const containerRef = useRef(null)

  const loadMore = useCallback(() => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE
    const newItems = contentToShow.slice(0, startIndex + ITEMS_PER_PAGE)
    setDisplayedItems(newItems)
    setPage((prevPage) => prevPage + 1)
  }, [contentToShow, page])

  useEffect(() => {
    if (isLoaded) {
      setPage(1)
      loadMore()
    }
  }, [isLoaded, activeTab])

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current
      if (scrollTop + clientHeight >= scrollHeight - 20 && displayedItems.length < contentToShow.length) {
        loadMore()
      }
    }
  }, [displayedItems.length, contentToShow.length, loadMore])

  useEffect(() => {
    const currentContainer = containerRef.current
    if (currentContainer) {
      currentContainer.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])
  console.log(displayedItems.length, contentToShow.length)
  return (
    <Flex p="12px">
      <Container height="calc(100vh - 24px)" p="0px" overflow="hidden">
        <Flex p="24px" pb="0px" borderBottom="1px solid #EDF2F7">
          <Tab onClick={() => setActiveTab(TABS.ALL)} title="All" isActive={activeTab === TABS.ALL} />
          <Tab onClick={() => setActiveTab(TABS.COMMENTS)} title="Comments & Replies" isActive={activeTab === TABS.COMMENTS} />
        </Flex>
        <Flex height="calc(100% - 70px)">
          <Flex
            ref={containerRef}
            flexDirection="column"
            width="450px"
            minWidth="450px"
            borderRight="1px solid #EDF2F7"
            overflowY="scroll"
            noScrollBar
          >
            {displayedItems && !displayedItems.length ? <EmptyState /> : null}

            {displayedItems.map((content) => {
              const isComment = content.type === 'COMMENT'
              const relevantContent = content.contentId ? contentMap[content.contentId] : null
              return (
                <Flex
                  key={content.id}
                  p="16px"
                  borderBottom="1px solid #EDF2F7"
                  backgroundColor={activeContent?.id === content.id ? '#F7FAFC' : 'white'}
                  cursor="pointer"
                  minHeight="96px"
                  onClick={() => {
                    setActiveContent(content)
                  }}
                >
                  <Flex
                    width="32px"
                    minWidth="32px"
                    height="32px"
                    borderRadius="50%"
                    bg={isComment ? '#5BB3F9' : '#007D5B'}
                    justifyContent="center"
                    alignItems="center"
                    overflow="hidden"
                    mr="16px"
                  >
                    {content.isCreator ? (
                      <Image
                        objectFit="cover"
                        src={theme.commentsImageUrl + '?width=200&height=200'}
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <Text fontWeight="600" color="white">
                        {content?.firstName?.charAt(0)}
                      </Text>
                    )}
                  </Flex>
                  <Flex flexDirection="column">
                    <Text fontWeight="600" fontSize="14px" lineHeight="20px" light mb="4px">
                      {content.isCreator ? 'You' : content?.firstName}{' '}
                      {isComment ? (
                        <Span fontWeight="400">commented on</Span>
                      ) : (
                        <Span fontWeight="400">
                          subscribed on <Span fontWeight="600">{content?.planType?.toLowerCase()}</Span>
                        </Span>
                      )}
                    </Text>
                    <LineClamp lines={1} lineHeight="20px" fontWeight="600" light>
                      {relevantContent ? relevantContent?.name : ''}
                    </LineClamp>
                  </Flex>
                  <Text light whiteSpace="nowrap" fontSize="12px" lineHeight="20px; ">
                    {formatTimeDifference(content.subscribedAt || content.createdAt)}
                  </Text>
                </Flex>
              )
            })}
          </Flex>
          <Flex flexDirection="column" overflowY="scroll" noScrollBar maxWidth="100%" px="86px" pb="200px">
            {activeContent && activeContent.type === 'CUSTOMER' ? (
              <Flex flexDirection="column" alignItems="center" height="100%" pt="100px">
                <svg width="208" height="115" viewBox="0 0 208 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="21.1807" width="84" height="83" rx="8" transform="rotate(-7.08688 0 21.1807)" fill="#FDF4E8" />
                  <rect
                    x="60.7319"
                    y="7.90576"
                    width="84"
                    height="83"
                    rx="8"
                    transform="rotate(11.9131 60.7319 7.90576)"
                    fill="#5BB3F9"
                  />
                  <rect x="135.563" width="84" height="83" rx="8" transform="rotate(30.9131 135.563 0)" fill="#F4ACC4" />
                </svg>
                <Text fontSize="24px" fontWeight="600" lineHeight="130%" my="48px" textAlign="center">
                  A new subscriber has joined your Clubb
                </Text>
                <Text fontSize="16px" light lineHeight="20px" mb="48px" textAlign="center">
                  {activeContent?.firstName} joined on a {activeContent?.planType?.toLowerCase()} plan for {currencySymbol}
                  {(activeContent.subscriptionPrice / 100).toFixed(2)}
                  {activeContent.planType === 'MONTHLY' ? '/m' : '/yr'}
                </Text>
                <Link to="/customers">
                  <Button large variant="green" label="View all subscribers" />
                </Link>
              </Flex>
            ) : null}
            {activeContent && activeContent.type === 'COMMENT' ? (
              <>
                <Flex px="60px" mt="40px" mb="64px">
                  <Box
                    width="80px"
                    height="80px"
                    minWidth="80px"
                    minHeight="80px"
                    borderRadius="16px"
                    bg={colors.beige}
                    overflow="hidden"
                    mr="24px"
                  >
                    {activeRelevantContent?.coverImageUrl || activeRelevantContent?.thumbnailImageUrl ? (
                      <Image
                        objectFit="cover"
                        src={activeRelevantContent?.coverImageUrl || activeRelevantContent?.thumbnailImageUrl}
                        width="100%"
                        height="100%"
                      />
                    ) : null}
                  </Box>
                  <Flex flexDirection="column">
                    <LineClamp lines={1} fontWeight="600" fontSize="16px" lineHeight="20px" mb="4px">
                      {activeRelevantContent?.name || activeRelevantContent?.title}
                    </LineClamp>
                    <Text lineHeight="20px" light mb="16px">
                      {dayjs(activeRelevantContent?.publishedAt).format('DD MMMM YYYY')}
                    </Text>
                    <a
                      rel="noreferrer"
                      href={`https://${user.subdomain}.theclubb.co/${
                        activeRelevantContent?.type === 'RECIPE'
                          ? 'recipe'
                          : activeRelevantContent?.type === 'MEAL_PLAN'
                          ? 'meal-plan'
                          : 'post'
                      }/${activeRelevantContent?.slug || activeRelevantContent?.id}`}
                      target="_blank"
                    >
                      <Flex alignItems="center" cursor="pointer">
                        <Text lineHeight="20px" light mr="8px">
                          Open
                        </Text>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1 8.5C1 8.22386 1.22386 8 1.5 8L13.2929 8L10.1464 4.85355C9.95118 4.65829 9.95118 4.34171 10.1464 4.14645C10.3417 3.95118 10.6583 3.95118 10.8536 4.14645L14.8536 8.14645C15.0488 8.34171 15.0488 8.65829 14.8536 8.85355L10.8536 12.8536C10.6583 13.0488 10.3417 13.0488 10.1464 12.8536C9.95118 12.6583 9.95118 12.3417 10.1464 12.1464L13.2929 9H1.5C1.22386 9 1 8.77614 1 8.5Z"
                            fill="#425466"
                          />
                        </svg>
                      </Flex>
                    </a>
                  </Flex>
                </Flex>
                <Comments
                  commentId={activeContent.id}
                  contentId={activeContent.contentId}
                  contentType={activeContent.contentType}
                />
              </>
            ) : null}
          </Flex>
        </Flex>
      </Container>
    </Flex>
  )
}

export default Inbox
