import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { uniqBy } from 'ramda'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { toast } from 'react-toastify'
import { useAuth } from '../Auth.js'
import baseUrl from '../baseUrl.js'
import { useVideos } from '../ContentProvider.js'
import Billing from '../Billing/Billing.js'
import { isFreePlan } from '../Plans.js'
import { HTr, Th, HeaderText, Tr, Td, Table } from '../ui/Table.js'
import ModalContainer from '../ui/ModalContainer.js'

const ProfileImageFallback = styled(Flex)`
  width: 24px;
  height: 24px;
  background: #f4acc4;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
`

const roleMap = {
  ADMIN: 'Admin',
  USER: 'User'
}

const UserTab = (props) => {
  let { user, setUser } = useAuth()

  const [users, setUsers] = useState([])
  const [invites, setInvites] = useState([])
  const [hasLoaded, setHasLoaded] = useState(false)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [email, setEmail] = useState()

  const refresh = async () => {
    const res = await api.get('/organisation/get-users')
    if (res.data.success) {
      setUsers(res.data.payload.users)
      setInvites(res.data.payload.invites)
      setHasLoaded(true)
    }
  }

  const sendInvite = async () => {
    const res = await api.post('/organisation/invite-user', { email })
    if (res.data.success) {
      await refresh()
      setEmail('')
      setShowInviteModal(false)
    }
  }

  useEffect(() => {
    refresh()
  }, [])

  const uniqUsers = uniqBy((x) => x.email, [...users, ...invites])
  return (
    <>
      <Flex flexDirection="column" px="30px">
        {!hasLoaded ? (
          <Flex justifyContent="center" pt="200px">
            <BigDarkSpinner />
          </Flex>
        ) : (
          <Container p="0px" pt="16px" mb="100px">
            <SmallTitle ml="24px" mb="16px">
              Users
            </SmallTitle>
            <Table width="auto">
              <HTr>
                <Th>
                  <HeaderText left>NAME</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>DATE JOINED</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>ROLE</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>EMAIL</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>STATUS</HeaderText>
                </Th>
              </HTr>
              {uniqUsers.map((user) => {
                return (
                  <Tr key={user.email} justifyContent="space-between">
                    <Td left>
                      {user.firstName ? (
                        <Flex width="auto">
                          <ProfileImageFallback>
                            <Text color="white" fontWeight="500">
                              {user.firstName[0]}
                            </Text>
                          </ProfileImageFallback>
                          <Text ml="16px" fontWeight="500" light>
                            {user.firstName} {user.lastName}
                          </Text>
                        </Flex>
                      ) : (
                        '-'
                      )}
                    </Td>
                    <Td mb={0} lightLight left>
                      {user.createdAt ? dayjs(user.createdAt).format('MMM DD, YYYY') : '-'}
                    </Td>
                    <Td mb={0} lightLight left>
                      {user.role ? roleMap[user.role] : 'User'}
                    </Td>
                    <Td mb={0} lightLight left>
                      {user.email}
                    </Td>
                    <Td mb={0} lightLight left>
                      <Flex width="auto" alignItems="center">
                        <Box
                          backgroundColor={user.createdAt ? colors.green : '#F7936F'}
                          width={10}
                          height={10}
                          borderRadius={10}
                          mr="8px"
                        />
                        {user.createdAt ? 'Active' : 'Invite Sent'}
                      </Flex>
                    </Td>
                  </Tr>
                )
              })}
            </Table>
            <Flex alignItems="center" justifyContent="space-between" px="16px">
              <Text fontSize="14px" my="18px" lightLight width="auto">
                Showing {uniqUsers.length} user{uniqUsers.length > 1 ? 's' : ''}
              </Text>
              <Button
                renderLeftIcon={() => <Icon width={12} height={12} icon="plus-white" />}
                mb="8px"
                variant="green"
                label="Invite"
                onClick={() => setShowInviteModal(true)}
              />
            </Flex>
          </Container>
        )}
      </Flex>
      <ModalContainer interiorClose autoHeight p="24px" width="550px" isOpen={showInviteModal} setIsOpen={setShowInviteModal}>
        <Flex>
          <H3 mb="8px" width="auto" mr="16px">
            Invite teammates
          </H3>
        </Flex>
        <Text light width="100%" mb="40px">
          Collaborate on your site with your team
        </Text>
        <Flex mb="24px" alignItems="flex-end" justifyContent="center">
          <Input
            label="Email Address"
            border="0.5px solid #A6B7D4;"
            placeholder="Search"
            width="100%"
            mr="16px"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            variant="green"
            small
            label="Send"
            onClick={() => {
              sendInvite()
            }}
          />
        </Flex>
      </ModalContainer>
    </>
  )
}

export default UserTab
