import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors, w } from '../ui/helpers.js'
import { H1, H2, Text, Span, GradientFont, Error } from '../ui/Typography.js'
import { Flex, Box } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Button from '../ui/Button.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import Billing from './Billing.js'
import ChatButton from '../Components/ChatButton.js'
import baseUrl from '../baseUrl.js'

const LeftContainer = styled(Flex)`
  @media (max-width: 1240px) {
    width: 200px;
  }
  @media (max-width: 1150px) {
    display: none;
  }
`

const BillingPage = (props) => {
  let { user } = useAuth()

  let navigate = useNavigate()
  console.log(user)
  return (
    <Flex>
      <LeftContainer flexDirection="column" pt="76px" alignItems="center" width="350px" minHeight="100vh" bg={colors.darkGreen}>
        <Image mb="160px" src="/logo.svg" width={'calc(100% - 32px)'} maxWidth="186px" />
      </LeftContainer>
      <Flex position="relative" flexDirection="column" alignItems="center" width="100%" py="76px">
        <H1 mb="27px" textAlign="left" width="100%" px="60px">
          Choose a plan
        </H1>
        <Billing
          redirectUrl={user.shopifyShopUrl ? `${baseUrl}/shopify-install` : `${baseUrl}/?welcomeFlow=true`}
          canAlwaysSelectFree
        />
      </Flex>
    </Flex>
  )
}

export default BillingPage
