import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '../ui/Layout.js'
import { Text } from '../ui/Typography.js'
import Icon from '../ui/Icon.js'
import { numberWithCommas } from '../ui/helpers.js'

const DetailItem = styled(Flex)`
  align-items: center;
  margin-bottom: 12px;
`

const PostDetailsPopover = ({ content, createdAt, updatedAt }) => {
  // Calculate word count and read time
  const wordCount =
    content?.blocks?.reduce((count, block) => {
      if (block.type === 'subscribePrompt') {
        return count
      }
      return count + (block.data?.text?.split(/\s+/).length || 0)
    }, 0) || 0

  const readTime = Math.ceil(wordCount / 250) // Assuming 200 words per minute reading speed

  return (
    <Flex flexDirection="column" p="24px" width="256px">
      <Text fontSize="16px" fontWeight="600" lineHeight="100%" mb="24px">
        Post Details
      </Text>
      <Text lineHeight="100%" mb="16px">
        Word count: {numberWithCommas(wordCount)}
      </Text>
      <Text lineHeight="100%">
        Read time: {readTime} minute{readTime > 1 ? 's' : ''}
      </Text>
    </Flex>
  )
}

export default PostDetailsPopover
