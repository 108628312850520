import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate, useLocation } from 'react-router-dom'
import axios from './api.js'
import { useCookies } from 'react-cookie'
import Spinner from './ui/Spinner.js'
import { Flex, Box } from './ui/Layout.js'
import { GradientFont } from './ui/Typography.js'
import { colors } from './ui/helpers.js'
import api from './api.js'
import { useAuth } from './Auth.js'

let InboxContext = React.createContext()

export const InboxProvider = ({ children }) => {
  const [comments, setComments] = useState([])
  const [customers, setCustomers] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  let { user, setUser } = useAuth()

  const refresh = async () => {
    try {
      const [commentsRes, subscribersRes] = await Promise.all([
        api.get('/comments/admin-get-comments'),
        api.get('/customers/get-subscribers')
      ])

      if (commentsRes.data.success) {
        setComments(commentsRes.data.payload.map((x) => ({ ...x, type: 'COMMENT' })))
      }
      if (subscribersRes.data.success) {
        setCustomers(subscribersRes.data.payload.map((x) => ({ ...x, type: 'CUSTOMER' })))
      }
      setIsLoaded(true)
    } catch (error) {
      console.error('Error refreshing inbox data:', error)
    }
  }

  useEffect(() => {
    if (user?.organisationId) {
      refresh()
    }
  }, [user])
  const props = { comments, customers, refresh, isLoaded }
  return <InboxContext.Provider value={props}>{children}</InboxContext.Provider>
}

export const useInbox = () => {
  return React.useContext(InboxContext)
}
