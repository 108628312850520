import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate, useLocation } from 'react-router-dom'
import axios from './api.js'
import { useCookies } from 'react-cookie'
import Spinner from './ui/Spinner.js'
import { Flex, Box } from './ui/Layout.js'
import { GradientFont } from './ui/Typography.js'
import { colors } from './ui/helpers.js'
import api from './api.js'
import { useAuth } from './Auth.js'

export let ThemeContext = React.createContext()

export const defaultTheme = {
  accentColor: '#78818E',
  backgroundColor: '#ffffff',
  cardStyle: 'ROUNDED',
  homepageBody: 'Get exclusive access to my recipes and lots of other cool benefits.',
  homepageCta: 'Join now',
  homepageImageUrl: 'https://clipara.b-cdn.net/clubb-images/3c66f584-9606-4bc3-b342-9bba50539262.jpg',
  homepageTitle: 'Join the Clubb',
  logo: 'https://clipara.b-cdn.net/clubb-images/b90498cf-9045-4dbc-be6b-fbeccd822980.svg',
  primaryButtonColor: '#1A1A1A',
  primaryButtonFill: 'FILL',
  primaryButtonFontColor: '#FFFFFF',
  primaryButtonRound: 'PILL',
  primaryFontColor: '#121212',
  primaryFontFamily: 'Inter',
  secondaryButtonColor: '#1A1A1A',
  secondaryButtonFill: 'OUTLINE',
  secondaryButtonFontColor: '#1A1A1A',
  secondaryButtonRound: 'PILL',
  secondaryFontColor: '#575757',
  secondaryFontFamily: 'Inter'
}

export const ThemeProvider = ({ children }) => {
  let { user, setUser } = useAuth()
  const [theme, setThemeState] = useState(defaultTheme)

  const refresh = async (count) => {
    const res = await api.get(`/theme/get?organisationId=${user?.organisationId}`)
    if (res.data.success) {
      setThemeState(res.data.payload || defaultTheme)
    }
  }

  useEffect(() => {
    if (user?.organisationId) {
      refresh()
    }
  }, [user])

  const props = { theme, refresh }
  return <ThemeContext.Provider value={props}>{children}</ThemeContext.Provider>
}

export const useTheme = () => {
  return React.useContext(ThemeContext)
}
