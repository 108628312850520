import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import ModalContainer from '../ui/ModalContainer.js'
import SideNav, { TABS } from '../Components/SideNav.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { useVideos } from '../ContentProvider.js'
import ChatButton from '../Components/ChatButton'
import { BigDarkSpinner, BigSpinner } from '../ui/Spinner.js'
import { loadConnectAndInitialize } from '@stripe/connect-js'
import {
  ConnectPayments,
  ConnectPayouts,
  ConnectBalances,
  ConnectComponentsProvider,
  ConnectAccountOnboarding,
  ConnectAccountManagement
} from '@stripe/react-connect-js'

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 30px;
`
const StripeDash = () => {
  // We use `useState` to ensure the Connect instance is only initialized once
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const res = await api.get('stripe/check-connect-account-requirements')
      if (!res.data.success) {
        // Handle errors on the client side here
        return undefined
      } else {
        const clientSecret = res.data.payload.clientSecret
        return clientSecret
      }
    }

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey:
        'pk_test_51PMDhCHOkMZfJmbY9HvOafLqJHOiY807E1gGZDx5v0EXobqCOQGxFYbMk35kqPWOcbRbXt6IMXRE9JLXIkZufJEU002KEnGtPc',
      fetchClientSecret: fetchClientSecret
    })
  })

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <Container mb="24px">
        <ConnectPayments />
      </Container>
      <Flex mb="24px">
        <Container mr="24px">
          <ConnectPayouts />
        </Container>
        <Container>
          <ConnectBalances />
        </Container>
      </Flex>
      <Container mb="24px">
        <ConnectAccountManagement />
      </Container>
    </ConnectComponentsProvider>
  )
}

const Payments = (props) => {
  let { user, setUser } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [hasStripeAccount, setHasStripeAccount] = useState(false)
  const [hasRequirements, setHasRequirements] = useState(false)
  const [clientSecret, setClientSecret] = useState()

  useEffect(() => {
    const asyncFunction = async () => {
      const res = await api.get('stripe/check-connect-account-requirements')
      console.log(res.data)
      setHasRequirements(res.data.payload.hasRequirements)
      setHasStripeAccount(res.data.payload.hasStripeAccount)
      setClientSecret(res.data.payload.clientSecret)
      setInitialLoad(false)
    }
    asyncFunction()
  }, [])

  const createStripeAccount = async () => {
    setIsLoading(true)
    const response = await api.get('/stripe/create-connect-account')
    console.log(response)
    if (response.data.success) {
      window.location.href = response.data.payload.redirectUrl
    } else {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        <Flex position="relative" flexDirection="column" width="100%" p="30px">
          <Flex>
            <H1 mb="32px" width="100%" color="rgba(74, 85, 104, 1)">
              Payments
            </H1>
          </Flex>
        </Flex>
        <Flex px="24px">
          {initialLoad ? (
            <Flex alignItems="center" justifyContent="center" height="200px">
              <BigDarkSpinner />
            </Flex>
          ) : (
            <>
              {hasRequirements || !hasStripeAccount ? (
                <Container p="24px" width="300px" justifyContent="space-between">
                  <Icon icon="stripe-logo" width="76px" height="32px" mb="22px" />
                  <Text light mb="22px">
                    {hasRequirements && hasStripeAccount
                      ? 'Continue setting up your stripe account.'
                      : 'Setup and connect your stripe account.'}
                  </Text>
                  <Flex flexDirection="row-reverse" alignItems="center">
                    <Button
                      small
                      width="auto"
                      isLoading={isLoading}
                      variant="black"
                      label="Connect"
                      onClick={createStripeAccount}
                    />
                  </Flex>
                </Container>
              ) : (
                <Flex flexDirection="column">
                  <Container mb="24px" p="24px" width="300px" justifyContent="space-between">
                    <Icon icon="stripe-logo" width="76px" height="32px" mb="22px" />
                    <Text light mb="22px">
                      Go to stripe.
                    </Text>
                    <Flex flexDirection="row-reverse" alignItems="center">
                      <a href="https://dashboard.stripe.com/dashboard" target="_blank" rel="noreferrer">
                        <Button small width="auto" isLoading={isLoading} variant="black" label="Go to stripe" />
                      </a>
                    </Flex>
                  </Container>
                  <StripeDash clientSecret={clientSecret} />
                </Flex>
              )}
            </>
          )}
        </Flex>
      </Flex>
    </>
  )
}

export default Payments
