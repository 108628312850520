import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import Tooltip from '../ui/Tooltip.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import VideoPlayer from '../Components/VideoPlayer'
import ProcessingPreview from '../Components/ProcessingPreview'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { toast } from 'react-toastify'
import { useAuth } from '../Auth.js'
import { useVideos } from '../ContentProvider.js'
import ChatButton, { openChat } from '../Components/ChatButton'
import { useModal } from '../Modal.js'
import ReactTooltip from 'react-tooltip'

const Grid = styled(Flex)`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 35px;
  padding-left: 35px;
  padding-right: 35px;
  @media (max-width: 1400px) {
    grid-gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 1300px) {
    grid-gap: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }
`

const PlanContainer = styled(Flex)`
  ${(props) =>
    !props.mostPopular &&
    'border: 2px solid #e1e8ff;box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);'}
  border-radius: 16px;
  background-color: white;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
`

const MostPopular = styled(Flex)`
  background: linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%);
  padding: 3px;
  border-radius: 19px;
  position: relative;
`

const MostPopularText = styled(Box)`
  width: 112px;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translate(-50%, 0);
  background: linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%);
  border-radius: 12px;
  padding: 4px;
  padding-left: 12px;
  padding-right: 12px;
`

const Billing = (props) => {
  let { user, setUser } = useAuth()
  console.log(user)
  const navigate = useNavigate()

  const canAlwaysSelectFree = props.canAlwaysSelectFree
  const [showSpinner, setShowSpinner] = useState(false)

  const addPlan = async (planId) => {
    setShowSpinner(true)

    return addStripePlan(planId)
  }

  const addStripePlan = async (planId) => {
    const res = await api.post('/stripe/create-subscription', {
      planId,
      redirectUrl: props.redirectUrl,
      tolt: window.tolt_referral
    })
    if (res.data.payload.isFree) {
      window.location.href = props.redirectUrl
    }
    const redirectUrl = res.data.payload.redirectUrl
    if (redirectUrl) {
      window.location.href = redirectUrl
    }
  }

  const planId = user.shopifyShopUrl ? user.shopifyPlanId : user.stripePlanId
  const getButtonLabel = (inputPlanId) => {
    if (!planId) {
      return 'Select Plan'
    }
    if (canAlwaysSelectFree) {
      return 'Select Plan'
    }
    if (user.shopifyPlanId === inputPlanId || user.stripePlanId === inputPlanId) {
      return 'Current Plan'
    }
    if (planId > inputPlanId) {
      return 'Downgrade'
    } else {
      return 'Upgrade'
    }
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      <Grid>
        <PlanContainer>
          <Text fontWeight="700" fontSize="20px" mb="12px">
            Free
          </Text>
          <Text light fontWeight="500" textAlign="center" lineHeight="16px" mb="24px">
            Best for small sites and personal projects
          </Text>

          <Text fontWeight="700" fontSize="32px" mb="36px">
            $0
            <Span color={colors.textLight} fontSize="16px">
              {' '}
              /mo
            </Span>
          </Text>
          {(!user.shopifyPlanId && !user.stripePlanId) || user.shopifyPlanId === 1 ? <Flex mb="24px" /> : null}

          <Flex mb="24px">
            {planId === 1 && !canAlwaysSelectFree ? (
              <Button width="100%" isDisabled large variant="black" label="Current Plan" />
            ) : (
              <Button
                width="100%"
                large
                variant="black"
                isLoading={showSpinner}
                label={getButtonLabel(1)}
                onClick={() => addPlan(1)}
              />
            )}
          </Flex>

          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>5 mins video storage</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Deliver 100 minutes /mo</Text>
          </Flex>
          <Flex alignItems="center" mb="16px" pb="16px" borderBottom="1px solid #E1E8FF">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Up to 3 campaigns</Text>
          </Flex>

          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Interactive video embeds</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Off-site video marketing</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Video integrations</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Analytics</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Video CMS</Text>
          </Flex>
        </PlanContainer>

        <PlanContainer>
          <Text fontWeight="700" fontSize="20px" mb="12px">
            Basic
          </Text>
          <Text light fontWeight="500" textAlign="center" lineHeight="16px" mb="24px">
            Best for sites up to 10k visitors /mo
          </Text>
          <Text fontWeight="700" fontSize="32px">
            $24
            <Span color={colors.textLight} fontSize="16px">
              {' '}
              /mo
            </Span>
          </Text>
          {(user.shopifyPlanId && user.shopifyPlanId !== 1) || user.stripePlanId ? (
            <Flex mb="36px" />
          ) : (
            <Text color={colors.textLight} mb="36px">
              14 days free trial
            </Text>
          )}

          <Flex mb="24px">
            {planId === 5 ? (
              <Button width="100%" isDisabled large variant="black" label="Current Plan" />
            ) : (
              <Button
                width="100%"
                large
                variant="black"
                isLoading={showSpinner}
                label={getButtonLabel(5)}
                onClick={() => addPlan(5)}
              />
            )}
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>25 mins video storage</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Deliver 1,000 minutes /mo</Text>
          </Flex>
          <Flex alignItems="center" mb="16px" pb="16px" borderBottom="1px solid #E1E8FF">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Unlimited campaigns</Text>
          </Flex>

          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>All core features</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Add your own branding</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Unlimited seats</Text>
          </Flex>
          <Flex height="100%" flexDirection="column" justifyContent="flex-end">
            <Text textAlign="center" color={colors.textLight} mb="8px" fontSize="12px">
              $10 per additional 500 delivered minutes
            </Text>
          </Flex>
        </PlanContainer>

        <MostPopular>
          <MostPopularText>
            <Text color="white">Most popular</Text>
          </MostPopularText>
          <PlanContainer mostPopular>
            <Text fontWeight="700" fontSize="20px" mb="12px">
              Pro
            </Text>
            <Text light fontWeight="500" textAlign="center" lineHeight="16px" mb="24px">
              Best for sites with 10-80k visitors /mo
            </Text>
            <Text fontWeight="700" fontSize="32px">
              $79
              <Span color={colors.textLight} fontSize="16px">
                {' '}
                /mo
              </Span>
            </Text>
            {(user.shopifyPlanId && user.shopifyPlanId !== 1) || user.stripePlanId ? (
              <Flex mb="36px" />
            ) : (
              <Text color={colors.textLight} mb="36px">
                14 days free trial
              </Text>
            )}
            <Flex mb="24px">
              {planId === 6 ? (
                <Button width="100%" isDisabled large variant="black" label="Current Plan" />
              ) : (
                <Button
                  width="100%"
                  large
                  variant="black"
                  isLoading={showSpinner}
                  label={getButtonLabel(6)}
                  onClick={() => addPlan(6)}
                />
              )}
            </Flex>
            <Flex alignItems="center" mb="16px">
              <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
              <Text color={colors.textLight}>150 mins video storage</Text>
            </Flex>
            <Flex alignItems="center" mb="16px">
              <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
              <Text color={colors.textLight}>Deliver 6,500 minutes /mo</Text>
            </Flex>
            <Flex alignItems="center" mb="16px" pb="16px" borderBottom="1px solid #E1E8FF">
              <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
              <Text color={colors.textLight}>Unlimited campaigns</Text>
            </Flex>

            <Flex alignItems="center" mb="16px">
              <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
              <Text color={colors.textLight}>All core features</Text>
            </Flex>
            <Flex alignItems="center" mb="16px">
              <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
              <Text color={colors.textLight}>Additional embed customisations</Text>
            </Flex>
            <Flex alignItems="center" mb="16px">
              <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
              <Text color={colors.textLight}>Free onboarding calls & setup support</Text>
            </Flex>
            <Flex alignItems="center" mb="16px">
              <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
              <Text color={colors.textLight}>Unlimited seats</Text>
            </Flex>
            <Flex height="100%" flexDirection="column" justifyContent="flex-end">
              <Text textAlign="center" color={colors.textLight} mb="8px" fontSize="12px">
                $10 per additional 500 delivered minutes
              </Text>
            </Flex>
          </PlanContainer>
        </MostPopular>

        <PlanContainer>
          <Text fontWeight="700" fontSize="20px" mb="12px">
            Scale
          </Text>
          <Text light fontWeight="500" textAlign="center" lineHeight="16px" mb="24px">
            Best for sites with over 80k visitors /mo
          </Text>
          <Text fontWeight="700" fontSize="32px">
            $249
            <Span color={colors.textLight} fontSize="16px">
              {' '}
              /mo
            </Span>
          </Text>
          {(user.shopifyPlanId && user.shopifyPlanId !== 1) || user.stripePlanId ? (
            <Flex mb="36px" />
          ) : (
            <Text color={colors.textLight} mb="36px">
              14 days free trial
            </Text>
          )}

          <Flex mb="24px">
            {planId === 7 ? (
              <Button width="100%" isDisabled large variant="black" label="Current Plan" />
            ) : (
              <Button
                width="100%"
                large
                variant="black"
                isLoading={showSpinner}
                label={getButtonLabel(7)}
                onClick={() => addPlan(7)}
              />
            )}
          </Flex>

          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>1,000 mins video storage</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Deliver 25,000 minutes /mo</Text>
          </Flex>
          <Flex alignItems="center" mb="16px" pb="16px" borderBottom="1px solid #E1E8FF">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Unlimited campaigns</Text>
          </Flex>

          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>All core features</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Additional embed customisations</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Additional embed styles</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Branded subdomain on sharing links</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Free onboarding + optimisation sessions</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>1:1 Slack Channel</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Additional Support</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Account manager & regular check-ins</Text>
          </Flex>
          <Flex alignItems="center" mb="16px">
            <Icon mr="8px" width="20px" height="20px" icon="check-circle-black" />
            <Text color={colors.textLight}>Unlimited seats</Text>
          </Flex>
          <Flex height="100%" flexDirection="column" justifyContent="flex-end">
            <Text textAlign="center" color={colors.textLight} mb="8px" fontSize="12px">
              $5 per additional 500 delivered minutes
            </Text>
          </Flex>
        </PlanContainer>
      </Grid>
      <Flex p="35px">
        <PlanContainer>
          <Flex mb="16px" alignItems="center">
            <Flex>
              <Text fontSize="18px" fontWeight="700" ml="16px" mr="40px">
                Enterprise
              </Text>
              <Text mr="40px">
                For custom scale plans, special use cases, whitelabelling, premium strategy support, complete customisation and
                much more, get in touch.
              </Text>
            </Flex>
            <Flex justifyContent="flex-end" width="auto">
              <Button width="218px" large variant="white" borderColor="black" label="Contact Sales" onClick={() => openChat()} />
            </Flex>
          </Flex>
        </PlanContainer>
      </Flex>
    </Flex>
  )
}

export default Billing
