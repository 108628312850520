import React from 'react'
import styled from 'styled-components'
import { colors } from './helpers.js'
import { Box, Flex } from './Layout.js'
import { Text } from './Typography.js'
import Spinner from './Spinner.js'
import Button from './Button.js'

import {
  space,
  width,
  color,
  flex,
  order,
  fontSize,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  borders,
  minWidth,
  minHeight,
  borderRadius,
  size,
  height,
  maxWidth,
  textAlign,
  boxShadow,
  borderColor,
  position,
  display,
  top,
  bottom,
  left,
  right,
  zIndex
} from 'styled-system'

const Circle = styled(Box)`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: white;
  position: absolute;
  transition: left 250ms ease-in-out 0s, right 250ms ease 0s;
  top: 3px;
`

const Container = styled(Flex)`
  position: relative;
  height: 22px;
  width: 40px;
  transition: background 250ms ease-in-out, transform 150ms ease;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%);
`

export default ({ leftColor, rightColor, value1, value2, selected, setSelected, mb, mt, customActiveColor }) => {
  return (
    <Container
      onClick={(e) => {
        e.stopPropagation()
        setSelected(selected === value1 ? value2 : value1)
      }}
      height="22px"
      width="40px"
      minWidth="40px"
      justifyContent="space-between"
      borderRadius="20px"
      bg={selected === value1 ? '#E4EBF6' : customActiveColor || 'black'}
      mb={mb}
      mt={mt}
    >
      <Circle left={selected === value1 ? '3px' : '21px'} />
      {/* <Button
        onClick={() => setSelected(value1)}
        bg={selected === value1 ? leftColor || '#FFFFFF' : '#F6F7F9'}
        disabled={selected === value1}
        height="34px"
        boxShadow={selected === value1 ? '0px 2px 5px 0px rgba(0,0,0,0.1)' : ''}
      />
      <Button
        onClick={() => setSelected(value2)}
        bg={selected === value2 ? rightColor || '#FFFFFF' : '#F6F7F9'}
        disabled={selected === value2}
        height="34px"
        boxShadow={selected === value2 ? '0px 2px 5px 0px rgba(0,0,0,0.1)' : ''}
      /> */}
    </Container>
  )
}
