import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label, Li } from '../ui/Typography.js'
import { Flex, Box, Container, Ul } from '../ui/Layout.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { Formik, Field } from 'formik'
import SelectComponent from '../ui/Select.js'
import { currencyOptions } from './types.js'
import { Input, TextArea } from '../ui/Input.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Button from '../ui/Button.js'
import ImageUploadModal from '../ui/ImageUploadModal.js'
import Image from '../ui/Image.js'
import Icon from '../ui/Icon.js'
import { defaultTheme, useTheme } from '../ThemeProvider.js'
import CircleToggle from '../ui/CircleToggle.js'
import { currencySymbols, toTitleCase } from '../ui/formatters.js'
import { props } from 'bluebird'

const TickSvg = ({ colorProp, config }) => {
  return (
    <Box minWidth="20px" mt="1px">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill={colorProp ? config[colorProp] : config.primaryButtonColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.7116 6.21209C14.0777 5.84597 14.6713 5.84597 15.0374 6.21209C15.399 6.57371 15.4034 7.15727 15.0507 7.52434L10.0604 13.7622C10.0532 13.7712 10.0455 13.7798 10.0374 13.7879C9.67126 14.154 9.07767 14.154 8.71155 13.7879L5.40349 10.4799C5.03738 10.1137 5.03738 9.52015 5.40349 9.15403C5.76961 8.78791 6.3632 8.78791 6.72932 9.15403L9.34622 11.7709L13.6867 6.24013C13.6944 6.23029 13.7027 6.22093 13.7116 6.21209Z"
          fill={colorProp ? config[colorProp] : config.primaryButtonColor}
        />
      </svg>
    </Box>
  )
}

const FontText = styled(Text)`
  color: ${(props) => props.config.secondaryFontColor};
  font-family: ${(props) => (props.light ? props.config?.secondaryFontFamily : props.config?.primaryFontFamily)} !important;
`

const Styler = styled(Flex)`
  h1 {
    font-family: ${(props) => props.config.primaryFontFamily} !important;
    color: ${(props) => props.config.primaryFontColor} !important;
  }
  button.primary {
    border-radius: ${(props) => {
      if (props.config.primaryButtonRound === 'PILL') {
        return '50px'
      }
      if (props.config.primaryButtonRound === 'ROUNDED') {
        return '8px'
      }
      if (props.config.primaryButtonRound === 'SQUARE') {
        return '0px'
      }
    }} !important;
    ${(props) => {
      if (props.config.primaryButtonFill === 'FILL') {
        return `background-color: ${props.config.primaryButtonColor};
      color: ${props.config.primaryButtonFontColor};`
      }
      if (props.config.primaryButtonFill === 'OUTLINE') {
        return `border: 1px solid ${props.config.primaryButtonColor};
      background-color: transparent;
      color: ${props.config.primaryButtonFontColor};`
      }
    }} !important;
    font-family: ${(props) => props.config.secondaryFontFamily} !important;
  }
`
const ResponsiveContainer = styled(Flex)`
  @media (min-width: 1600px) {
    height: 800px;
    width: 414px;
  }
`

const PreviewPaywall = ({ values }) => {
  let { user, setUser } = useAuth()
  const { theme: config } = useTheme()

  const plans = [
    {
      currency: values.currency,
      freeTrialDays: values.freeTrialDays,
      type: 'MONTHLY',
      price: values.monthlyPrice,
      features: [
        values.monthly && values.monthly['1'],
        values.monthly && values.monthly['2'],
        values.monthly && values.monthly['3']
      ].filter((el) => el)
    },
    {
      currency: values.currency,
      freeTrialDays: values.freeTrialDays,
      type: 'ANNUAL',
      price: values.annualPrice,
      features: [
        values.annual && values.annual['1'],
        values.annual && values.annual['2'],
        values.annual && values.annual['3']
      ].filter((el) => el)
    },
    {
      currency: values.currency,
      freeTrialDays: values.freeTrialDays,
      type: 'PREMIUM',
      name: values.premium && values.premium.name,
      price: values.premiumPrice,
      features: [
        values.premium && values.premium['1'],
        values.premium && values.premium['2'],
        values.premium && values.premium['3']
      ].filter((el) => el)
    }
  ]

  const [selectedPlanId, setSelectedPlanId] = useState(plans[1]?.type || plans[0]?.type)
  const selectedPlan = plans.find((el) => el.type === selectedPlanId)

  let cheapestPerMonthPrice = plans[0].price
  if (plans[1].price / 12 < cheapestPerMonthPrice) {
    cheapestPerMonthPrice = (Math.round((plans[1].price / 12) * 100) / 100).toFixed(2)
  }

  return (
    <Styler config={config} width="auto">
      <ResponsiveContainer
        backgroundColor={config.backgroundColor}
        height="600px"
        width="340px"
        borderRadius="32px"
        boxShadow="0px 4px 8px 4px rgba(118, 118, 118, 0.25)"
        flexDirection="column"
        alignItems="center"
        overflow="hidden"
      >
        <Box width="100%" height="215px">
          <Image src={values.paywall.imageUrl || defaultTheme.homepageImageUrl} width="100%" height="100%" objectFit="cover" />
        </Box>

        <Flex p="24px" flexDirection="column" height="100%">
          <Flex flexDirection="column" height="100%">
            <H1 fontSize="24px" mb="8px">
              {values.paywall.title}
            </H1>
            <Ul>
              {values.paywall[1] ? (
                <li>
                  <FontText mb="16px" config={config} light>
                    {values.paywall[1]}
                  </FontText>
                </li>
              ) : null}
              {values.paywall[2] ? (
                <li>
                  <FontText mb="16px" config={config} light>
                    {values.paywall[2]}
                  </FontText>
                </li>
              ) : null}
              {values.paywall[3] ? (
                <li>
                  <FontText mb="16px" config={config} light>
                    {values.paywall[3]}
                  </FontText>
                </li>
              ) : null}
            </Ul>
          </Flex>

          <Box>
            <FontText fontSize="14px" config={config} light mb="8px" fontWeight="400">
              From just {currencySymbols[plans[0].currency]}
              {cheapestPerMonthPrice}
              /m
            </FontText>
            <Button width="100%" className="primary" label="Subscribe" />
          </Box>
        </Flex>
      </ResponsiveContainer>
    </Styler>
  )
}

export default PreviewPaywall
