import { useEffect, useState } from 'react'
import Button from '../ui/Button'
import Icon from '../ui/Icon'
import Image from '../ui/Image'
import { Input, TextArea } from '../ui/Input'
import { Box, Flex } from '../ui/Layout'
import { Error, H3, Text } from '../ui/Typography'
import { uniq, update } from 'ramda'
import ModalContainer from '../ui/ModalContainer'
import api from '../api'
import { colors } from '../ui/helpers'
import Spinner, { DarkBlockSpinner, GreenSpinner } from '../ui/Spinner'

const LoadingComponent = () => {
  const [activeText, setActiveText] = useState('Reading ingredients...')

  useEffect(() => {
    setTimeout(() => {
      setActiveText('Tidying up...')
    }, 4000)

    setTimeout(() => {
      setActiveText('A little bit of magic...')
    }, 8000)
  }, [])

  return (
    <Flex justifyContent="flex-end" height="40px" alignItems="center">
      <Text mr="8px" color={colors.green}>
        {activeText}
      </Text>
      <GreenSpinner />
    </Flex>
  )
}

const AddIngredientsModal = ({ isOpen, setIsOpen, setIngredients, ingredients }) => {
  const [list, setList] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const convertList = async () => {
    setIsLoading(true)
    try {
      const res = await api.post('/content/convert-ingredients-to-object', { ingredientList: list })
      setIngredients(uniq([...ingredients, ...res.data.payload]))
      setIsOpen(false)
    } catch (e) {
      setError('There was an error converting your ingredients, please try again')
    }
    setIsLoading(false)

    return
  }

  return (
    <ModalContainer
      interiorClose
      p="16px"
      pt="32px"
      pb="16px"
      width="700px"
      height="400px"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => null}
    >
      <Flex flexDirection="column" height="100%" pt="16px" px="8px">
        <H3 mb="8px">Add your ingredients</H3>
        <Text mb="16px">Write out or paste your ingredients below and we’ll format them for you</Text>
        <TextArea value={list} onChange={(e) => setList(e.target.value)} height="100%" />
        <Flex justifyContent="flex-end" pt="16px">
          {isLoading ? (
            <LoadingComponent />
          ) : (
            <Flex flexDirection="column" alignItems="flex-end">
              <Box>
                <Button variant="green" label="Convert list" onClick={convertList} />
              </Box>
              {error && <Error mt="8px">{error}</Error>}
            </Flex>
          )}
        </Flex>
      </Flex>
    </ModalContainer>
  )
}

export default AddIngredientsModal
