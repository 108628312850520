import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { LineClamp, Text } from '../ui/Typography'
import { Box, Flex } from '../ui/Layout'
import { minutesFormat } from '../ui/formatters'
import { useAuth } from '../Auth'

const Image = styled.img`
  box-sizing: border-box !important;
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  background: -webkit-linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  animation: loading-gradient 4s linear infinite;
  background-size: 200%;
  ${(props) => {
    if (props.cardStyle === 'EXTRA_ROUNDED') return `border-radius: 16px;`
    if (props.cardStyle === 'ROUNDED') return `border-radius: 8px;`
    return `border-radius: 8px;`
  }}
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: calc(10 / 10 * 100%);
`

const MealPlanCard = ({ result, deleteFromPlan }) => {
  const { user, refresh } = useAuth()
  const src = result.coverImageUrl
    ? `${result?.coverImageUrl}?width=700`
    : result?.playbackId
    ? `https://image.mux.com/${result?.playbackId}/thumbnail.jpg?time=0&width=600`
    : undefined

  return (
    <Box position="relative" mb="32px">
      <Flex flexDirection="column" alignItems="flex-start">
        <Container key={result.id} mb="8px" bg="#FDF4E8" borderRadius="8px">
          {src ? <Image draggable={false} loading="lazy" src={src} alt={result.name} /> : null}
        </Container>
        <LineClamp lines={2} minHeight="40px" fontSize="14px" mb="8px" lineHeight="130%" fontWeight="500" wordBreak="break-word">
          {result.name}
        </LineClamp>
        <Flex alignItems="center" justifyContent="space-between">
          <Text light>
            {result.totalTime ? (
              <Flex alignItems="center">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                    fill="#425466"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.5 3C7.77614 3 8 3.22386 8 3.5V8.70984L11.2481 10.5659C11.4878 10.7029 11.5711 11.0083 11.4341 11.2481C11.2971 11.4878 10.9917 11.5711 10.7519 11.4341L7.25193 9.43412C7.09614 9.3451 7 9.17943 7 9V3.5C7 3.22386 7.22386 3 7.5 3Z"
                    fill="#425466"
                  />
                </svg>
                <Text light ml="8px" fontSize="14px">
                  {minutesFormat((result.totalTime || 0) + (result.cookingTime || 0))}
                </Text>
              </Flex>
            ) : null}
          </Text>

          <Box cursor="pointer" onClick={deleteFromPlan}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.30769 5.86667C5.60508 5.86667 5.84615 6.10545 5.84615 6.4V12.8C5.84615 13.0946 5.60508 13.3333 5.30769 13.3333C5.01031 13.3333 4.76923 13.0946 4.76923 12.8V6.4C4.76923 6.10545 5.01031 5.86667 5.30769 5.86667Z"
                fill="#3E3E3E"
              />
              <path
                d="M8 5.86667C8.29738 5.86667 8.53846 6.10545 8.53846 6.4V12.8C8.53846 13.0946 8.29738 13.3333 8 13.3333C7.70262 13.3333 7.46154 13.0946 7.46154 12.8V6.4C7.46154 6.10545 7.70262 5.86667 8 5.86667Z"
                fill="#3E3E3E"
              />
              <path
                d="M11.2308 6.4C11.2308 6.10545 10.9897 5.86667 10.6923 5.86667C10.3949 5.86667 10.1538 6.10545 10.1538 6.4V12.8C10.1538 13.0946 10.3949 13.3333 10.6923 13.3333C10.9897 13.3333 11.2308 13.0946 11.2308 12.8V6.4Z"
                fill="#3E3E3E"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 3.2C15 3.7891 14.5178 4.26667 13.9231 4.26667H13.3846V13.8667C13.3846 15.0449 12.4203 16 11.2308 16H4.76923C3.57969 16 2.61538 15.0449 2.61538 13.8667V4.26667H2.07692C1.48215 4.26667 1 3.7891 1 3.2V2.13333C1 1.54423 1.48215 1.06667 2.07692 1.06667H5.84615C5.84615 0.477563 6.32831 0 6.92308 0H9.07692C9.67169 0 10.1538 0.477563 10.1538 1.06667H13.9231C14.5178 1.06667 15 1.54423 15 2.13333V3.2ZM3.81942 4.26667L3.69231 4.32962V13.8667C3.69231 14.4558 4.17446 14.9333 4.76923 14.9333H11.2308C11.8255 14.9333 12.3077 14.4558 12.3077 13.8667V4.32962L12.1806 4.26667H3.81942ZM2.07692 3.2V2.13333H13.9231V3.2H2.07692Z"
                fill="#3E3E3E"
              />
            </svg>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

export default MealPlanCard
