import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'
import { useModal } from '../Modal.js'

export const initChat = (user) => {
  window.$crisp && window.$crisp.push(['set', 'user:email', [user.email]])
  window.$crisp && window.$crisp.push(['set', 'user:id', [user.id]])
  window.$crisp && window.$crisp.push(['set', 'user:organisationId', [user.organisationId]])
  window.$crisp && window.$crisp.push(['set', 'user:nickname', [user.firstName + ' ' + user.lastName]])
}

export const openChat = () => {
  window.$crisp.push(['do', 'chat:open'])
  setTimeout(() => window.$crisp.push(['do', 'chat:show']), 100)
}

const ChatButton = ({ showCallButton, noMargin }) => {
  const { setShowReferModal } = useModal()

  return (
    <>
      {showCallButton ? (
        <Box mr={noMargin ? '0px' : '18px'}>
          <Flex
            onClick={() => {
              setShowReferModal(true)
            }}
            minWidth="153px"
            cursor="pointer"
            bg="white"
            white="16px"
            px="24px"
            alignItems="center"
            height="40px"
            borderRadius="8px"
            boxShadow="0px 0px 1px rgba(12, 26, 75, 0.1), 0px 4px 20px -2px rgba(50, 50, 71, 0.08);"
          >
            <Icon icon="present-green" width="16px" height="16px" mr="16px" />
            <Text light lineHeight="16px" fontWeight="700" fontSize="12px">
              Refer & Earn
            </Text>
          </Flex>
        </Box>
      ) : null}
    </>
  )
}

export default ChatButton
