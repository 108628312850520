import React, { useEffect } from 'react'
import styled from 'styled-components'
import { colors } from './helpers'
import { Label, Error, Text, Span } from './Typography'
import { Flex, Box } from './Layout'
import Icon from './Icon'
import ReactTooltip from 'react-tooltip'

const Tooltip = styled(Icon)`
  cursor: pointer;
`

const TooltipComponent = ({ tooltip }) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  })
  return (
    <Box width="auto" data-tip={tooltip}>
      <Tooltip width="16" height="16" icon="tooltip-grey" />
    </Box>
  )
}

export default TooltipComponent
