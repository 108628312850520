import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { colors, formatSeconds } from './helpers'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from './Typography'
import { Flex, Box, Container } from './Layout'
import { Input } from './Input'
import Icon from './Icon'
import Button from './Button'
import { BigDarkSpinner } from './Spinner'
import api from '../api'
import * as UpChunk from '@mux/upchunk'

import ModalContainer from './ModalContainer'
import * as Sentry from '@sentry/react'
import { useAuth } from '../Auth'
import bluebird from 'bluebird'
import { ProgressBar, ProgressBarProgress, SpinningIcon } from './ProgressBar'
import axios from 'axios'

const DragDropContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

const StyledInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  background-color: red;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const TABS = {
  UPLOAD: 'UPLOAD',
  EXISTING: 'EXISTING'
}

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && `border-bottom: 1px solid ${colors.green};`}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive} light={isActive ? undefined : true}>
      {title}
    </TabText>
  )
}

const VideoUploadModal = ({ isOpen, setIsOpen, saveFunction, multiple }) => {
  const [files, setFiles] = useState()
  const { user } = useAuth()
  const [isFinished, setIsFinished] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [numberUploaded, setNumberUploaded] = useState(0)
  const [percentProgressObj, setPercentProgressObj] = useState({})
  const [activeTab, setActiveTab] = useState(TABS.UPLOAD)

  const getUploadUrlId = async () => {
    try {
      const res = await api.get('/video/get-upload-url')
      if (res.data.success) {
        return { url: decodeURIComponent(res.data.payload.url), id: decodeURIComponent(res.data.payload.id) }
      }
    } catch (e) {
      Sentry.captureException(e, {
        extra: user
      })
    }
  }

  const startUpload = async () => {
    console.log('starting upload', files)

    try {
      let temp = {}
      files.forEach((file) => {
        temp = { ...temp, [file.name]: '0' }
      })
      setPercentProgressObj(temp)
      setIsUploading(true)
    } catch (e) {
      Sentry.captureException(e, {
        extra: user
      })
    }
    const ids = await bluebird.mapSeries(files, async (file) => {
      const uploadObj = await getUploadUrlId()
      const id = await new Promise((resolve, reject) => {
        const upload = UpChunk.createUpload({
          endpoint: uploadObj.url,
          file,
          chunkSize: 5120
        })
        upload.on('error', (err) => {
          console.error('💥 🙀', err.detail)
          return resolve()
        })
        upload.on('progress', (progress) => {
          console.log('Uploaded', progress.detail, 'percent of this file.')
          setPercentProgressObj((prevState) => {
            return { ...prevState, [file.name]: progress.detail === 100 ? 90 : progress.detail }
          })
        })
        // subscribe to events
        upload.on('success', async (err) => {
          console.log(err, 'success')
          console.log("Wrap it up, we're done here. 👋")
          console.log(uploadObj)
          const res = await api.post('/video/create', { uploadId: uploadObj.id, name: file.name })
          setPercentProgressObj((prevState) => {
            return { ...prevState, [file.name]: 100 }
          })
          console.log(res.data.payload, '<<<')
          return resolve(res.data.payload.id)
        })
      })
      return id
    })

    setTimeout(() => {
      saveFunction && saveFunction(ids)
      setIsOpen(false)
      setIsUploading(false)
    }, 100)
  }

  useEffect(() => {
    const func = async () => {
      try {
        if (!files || !files.length) {
          return
        }

        if (files && files.length) {
          setIsUploading(true)
          startUpload()
        }
      } catch (e) {
        Sentry.captureException(e, { extra: user })
      }
    }
    func()
  }, [files])

  const UploadInner = (
    <>
      {(files || []).map((file) => {
        return (
          <Flex key={file.name} flexDirection="column" alignItems="center">
            <Text light mb="8px" width="550px" wordBreak="break-all">
              {file.name}
            </Text>
            <ProgressBar mb="8px" width="550px">
              <ProgressBarProgress finished={percentProgressObj[file.name] === 100} width={`${percentProgressObj[file.name]}%`} />
            </ProgressBar>
            <Flex justifyContent="space-between" width="550px" maxWidth="550px">
              <Text lightLight>{Math.round(file.size / 10000) / 100} MB</Text>
              <Text lightLight maxWidth="550px">
                {Math.round(percentProgressObj[file.name])}%
              </Text>
            </Flex>
          </Flex>
        )
      })}
    </>
  )

  return (
    <ModalContainer interiorClose p="0px" width="800px" height="500px" isOpen={isOpen} setIsOpen={setIsOpen} onClose={() => null}>
      <Flex borderBottom="1px solid rgba(237, 242, 247, 1)" px="24px" pt="24px">
        <Flex>
          <Tab onClick={() => setActiveTab(TABS.UPLOAD)} title="Upload" isActive={activeTab === TABS.UPLOAD} />
          {/* <Tab onClick={() => setActiveTab(TABS.EXISTING)} title="Library" isActive={activeTab === TABS.EXISTING} /> */}
        </Flex>
      </Flex>
      {isUploading ? (
        <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100%">
          {UploadInner}
        </Flex>
      ) : (
        <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100%" p="24px">
          <DragDropContainer>
            <svg width="208" height="115" viewBox="0 0 208 115" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="21.1807" width="84" height="83" rx="8" transform="rotate(-7.08688 0 21.1807)" fill="#FDF4E8" />
              <rect
                x="60.7319"
                y="7.90576"
                width="84"
                height="83"
                rx="8"
                transform="rotate(11.9131 60.7319 7.90576)"
                fill="#5BB3F9"
              />
              <rect x="135.563" width="84" height="83" rx="8" transform="rotate(30.9131 135.563 0)" fill="#F4ACC4" />
            </svg>
            <Button mt="24px" mb="24px" variant="green" label="Upload" />
            <Text light fontSize="14px" fontWeight="500">
              or drag file here
            </Text>

            <StyledInput
              type="file"
              id="clipara-file-picker"
              name="file-picker"
              accept="video/mp4,video/x-m4v,video/*"
              multiple={multiple ? 'multiple' : false}
              onChange={(e) => {
                const filePicker = document.getElementById('clipara-file-picker')
                console.log(filePicker.files)
                if (filePicker.files[0]) {
                  setFiles(Array.from(filePicker.files))
                }
              }}
            />
          </DragDropContainer>
        </Flex>
      )}
    </ModalContainer>
  )
}

export default VideoUploadModal
