import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { uniqBy } from 'ramda'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, numberWithCommas } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { toast } from 'react-toastify'
import { useAuth } from '../Auth.js'
import baseUrl from '../baseUrl.js'
import { useVideos } from '../ContentProvider.js'
import { useModal } from '../Modal.js'
import Billing from '../Billing/Billing.js'
import { isFreePlan } from '../Plans.js'
import { HTr, Th, HeaderText, Tr, Td, Table } from '../ui/Table.js'
import ModalContainer from '../ui/ModalContainer.js'

const DeliveredMinutesTab = (props) => {
  let { user, setUser } = useAuth()

  const [deliveredMinutes, setDeliveredMinutes] = useState([])
  const [hasLoaded, setHasLoaded] = useState(false)

  const refresh = async () => {
    const res = await api.post('/organisations/get-delivered-minutes')
    if (res.data.success) {
      setDeliveredMinutes(res.data.payload)
      setHasLoaded(true)
    }
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <>
      <Flex flexDirection="column" px="30px">
        {!hasLoaded ? (
          <Flex justifyContent="center" pt="200px">
            <BigDarkSpinner />
          </Flex>
        ) : (
          <Container p="0px" pt="16px" mb="100px">
            <SmallTitle ml="24px" mb="16px">
              Delivered Minutes
            </SmallTitle>
            <Table width="auto">
              <HTr>
                <Th>
                  <HeaderText left>MONTH</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>PERIOD</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>DELIVERED MINUTES</HeaderText>
                </Th>
              </HTr>
              {deliveredMinutes.map((dm, i) => {
                return (
                  <Tr key={dm.startDate} justifyContent="space-between">
                    <Td mb={0} lightLight left>
                      <Text light fontWeight={600}>
                        {dayjs(dm.startDate).format('MMMM YYYY')}
                        {i === 0 ? ' (Current)' : ''}
                      </Text>
                    </Td>
                    <Td mb={0} lightLight left>
                      {dayjs(dm.startDate).format('MMM DD')} - {dayjs(dm.endDate).subtract(1, 'day').format('MMM DD')}
                    </Td>
                    <Td mb={0} lightLight left>
                      {numberWithCommas(Math.round(dm.sum))}
                      {i === 0 ? ' (To date)' : ''}
                    </Td>
                  </Tr>
                )
              })}
            </Table>
          </Container>
        )}
      </Flex>
    </>
  )
}

export default DeliveredMinutesTab
