import React from 'react'
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Flex } from './Layout'
import { colors } from './helpers'
import { Label } from './Typography'

const Styler = styled(Flex)`
  flex-direction: column;
  width: 100%;
  z-index: 2147483647;
  .MuiFormControl-root {
    width: 100%;
  }
  .Mui-selected {
    font-weight: 900 !important;
    background-color: ${colors.green} !important;
    background-color: red !important;
  }
  .MuiInputBase-root {
    border-radius: 8px;
    background-color: #fbfbfb;
    border: none !important;
    height: 42px;
    box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
  }

  fieldset {
    border: none !important;
  }

  input {
    font-family: 'Inter', sans-serif !important;
    color: ${colors.textDark} !important;
  }
`

const DateTimePicker = ({ label, value, onChange, minDateTime, ...props }) => {
  return (
    <Styler>
      <Label mb="8px">{label}</Label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDateTimePicker
          minDateTime={minDateTime ? dayjs(minDateTime) : null}
          value={value ? dayjs(value) : null}
          onChange={(newValue) => onChange(newValue ? newValue.toDate() : null)}
          {...props}
        />
      </LocalizationProvider>
    </Styler>
  )
}

export default DateTimePicker
