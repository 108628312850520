import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'

const StatusPill = ({ widgetActive, isShareCampaign }) => {
  if (isShareCampaign) {
    return <Text>-</Text>
  }
  if (!widgetActive) {
    return (
      <Box px="10px" py="4px" borderRadius="6px" width="fit-content" bg="#E4ECF7">
        <Text fontWeight="700" width="auto" color="#505780">
          PAUSED
        </Text>
      </Box>
    )
  }
  return (
    <Box px="10px" py="4px" borderRadius="6px" width="fit-content" bg="#DEFFEE">
      <Text fontWeight="700" width="auto" color="#66CB9F">
        LIVE
      </Text>
    </Box>
  )
}

export default StatusPill
