import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { Formik, Field } from 'formik'
import SelectComponent from '../ui/Select.js'
import { Input, TextArea } from '../ui/Input.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Button from '../ui/Button.js'
import ImageUploadModal from '../ui/ImageUploadModal.js'
import Image from '../ui/Image.js'
import Icon from '../ui/Icon.js'
import ColorPicker, { ColorPickerElement, ColorPickerRow } from '../ui/ColorPicker.js'
import { BUTTON_FILL, BUTTON_ROUNDING, CARD_OPTIONS, buttonOptions } from './types.js'
import FontPicker from './FontPicker.js'
import { defaultTheme, useTheme } from '../ThemeProvider.js'
import Preview from './Preview.js'
import usePrompt from '../ui/BlockerPrompt.js'
const FormInput = formikFormWrapper(Input)
const FormTextArea = formikFormWrapper(TextArea)

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
`

const DemoText = styled(Text)`
  font-family: ${(props) => props.fontFamily};
`

const Appearance = (props) => {
  let { user, setUser } = useAuth()
  let { refresh: refreshContext } = useTheme()
  const [inputTheme, setTheme] = useState()
  const [logoUploadOpen, setLogoUploadOpen] = useState(false)
  const [imageUploadOpen, setImageUploadOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [primaryFontPickerOpen, setPrimaryFontPickerOpen] = useState(false)
  const [secondaryFontPickerOpen, setSecondaryFontPickerOpen] = useState(false)

  const refresh = async () => {
    const res = await api.get(`/theme/get?organisationId=${user.organisationId}`)
    setTheme(res.data.payload)
    console.log(res)
    setIsLoading(false)
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        {!isLoading ? (
          <Formik
            initialValues={inputTheme ? inputTheme : defaultTheme}
            validate={(values) => {
              const errors = {}
              // if (!values.currency) {
              //   errors.currency = 'Required'
              // }
              // if (!values.monthly?.price) {
              //   errors.monthly.price = 'Required'
              // }
              // if (!values.currency) {
              //   errors.currency = 'Required'
              // }
              return errors
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true)
              try {
                const res = await api.post('/theme/update', values)
                await refreshContext()
                setTimeout(() => resetForm({ values: values }), 500)
                console.log(res)
              } catch (e) {
                console.log(e)
              }

              setSubmitting(false)
            }}
          >
            {({ values, dirty, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
              usePrompt("Are you sure you want to leave this page? You'll lose your changes", dirty)
              console.log(values)

              const isDisabled = !dirty
              return (
                <Flex>
                  <Flex
                    px="32px"
                    pl="16px"
                    pt="40px"
                    flexDirection="column"
                    borderRight="1px solid rgba(166, 183, 212, 1)"
                    width="60%"
                    alignItems="center"
                  >
                    <Flex flexDirection="column" maxWidth="640px">
                      <Flex position="relative" flexDirection="column" width="100%">
                        <Flex>
                          <H1 mb="24px" width="100%">
                            Appearance
                          </H1>
                        </Flex>
                      </Flex>
                      <Container p="24px" justifyContent="space-between" mb="32px">
                        <H3>Logo</H3>
                        <Flex>
                          <Flex flexDirection="column" justifyContent="center">
                            <Text light fontWeight="500">
                              Select an image for your logo
                            </Text>
                            <Text fontSize="12px" light>
                              Use a PNG image with a clear background.
                            </Text>
                          </Flex>
                          <ImageUploadModal
                            accept="image/png"
                            isOpen={logoUploadOpen}
                            setIsOpen={setLogoUploadOpen}
                            saveFunction={(url) => {
                              setFieldValue('logo', url[0])
                              setLogoUploadOpen(false)
                            }}
                          />
                          {values.logo ? (
                            <Box
                              width="200px"
                              minWidth="200px"
                              height="100px"
                              onClick={() => setLogoUploadOpen(true)}
                              cursor="pointer"
                              border="1px solid #A6B7D4"
                              bg="#FBFBFB"
                              borderRadius="8px"
                              padding="4px"
                            >
                              <Image src={values.logo + '?width=400&height=400'} width="100%" height="100%" objectFit="contain" />
                            </Box>
                          ) : (
                            <Flex
                              borderRadius="16px"
                              width="200px"
                              minWidth="200px"
                              height="100px"
                              cursor="pointer"
                              justifyContent="center"
                              alignItems="center"
                              border="1px dashed #A6B7D4"
                              onClick={() => setLogoUploadOpen(true)}
                            >
                              <Icon icon="plus-dark-grey" width="20px" height="20px" />
                            </Flex>
                          )}
                        </Flex>
                        {values.logo ? (
                          <Box mt="24px">
                            <Button onClick={() => setLogoUploadOpen(true)} variant="secondary" label="Change" />
                          </Box>
                        ) : null}
                      </Container>
                      <Container p="24px" justifyContent="space-between" mb="32px">
                        <H3 mb="8px">Tell them why</H3>
                        <Text light mb="16px">
                          A homepage section to explain what you’re offering and why customers should join.
                        </Text>
                        <Field mb="24px" large name="homepageTitle" label="Title" component={FormInput} dark />
                        <Label>Body</Label>
                        <Field mb="24px" large name="homepageBody" component={FormTextArea} dark rows="4" />
                        <Field mb="24px" large name="homepageCta" label="Call to Action" component={FormInput} dark />
                        <ImageUploadModal
                          isOpen={imageUploadOpen}
                          setIsOpen={setImageUploadOpen}
                          saveFunction={(url) => {
                            setFieldValue('homepageImageUrl', url[0])
                            setImageUploadOpen(false)
                          }}
                        />
                        <Flex>
                          <Flex justifyContent="center" flexDirection="column">
                            <Text light fontSize="14px" fontWeight="500">
                              Add an image
                            </Text>
                            <Text mb="16px" fontSize="12px" light>
                              Recommended aspect ratio of 9:16 and minimum width of 1920px
                            </Text>
                            {values.homepageImageUrl ? (
                              <Box>
                                <Button variant="secondary" label="Change image" onClick={() => setImageUploadOpen(true)} />
                              </Box>
                            ) : null}
                          </Flex>
                          {values.homepageImageUrl ? (
                            <Box
                              width="140px"
                              minWidth="140px"
                              height="140px"
                              onClick={() => setImageUploadOpen(true)}
                              cursor="pointer"
                              p="8px"
                            >
                              <Image
                                src={values.homepageImageUrl + '?width=400&height=400'}
                                width="140px"
                                height="140px"
                                minWidth="140px"
                                objectFit="cover"
                                borderRadius="16px"
                              />
                            </Box>
                          ) : (
                            <Flex
                              borderRadius="16px"
                              width="140px"
                              minWidth="140px"
                              height="140px"
                              cursor="pointer"
                              justifyContent="center"
                              alignItems="center"
                              border="2px dashed #A6B7D4"
                              onClick={() => setImageUploadOpen(true)}
                            >
                              <Icon icon="plus-light-grey" width="20px" height="20px" />
                            </Flex>
                          )}
                        </Flex>
                      </Container>
                      <Container p="24px" justifyContent="space-between" mb="32px">
                        <H3 mb="24px">Theme</H3>
                        <Flex>
                          <Flex flexDirection="column" mr="24px">
                            <Label>Background</Label>
                            <ColorPickerElement
                              setFieldValue={setFieldValue}
                              name="backgroundColor"
                              value={values.backgroundColor}
                            />
                          </Flex>
                          <Flex flexDirection="column" ml="24px">
                            <Label>Accent</Label>
                            <ColorPickerElement setFieldValue={setFieldValue} name="accentColor" value={values.accentColor} />
                          </Flex>
                        </Flex>
                      </Container>
                      {/* BUTTONS */}

                      <Container p="24px" justifyContent="space-between" mb="32px">
                        <H3 mb="8px">Buttons</H3>
                        <Text light mb="16px">
                          Button Shapes and styles
                        </Text>
                        <Text fontWeight="600" fontSize="16px" mb="16px" light>
                          Primary
                        </Text>
                        <Grid mb="24px">
                          {buttonOptions.map(({ buttonRound, buttonFill }) => {
                            const isSelected =
                              buttonRound === values.primaryButtonRound && buttonFill === values.primaryButtonFill
                            return (
                              <Flex
                                key={buttonRound + buttonFill}
                                border={isSelected ? '3px solid #5BB3F9' : 'none'}
                                onClick={() => {
                                  setFieldValue('primaryButtonRound', buttonRound)
                                  setFieldValue('primaryButtonFill', buttonFill)
                                }}
                                alignItems="center"
                                justifyContent="center"
                                height="68px"
                                borderRadius="8px"
                                boxShadow="0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)"
                                cursor="pointer"
                                px="24px"
                              >
                                <Box
                                  width="100%"
                                  height="36px"
                                  backgroundColor={buttonFill === BUTTON_FILL.FILL ? 'rgba(113, 128, 150, 1)' : 'transparent'}
                                  border={buttonFill === BUTTON_FILL.OUTLINE ? '1px solid rgba(113, 128, 150, 1)' : 'none'}
                                  borderRadius={
                                    buttonRound === BUTTON_ROUNDING.PILL
                                      ? '36px'
                                      : buttonRound === BUTTON_ROUNDING.ROUNDED
                                      ? '8px'
                                      : 'transparent'
                                  }
                                />
                              </Flex>
                            )
                          })}
                        </Grid>
                        <Flex width="260px" mb="24px">
                          <ColorPickerElement
                            label="Button color"
                            setFieldValue={setFieldValue}
                            name="primaryButtonColor"
                            value={values.primaryButtonColor}
                          />
                        </Flex>
                        <Flex width="260px" mb="40px">
                          <ColorPickerElement
                            label="Font color"
                            setFieldValue={setFieldValue}
                            name="primaryButtonFontColor"
                            value={values.primaryButtonFontColor}
                          />
                        </Flex>
                        <Text fontWeight="600" fontSize="16px" mb="16px" light>
                          Secondary
                        </Text>
                        <Grid mb="24px">
                          {buttonOptions.map(({ buttonRound, buttonFill }) => {
                            const isSelected =
                              buttonRound === values.secondaryButtonRound && buttonFill === values.secondaryButtonFill
                            return (
                              <Flex
                                key={buttonRound + buttonFill}
                                border={isSelected ? '3px solid #5BB3F9' : 'none'}
                                onClick={() => {
                                  setFieldValue('secondaryButtonRound', buttonRound)
                                  setFieldValue('secondaryButtonFill', buttonFill)
                                }}
                                alignItems="center"
                                justifyContent="center"
                                height="68px"
                                borderRadius="8px"
                                boxShadow="0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)"
                                cursor="pointer"
                                px="24px"
                              >
                                <Box
                                  width="100%"
                                  height="36px"
                                  backgroundColor={buttonFill === BUTTON_FILL.FILL ? 'rgba(113, 128, 150, 1)' : 'transparent'}
                                  border={buttonFill === BUTTON_FILL.OUTLINE ? '1px solid rgba(113, 128, 150, 1)' : 'none'}
                                  borderRadius={
                                    buttonRound === BUTTON_ROUNDING.PILL
                                      ? '36px'
                                      : buttonRound === BUTTON_ROUNDING.ROUNDED
                                      ? '8px'
                                      : 'transparent'
                                  }
                                />
                              </Flex>
                            )
                          })}
                        </Grid>
                        <Flex width="260px" mb="24px">
                          <ColorPickerElement
                            label="Button color"
                            setFieldValue={setFieldValue}
                            name="secondaryButtonColor"
                            value={values.secondaryButtonColor}
                          />
                        </Flex>
                        <Flex width="260px" mb="24px">
                          <ColorPickerElement
                            label="Font color"
                            setFieldValue={setFieldValue}
                            name="secondaryButtonFontColor"
                            value={values.secondaryButtonFontColor}
                          />
                        </Flex>
                      </Container>
                      {/* CARDS */}

                      <Container p="24px" justifyContent="space-between" mb="32px">
                        <H3 mb="8px">Cards</H3>
                        <Text light mb="16px">
                          Content card style for explore and discover
                        </Text>
                        <Grid mb="24px">
                          {Object.keys(CARD_OPTIONS).map((option) => {
                            const isSelected = option === values.cardStyle
                            return (
                              <Flex
                                key={option}
                                border={isSelected ? '3px solid #5BB3F9' : 'none'}
                                onClick={() => {
                                  setFieldValue('cardStyle', option)
                                }}
                                alignItems="center"
                                justifyContent="center"
                                height="144px"
                                borderRadius="8px"
                                boxShadow="0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)"
                                cursor="pointer"
                              >
                                <Box
                                  width="82px"
                                  height="112px"
                                  backgroundColor={'rgba(113, 128, 150, 1)'}
                                  borderRadius={
                                    option === CARD_OPTIONS.EXTRA_ROUNDED
                                      ? '24px'
                                      : option === CARD_OPTIONS.ROUNDED
                                      ? '8px'
                                      : 'transparent'
                                  }
                                />
                              </Flex>
                            )
                          })}
                        </Grid>
                      </Container>
                      <Container p="24px" justifyContent="space-between" mb="32px">
                        <H3 mb="16px">Fonts</H3>
                        <Text fontWeight="600" fontSize="16px" mb="16px" light>
                          Titles
                        </Text>
                        <Flex alignItems="center" justifyContent="space-between" mb="24px">
                          <Flex alignItems="center">
                            <Flex
                              onClick={() => setPrimaryFontPickerOpen(true)}
                              cursor="pointer"
                              width="80px"
                              height="80px"
                              minWidth="80px"
                              backgroundColor="#EBF2FA"
                              borderRadius="16px"
                              alignItems="center"
                              justifyContent="center"
                              mr="20px"
                            >
                              <DemoText
                                color="rgba(74, 85, 104, 1)"
                                fontSize="24px"
                                fontWeight="500"
                                fontFamily={values.primaryFontFamily}
                              >
                                Aa
                              </DemoText>
                            </Flex>
                            <Text fontWeight="500" fontSize="16px" mr="20px">
                              {values.primaryFontFamily}
                            </Text>
                            <FontPicker
                              selectedFont={values.primaryFontFamily}
                              setIsOpen={setPrimaryFontPickerOpen}
                              isOpen={primaryFontPickerOpen}
                              saveFunction={(font) => {
                                setFieldValue('primaryFontFamily', font)
                              }}
                            />
                          </Flex>
                          <Box width="300px" minWidth="300px">
                            <ColorPickerElement
                              setFieldValue={setFieldValue}
                              name="primaryFontColor"
                              value={values.primaryFontColor}
                            />
                          </Box>
                        </Flex>
                        <Text fontWeight="600" fontSize="16px" mb="16px" light>
                          Body
                        </Text>
                        <Flex alignItems="center" justifyContent="space-between">
                          <Flex alignItems="center">
                            <Flex
                              onClick={() => setSecondaryFontPickerOpen(true)}
                              cursor="pointer"
                              width="80px"
                              height="80px"
                              minWidth="80px"
                              backgroundColor="#EBF2FA"
                              borderRadius="16px"
                              alignItems="center"
                              justifyContent="center"
                              mr="20px"
                            >
                              <DemoText
                                color="rgba(74, 85, 104, 1)"
                                fontSize="24px"
                                fontWeight="500"
                                fontFamily={values.secondaryFontFamily}
                              >
                                Aa
                              </DemoText>
                            </Flex>
                            <Text fontWeight="500" fontSize="16px" mr="20px">
                              {values.secondaryFontFamily}
                            </Text>
                            <FontPicker
                              selectedFont={values.secondaryFontFamily}
                              setIsOpen={setSecondaryFontPickerOpen}
                              isOpen={secondaryFontPickerOpen}
                              saveFunction={(font) => {
                                setFieldValue('secondaryFontFamily', font)
                              }}
                            />
                          </Flex>
                          <Box width="300px" minWidth="300px">
                            <ColorPickerElement
                              setFieldValue={setFieldValue}
                              name="secondaryFontColor"
                              value={values.secondaryFontColor}
                            />
                          </Box>
                        </Flex>
                      </Container>
                      <Flex justifyContent="flex-end">
                        <Button
                          isLoading={isSubmitting}
                          isDisabled={isDisabled}
                          variant="green"
                          label="Save"
                          onClick={handleSubmit}
                          mb="32px"
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex px="16px" flexDirection="column" pt="16px" alignItems="center" width="40%">
                    <Flex flexDirection="column" position="sticky" top="16px" alignItems="center">
                      <Flex justifyContent="flex-end">
                        <Button
                          mr="16px"
                          isDisabled={isDisabled}
                          isLoading={isSubmitting}
                          variant="green"
                          label="Save"
                          onClick={handleSubmit}
                          mb="32px"
                        />
                        <a href={`https://${user.subdomain}.theclubb.co/`} target="_blank" rel="noreferrer">
                          <Button
                            renderLeftIcon={() => <Icon icon="diagonal-arrow-grey" width="12px" height="12px" />}
                            variant="secondary"
                            label="Open"
                            mb="32px"
                          />
                        </a>
                      </Flex>
                      <Preview values={values} />
                    </Flex>
                  </Flex>
                </Flex>
              )
            }}
          </Formik>
        ) : (
          <Flex height="500px" justifyContent="center" alignItems="center">
            <BigDarkSpinner />
          </Flex>
        )}
      </Flex>
    </>
  )
}

export default Appearance
