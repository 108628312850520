import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { colors, numberWithCommas } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { Formik, Field } from 'formik'
import SelectComponent from '../ui/Select.js'
import { Input, TextArea } from '../ui/Input.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Button from '../ui/Button.js'
import ImageUploadModal from '../ui/ImageUploadModal.js'
import Image from '../ui/Image.js'
import Icon from '../ui/Icon.js'
import { useTheme } from '../ThemeProvider.js'
import Select from '../ui/Select.js'
import SlideOverContainer from '../ui/SlideOverContainer.js'
const FormInput = formikFormWrapper(Input)
const FormTextArea = formikFormWrapper(TextArea)

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
`

const DemoText = styled(Text)`
  font-family: ${(props) => props.fontFamily};
`

const Referrals = (props) => {
  let { user, setUser } = useAuth()
  let { refresh: refreshContext, theme } = useTheme()
  const [inputTiers, setTiers] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [editTier, setEditTier] = useState(null)
  const [data, setData] = useState()
  const [savedValues, setSavedValues] = useState({})

  console.log(data)

  useEffect(() => {
    const getData = async () => {
      const res = await api.get(`/referrals/get-data`)
      setData(res.data.payload)
    }
    getData()
  }, [])

  const refresh = async () => {
    const res = await api.get(`/referrals/get-tiers`)
    setTiers(res.data.payload)
    console.log(res)
    setIsLoading(false)
  }

  useEffect(() => {
    refresh()
  }, [])

  const enableReferrals = async () => {
    const res = await api.post('/referrals/enable', { enable: !theme.referralsEnabled })
    refreshContext()
    console.log(res)
  }

  useEffect(() => {
    if (inputTiers) {
      const initialValues = inputTiers.reduce((acc, tier) => {
        acc[`${tier.tier}numberReferrals`] = tier.numberReferrals
        acc[`${tier.tier}rewardType`] = tier.rewardType
        acc[`${tier.tier}rewardDescription`] = tier.rewardDescription
        acc[`${tier.tier}freeMonths`] = tier.freeMonths
        acc[`${tier.tier}emailBody`] = tier.emailBody
        return acc
      }, {})
      setSavedValues(initialValues)
    }
  }, [inputTiers])

  console.log(savedValues)

  return (
    <>
      {!isLoading ? (
        <Formik
          initialValues={
            inputTiers
              ? inputTiers.reduce((acc, tier) => {
                  acc[`${tier.tier}numberReferrals`] = tier.numberReferrals
                  acc[`${tier.tier}rewardType`] = tier.rewardType
                  acc[`${tier.tier}rewardDescription`] = tier.rewardDescription
                  acc[`${tier.tier}freeMonths`] = tier.freeMonths
                  acc[`${tier.tier}emailBody`] = tier.emailBody
                  return acc
                }, {})
              : {}
          }
          validate={(values) => {
            const errors = {}
            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)
            const tiers = [
              {
                tier: 1,
                numberReferrals: values[`${1}numberReferrals`],
                rewardType: values[`${1}rewardType`] || 'AUTOMATIC',
                rewardDescription: values[`${1}rewardDescription`],
                freeMonths: values[`${1}freeMonths`],
                emailBody: values[`${1}emailBody`]
              },
              {
                tier: 2,
                numberReferrals: values[`${2}numberReferrals`],
                rewardType: values[`${2}rewardType`] || 'AUTOMATIC',
                rewardDescription: values[`${2}rewardDescription`],
                freeMonths: values[`${2}freeMonths`],
                emailBody: values[`${2}emailBody`]
              },
              {
                tier: 3,
                numberReferrals: values[`${3}numberReferrals`],
                rewardType: values[`${3}rewardType`] || 'AUTOMATIC',
                rewardDescription: values[`${3}rewardDescription`],
                freeMonths: values[`${3}freeMonths`],
                emailBody: values[`${3}emailBody`]
              }
            ].filter((tier) => tier.numberReferrals && tier.rewardType)

            try {
              const res = await api.post('/referrals/update-tiers', tiers)
              setSavedValues(values)
              console.log(res)
            } catch (e) {
              console.log(e)
            }

            setEditTier(null)
            setSubmitting(false)
          }}
        >
          {({
            handleSubmit,
            values,
            dirty,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setSubmitting,
            setFieldValue,
            resetForm
          }) => {
            const removeTier = async (tier) => {
              setSubmitting(true)
              try {
                resetForm({
                  values: {
                    ...values,
                    [`${tier}numberReferrals`]: null,
                    [`${tier}rewardType`]: null,
                    [`${tier}freeMonths`]: null,
                    [`${tier}rewardDescription`]: null,
                    [`${tier}emailBody`]: null
                  }
                })
                handleSubmit()
              } catch (e) {
                console.log(e)
              }
              setSubmitting(false)
            }

            const getTierDescription = (tier) => {
              if (!savedValues[`${tier}numberReferrals`] || !savedValues[`${tier}freeMonths`]) {
                return 'Not activated'
              }
              return `${savedValues[`${tier}numberReferrals`]} referrals, ${
                savedValues[`${tier}rewardType`] === 'CUSTOM'
                  ? savedValues[`${tier}rewardDescription`]
                  : `${savedValues[`${tier}freeMonths`]} month${savedValues[`${tier}freeMonths`] > 1 ? 's' : ''} free`
              }`
            }
            return (
              <Flex flexDirection="column" p="40px">
                <Flex flexDirection="column" maxWidth="640px">
                  <Flex position="relative" flexDirection="column" width="100%">
                    <Flex>
                      <H1 mb="24px" width="100%">
                        Referrals
                      </H1>
                    </Flex>
                  </Flex>
                  <Flex mb="32px">
                    <Container p="24px" py="8px" mr="24px">
                      <Text fontWeight="600" light mb="8px" lineHeight="24px">
                        Referrers
                      </Text>
                      <Text fontSize="24px" fontWeight="500" mb="8px" lineHeight="normal">
                        {typeof data?.referrers !== 'undefined' ? numberWithCommas(data?.referrers) : '-'}
                      </Text>
                      <Text light fontWeight="500" lineHeight="24px">
                        {typeof data?.referrers !== 'undefined'
                          ? `${data.referrers - data.referrers30DaysAgo > 0 ? '+' : ''}${numberWithCommas(
                              data.referrers - data.referrers30DaysAgo
                            )} vs previous 30 days`
                          : null}
                      </Text>
                    </Container>
                    <Container p="24px" py="8px">
                      <Text fontWeight="600" light mb="8px" lineHeight="24px">
                        Paid Referrals
                      </Text>
                      <Text fontSize="24px" fontWeight="500" mb="8px" lineHeight="normal">
                        {typeof data?.paidRefferals !== 'undefined' ? numberWithCommas(data?.paidRefferals) : '-'}
                      </Text>
                      <Text light fontWeight="500" lineHeight="24px">
                        {typeof data?.paidRefferals !== 'undefined'
                          ? `${data.paidRefferals - data.paidRefferals30DaysAgo > 0 ? '+' : ''}${numberWithCommas(
                              data.paidRefferals - data.paidRefferals30DaysAgo
                            )} vs previous 30 days`
                          : null}
                      </Text>
                    </Container>
                  </Flex>
                  <Flex mb="32px">
                    <Container p="24px" py="8px" mr="24px">
                      <Text fontWeight="600" light mb="8px" lineHeight="24px">
                        Free Referrals
                      </Text>
                      <Text fontSize="24px" fontWeight="500" mb="8px" lineHeight="normal">
                        {typeof data?.freeRefferals !== 'undefined' ? numberWithCommas(data?.freeRefferals) : '-'}
                      </Text>
                      <Text light fontWeight="500" lineHeight="24px">
                        {typeof data?.freeRefferals !== 'undefined'
                          ? `${data.freeRefferals - data.freeRefferals30DaysAgo > 0 ? '+' : ''}${numberWithCommas(
                              data.freeRefferals - data.freeRefferals30DaysAgo
                            )} vs previous 30 days`
                          : null}
                      </Text>
                    </Container>
                    <Container p="24px" py="8px">
                      <Text fontWeight="600" light mb="8px" lineHeight="24px">
                        Paid Referrals per subscriber
                      </Text>
                      <Text fontSize="24px" fontWeight="500" mb="8px" lineHeight="normal">
                        {typeof data?.paidReferralsPerSubscriber !== 'undefined'
                          ? Math.round(data?.paidReferralsPerSubscriber * 100) / 100
                          : '-'}
                      </Text>
                      <Text light fontWeight="500" lineHeight="24px"></Text>
                    </Container>
                  </Flex>
                  <Container p="24px" justifyContent="space-between" mb="32px">
                    <Flex>
                      <Flex flexDirection="column">
                        <H3 mb="8px">Subscriber Referrals</H3>
                        <Text light>
                          A great way to grow your audience. Reward your subscribers for spreading the word about your Clubb.
                        </Text>
                      </Flex>
                      <Flex ml="32px" minWidth="160px" width="auto" alignItems="center" justifyContent="flex-end">
                        <Button
                          // isDisabled={!values[`${1}numberReferrals`] || !values[`${1}rewardType`]}
                          isDisabled={!savedValues[`${1}numberReferrals`]}
                          onClick={enableReferrals}
                          variant={theme.referralsEnabled ? 'secondary' : 'green'}
                          label={theme.referralsEnabled ? 'Disable referrals' : 'Enable referrals'}
                        />
                      </Flex>
                    </Flex>
                    <Flex my="32px" borderBottom="1px solid #EDF2F7" />
                    <Flex alignItems="center">
                      <Flex flexDirection="column" justifyContent="center">
                        <Text fontWeight="600" lineHeight="24px">
                          Tier One
                        </Text>
                        {getTierDescription(1) ? (
                          <Text light lineHeight="24px">
                            {getTierDescription(1)}
                          </Text>
                        ) : null}
                      </Flex>
                      <Flex width="fit-content">
                        <Button
                          minWidth="0px"
                          variant="white"
                          shadow
                          label={savedValues[`${1}numberReferrals`] ? 'Edit' : 'Setup'}
                          onClick={() => setEditTier(1)}
                        />
                      </Flex>
                    </Flex>
                    <Flex my="32px" borderBottom="1px solid #EDF2F7" />
                    <Flex alignItems="center">
                      <Flex flexDirection="column" justifyContent="center">
                        <Text fontWeight="600" lineHeight="24px">
                          Tier Two
                        </Text>
                        {getTierDescription(2) ? (
                          <Text light lineHeight="24px">
                            {getTierDescription(2)}
                          </Text>
                        ) : null}
                      </Flex>
                      <Flex width="fit-content">
                        <Button
                          // isDisabled={!values[`${2}numberReferrals`] || !values[`${2}rewardType`]}
                          minWidth="0px"
                          isDisabled={!savedValues[`${1}numberReferrals`]}
                          variant="white"
                          shadow
                          label={savedValues[`${2}numberReferrals`] ? 'Edit' : 'Setup'}
                          onClick={() => setEditTier(2)}
                        />
                      </Flex>
                    </Flex>
                    <Flex my="32px" borderBottom="1px solid #EDF2F7" />
                    <Flex alignItems="center">
                      <Flex flexDirection="column" justifyContent="center">
                        <Text fontWeight="600" lineHeight="24px">
                          Tier Three
                        </Text>
                        {getTierDescription(3) ? (
                          <Text light lineHeight="24px">
                            {getTierDescription(3)}
                          </Text>
                        ) : null}
                      </Flex>
                      <Flex width="fit-content">
                        <Button
                          // isDisabled={!values[`${2}numberReferrals`] || !values[`${2}rewardType`]}
                          minWidth="0px"
                          isDisabled={!savedValues[`${2}numberReferrals`]}
                          variant="white"
                          shadow
                          label={savedValues[`${3}numberReferrals`] ? 'Edit' : 'Setup'}
                          onClick={() => setEditTier(3)}
                        />
                      </Flex>
                    </Flex>
                    <Flex mt="32px" mb="24px" borderBottom="1px solid #EDF2F7" />
                    {/* {!values[`${1}numberReferrals`] || !values[`${1}rewardType`] ? ( */}
                    {/* {!values[`${1}numberReferrals`] ? (
                      <Text textAlign="right" mb="8px" fontWeight="500" lineHeight="24px" color={colors.red}>
                        Create at least one tier of reward to enable referrals
                      </Text>
                    ) : null} */}
                    {/* {values[`${1}numberReferrals`] && values[`${1}rewardType`] ? ( */}
                  </Container>
                </Flex>
                <SlideOverContainer width="640px" isOpen={editTier} setIsOpen={setEditTier} padding={'0px'} noAutoClose>
                  <Flex flexDirection="column" overflowY="scroll" height="calc(100% - 84px)" p="24px">
                    <Flex flexDirection="column" pb="24px">
                      <H3 mb="8px">Tier {{ 1: 'One', 2: 'Two', 3: 'Three' }[editTier]}</H3>
                      <Text light>
                        Set the milestones and rewards for your Tier {{ 1: 'One', 2: 'Two', 3: 'Three' }[editTier]} reward
                      </Text>
                      <Flex my="32px" borderBottom="1px solid #EDF2F7" />
                      <Field
                        mb="24px"
                        large
                        type="number"
                        min="0"
                        max="1000000"
                        name={`${editTier}numberReferrals`}
                        label="Number of referrals needed"
                        component={FormInput}
                        dark
                      />
                      {/* <Flex flexDirection="column" mb="32px">
                        <Label>Reward type</Label>
                        <Select
                          mb="16px"
                          fullWidth
                          value={values[`${editTier}rewardType`]}
                          onChange={async (option) => {
                            setFieldValue(`${editTier}rewardType`, option.value)
                          }}
                          options={[
                            { value: 'AUTOMATIC', label: 'Complementary paid subscription months' },
                            { value: 'CUSTOM', label: 'Custom' }
                          ]}
                          placeholder="Select option"
                        />
                      </Flex> */}
                      {values[`${editTier}rewardType`] === 'CUSTOM' ? (
                        <Flex flexDirection="column">
                          <Field label="Reward" large name={`${editTier}rewardDescription`} component={FormInput} dark />
                          <Text light fontSize="11px">
                            How the reward will be communicated to subscribers eg. “A one hour Facetime cooking session with me”
                          </Text>
                        </Flex>
                      ) : (
                        <Field
                          large
                          type="number"
                          min="0"
                          max="1000000"
                          name={`${editTier}freeMonths`}
                          label="Number of complimentary months gifted"
                          component={FormInput}
                          dark
                        />
                      )}
                    </Flex>
                    <Flex my="32px" borderBottom="1px solid #EDF2F7" />
                    <Flex flexDirection="column">
                      <Label>Email body</Label>
                      <Text light mb="8px" fontSize="11px" lineHeight="12px">
                        Optional. This will be included in the email sent to subscribers when they successfully reach this reward
                        tier.
                      </Text>
                      <Field mb="24px" large name={`${editTier}emailBody`} component={FormTextArea} dark rows="8" />
                    </Flex>
                  </Flex>
                  <Flex
                    borderTop="1px solid #EDF2F7"
                    p="24px"
                    mt="auto"
                    bg="white"
                    height="84px"
                    boxShadow="0px -1px 4px 0px rgba(164, 164, 164, 0.25);"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {/* Only show remove option for highest active tier */}
                    {(() => {
                      const highestTier = [3, 2, 1].find((t) => savedValues[`${t}numberReferrals`])
                      return editTier === highestTier ? (
                        <Text fontWeight="500" cursor="pointer" onClick={() => removeTier(editTier)} light mr="16px">
                          Remove tier
                        </Text>
                      ) : null
                    })()}
                    <Button mr="16px" variant="secondary" label="Cancel" onClick={() => setEditTier(null)} />
                    <Button
                      isDisabled={
                        // !values[`${editTier}numberReferrals`] ||
                        // !values[`${editTier}rewardType`] ||
                        // (!values[`${editTier}rewardDescription`] && values[`${editTier}rewardType`] === 'CUSTOM') ||
                        // (!values[`${editTier}freeMonths`]
                        //   && values[`${editTier}rewardType`] === 'AUTOMATIC'
                        // )
                        !values[`${editTier}numberReferrals`] || !values[`${editTier}freeMonths`]
                      }
                      variant="green"
                      label="Save"
                      onClick={handleSubmit}
                      loading={isSubmitting}
                    />
                  </Flex>
                </SlideOverContainer>
              </Flex>
            )
          }}
        </Formik>
      ) : (
        <Flex height="500px" justifyContent="center" alignItems="center">
          <BigDarkSpinner />
        </Flex>
      )}
    </>
  )
}

export default Referrals
