/* eslint react/display-name:off */
import React from 'react'
import omit from 'lodash/omit'

const withFormik = (Component) => {
  return (props) => {
    const {
      onChange,
      onBlur,
      onFocus,
      isSubmitting,
      field,
      noError,

      form: { touched, errors }
    } = props
    const propsToPass = omit(props, ['input', 'meta', 'onChange', 'onBlur', 'onFocus'])
    const errorToPass = touched[field.name] && errors[field.name] && !noError ? errors[field.name] : undefined
    return (
      <Component
        error={errorToPass}
        isLoading={isSubmitting}
        name={field.name}
        value={field.value}
        onChange={(e) => {
          onChange && onChange(e)
          field.onChange(e)
        }}
        onBlur={(e) => {
          onBlur && onBlur(e)
          field.onBlur(e)
        }}
        onFocus={(e) => {
          onFocus && onFocus(e)
        }}
        {...propsToPass}
      />
    )
  }
}

export default withFormik
