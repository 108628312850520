import './App.css'
import './Fonts.css'
import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { BrowserRouter as Router, Routes, Route, Redirect, Link, Navigate, useNavigate, useLocation } from 'react-router-dom'
import Home from './Home/index.js'
import Login from './Auth/Login.js'
import Subdomain from './Auth/Subdomain.js'
import Signup from './Auth/Signup.js'
import SendPasswordReset from './Auth/SendPasswordReset.js'
import PasswordReset from './Auth/PasswordReset.js'
import Account from './Account/index.js'
import Content from './Content/index.js'
import BillingPage from './Billing/index.js'
import Plans from './Plans/index.js'
import { RequireAuth, AuthProvider } from './Auth.js'
import SideNav, { TABS } from './Components/SideNav.js'
import { Flex, MobileContainer } from './ui/Layout.js'
import { H1, H2, Text, Span, GradientFont, Error } from './ui/Typography.js'
import { colors, fontFamily } from './ui/helpers'
import { ToastContainer } from 'react-toastify'
import { ModalProvider } from './Modal'

import PageTrackingComponent from './PageTracking'
import 'react-toastify/dist/ReactToastify.css'
import Payments from './Payments/index'
import Appearance from './Appearance/index'
import Recipe from './Recipe/index'
import { ContentProvider } from './ContentProvider'
import Customers from './Customers/index'
import Settings from './Settings/index'
import { ThemeProvider } from './ThemeProvider'
import MealPlan from './MealPlan/index'
import Inbox from './Inbox/index'
import { InboxProvider } from './InboxProvider'
import PostsEditor from './Posts/Editor'
import PostsSchedule from './Posts/Schedule'
import PostsSettings from './Posts/Settings'
import PostsReview from './Posts/Review'
import PostsIndex from './Posts/index'
import Referrals from './Referrals/index'

const StyledToastContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
    z-index: 99999999999999;
  }
  .Toastify__toast {
    height: 40px !important;
    min-height: 40px !important;
    background-color: black;
    border-radius: 8px;
    color: white;
    text-align: center;
    font-family: ${fontFamily};
    font-size: 14px;
    font-weight: 700;
    padding-top: 4px;
    z-index: 99999999999999;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`

function App() {
  ReactTooltip.rebuild()
  return (
    <>
      <Router>
        <AuthProvider>
          <ContentProvider>
            <InboxProvider>
              <ThemeProvider>
                <ModalProvider>
                  <PageTrackingComponent />
                  <Flex>
                    <StyledToastContainer
                      style={{ width: 'fit-content' }}
                      position="bottom-center"
                      autoClose={2000}
                      hideProgressBar
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss={false}
                      draggable
                      pauseOnHover
                      limit={1}
                      closeButton={false}
                    />
                    <SideNav />
                    <Routes>
                      <Route
                        exact
                        path="/"
                        element={
                          <RequireAuth>
                            <Home />
                          </RequireAuth>
                        }
                      />
                      <Route exact path="/signup" element={<Signup />} />
                      <Route exact path="/login" element={<Login />} />
                      <Route exact path="/password-reset" element={<PasswordReset />} />
                      <Route exact path="/send-password-reset" element={<SendPasswordReset />} />
                      <Route
                        exact
                        path="/clubb-name"
                        element={
                          <RequireAuth>
                            <Subdomain />
                          </RequireAuth>
                        }
                      />
                      <Route
                        exact
                        path="/plans"
                        element={
                          <RequireAuth>
                            <Plans />
                          </RequireAuth>
                        }
                      />

                      <Route
                        exact
                        path="/account"
                        element={
                          <RequireAuth>
                            <Account />
                          </RequireAuth>
                        }
                      />
                      <Route
                        exact
                        path="/customers"
                        element={
                          <RequireAuth>
                            <Customers />
                          </RequireAuth>
                        }
                      />

                      <Route
                        exact
                        path="/settings"
                        element={
                          <RequireAuth>
                            <Settings />
                          </RequireAuth>
                        }
                      />

                      <Route
                        exact
                        path="/inbox"
                        element={
                          <RequireAuth>
                            <Inbox />
                          </RequireAuth>
                        }
                      />

                      <Route
                        exact
                        path="/billing"
                        element={
                          <RequireAuth>
                            <BillingPage />
                          </RequireAuth>
                        }
                      />

                      <Route
                        exact
                        path="/appearance"
                        element={
                          <RequireAuth>
                            <Appearance />
                          </RequireAuth>
                        }
                      />

                      <Route
                        exact
                        path="/content"
                        element={
                          <RequireAuth>
                            <Content />
                          </RequireAuth>
                        }
                      />
                      <Route
                        exact
                        path="/meal-plan"
                        element={
                          <RequireAuth>
                            <MealPlan />
                          </RequireAuth>
                        }
                      />
                      <Route
                        exact
                        path="/meal-plan/:mealPlanId"
                        element={
                          <RequireAuth>
                            <MealPlan />
                          </RequireAuth>
                        }
                      />
                      <Route
                        exact
                        path="/recipe/:recipeId"
                        element={
                          <RequireAuth>
                            <Recipe />
                          </RequireAuth>
                        }
                      />
                      <Route
                        exact
                        path="/recipe"
                        element={
                          <RequireAuth>
                            <Recipe />
                          </RequireAuth>
                        }
                      />

                      <Route
                        exact
                        path="/post/editor"
                        element={
                          <RequireAuth>
                            <PostsEditor />
                          </RequireAuth>
                        }
                      />

                      <Route
                        exact
                        path="/post/editor/:postId"
                        element={
                          <RequireAuth>
                            <PostsEditor />
                          </RequireAuth>
                        }
                      />
                      <Route
                        exact
                        path="/post/schedule/:postId"
                        element={
                          <RequireAuth>
                            <PostsSchedule />
                          </RequireAuth>
                        }
                      />
                      <Route
                        exact
                        path="/post/settings/:postId"
                        element={
                          <RequireAuth>
                            <PostsSettings />
                          </RequireAuth>
                        }
                      />
                      <Route
                        exact
                        path="/post/review/:postId"
                        element={
                          <RequireAuth>
                            <PostsReview />
                          </RequireAuth>
                        }
                      />
                      <Route
                        exact
                        path="/post/:postId"
                        element={
                          <RequireAuth>
                            <PostsIndex />
                          </RequireAuth>
                        }
                      />
                      <Route
                        exact
                        path="/referrals"
                        element={
                          <RequireAuth>
                            <Referrals />
                          </RequireAuth>
                        }
                      />
                    </Routes>
                  </Flex>
                </ModalProvider>
              </ThemeProvider>
            </InboxProvider>
          </ContentProvider>
        </AuthProvider>
      </Router>
      <ReactTooltip effect="solid" multiline="true" />
    </>
  )
}

export default App
