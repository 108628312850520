import React, { useState, useEffect, useRef } from 'react'
import hexToRgba from 'hex-to-rgba'
import WriteComment from './WriteComment'
import api from '../../api'
import Comment from './Comment'
import styled from 'styled-components'
import { useTheme } from '../../ThemeProvider'
import { Box, Flex } from '../../ui/Layout'
import { useInbox } from '../../InboxProvider'
import { useAuth } from '../../Auth'

const formatComments = (comments) => {
  const commentMap = new Map()
  const rootComments = []

  // First pass: create a map of all comments
  comments.forEach((comment) => {
    commentMap.set(comment.id, { ...comment, children: [] })
  })

  // Second pass: organize comments into a tree structure
  comments.forEach((comment) => {
    if (comment.parentId) {
      const parentComment = commentMap.get(comment.parentId)
      if (parentComment) {
        parentComment.children.push(commentMap.get(comment.id))
      }
    } else {
      rootComments.push(commentMap.get(comment.id))
    }
  })

  return rootComments
}

const CommentWrapper = styled(Box)`
  position: relative;
  padding-left: 40px;
  margin-top: ${(props) => (props.isTopLevel ? '28px' : '0')};
  ${({ noIndent }) => noIndent && 'padding-left: 0;'}

  ${(props) =>
    !props.noIndent
      ? `&::before {
    content: '';
    position: absolute;
    left: 20px;
    top: 44px;
    bottom: 0;
    width: 1px;
    background-color: ${hexToRgba(props.secondaryFontColor, 0.3)};
  }`
      : null}

  @media (max-width: 700px) {
    padding-left: 30px;
    ${({ noIndent }) => noIndent && 'padding-left: 0px;'}
    &::before {
      left: 11px;
      top: 28px;
      width: 1px;
    }
  }
`

export const CommentCircle = styled.div`
  position: absolute;
  left: ${({ noIndent }) => (noIndent ? '-43px' : '-3px')};
  top: -6px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${(props) => props.primaryButtonColor};
  color: ${(props) => props.primaryButtonFontColor};
  font-family: ${(props) => props.primaryFontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  border: 4px solid ${(props) => props.backgroundColor};

  ${(props) =>
    props.isCreator &&
    props.commentsImageUrl &&
    `
    background-image: url(${props.commentsImageUrl + '?width=100&height=100'});
    background-size: cover;
    background-position: center;
    background-color: transparent;
  `}

  @media (max-width: 700px) {
    width: 40px;
    height: 40px;
    font-size: 12px;
    left: ${({ noIndent }) => (noIndent ? '-38px' : '-8px')};
  }
`

const Comments = ({ commentId, contentId, contentType, isMobile: inputIsMobile }) => {
  const { user } = useAuth()

  const { theme: config } = useTheme()

  const { comments: inputComments } = useInbox()
  const filteredComments = inputComments.filter(
    (comment) => comment.contentId === contentId && comment.contentType === contentType
  )
  const [openReplyId, setOpenReplyId] = useState(null)
  const [likes, setLikes] = useState([])
  const formattedComments = formatComments(filteredComments)
  const [isMobile, setIsMobile] = useState(inputIsMobile)

  useEffect(() => {
    setIsMobile(window.innerWidth < 700)
  }, [inputIsMobile])

  const fetchLikes = async () => {
    const response = await api.get(`/comments/get-likes?contentId=${contentId}&contentType=${contentType}`)
    setLikes(response.data.payload || [])
  }

  useEffect(() => {
    fetchLikes()
  }, [contentId, contentType])

  const commentRefs = useRef({})

  useEffect(() => {
    if (commentId && commentRefs.current[commentId]) {
      commentRefs.current[commentId].scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [commentId, formattedComments])

  const RenderComments = ({ comments, isTopLevel = true, depth = 0, parentId }) => {
    const noIndent = isMobile ? depth > 2 : depth > 4
    return comments.map((comment) => (
      <CommentWrapper
        noIndent={noIndent}
        key={comment.id}
        secondaryFontColor={config.secondaryFontColor}
        isTopLevel={isTopLevel}
        ref={(el) => (commentRefs.current[comment.id] = el)}
      >
        <CommentCircle
          noIndent={noIndent}
          isCreator={comment.isCreator}
          commentsImageUrl={config.commentsImageUrl}
          backgroundColor={config.backgroundColor}
          primaryButtonColor={config.primaryButtonColor}
          primaryButtonFontColor={config.primaryButtonFontColor}
          primaryFontFamily={config.primaryFontFamily}
        >
          {config.commentsImageUrl && comment.isCreator
            ? ''
            : comment.firstName
            ? comment.firstName[0].toUpperCase()
            : user.organisationName
            ? user.organisationName[0].toUpperCase()
            : ''}
        </CommentCircle>
        <Comment
          {...comment}
          parentId={parentId}
          fetchLikes={fetchLikes}
          likes={likes}
          config={config}
          contentId={contentId}
          contentType={contentType}
          openReplyId={openReplyId}
          setOpenReplyId={setOpenReplyId}
          comments={comments}
        >
          {comment.children.length > 0 && (
            <RenderComments parentId={comment.id} comments={comment.children} isTopLevel={false} depth={depth + 1} />
          )}
        </Comment>
      </CommentWrapper>
    ))
  }

  return (
    <Flex flexDirection="column">
      <WriteComment comments={formattedComments} contentId={contentId} contentType={contentType} config={config} />
      <RenderComments comments={formattedComments} />
    </Flex>
  )
}

export default Comments
