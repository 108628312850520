import { useState } from 'react'
import Button from '../ui/Button'
import Icon from '../ui/Icon'
import { Input, TextArea } from '../ui/Input'
import { Box, Flex } from '../ui/Layout'
import { Label, Span, Text } from '../ui/Typography'
import { uniq, update } from 'ramda'
import styled from 'styled-components'
import formikFormWrapper from '../formikFormWrapper.js'
import { Field } from 'formik'
const FormInput = formikFormWrapper(Input)

const Nutrition = () => {
  return (
    <>
      <Text mb="8px" color="black" fontSize="18px" fontWeight="600" width="100%">
        Nutrition
      </Text>
      <Text mb="24px" light fontSize="14px" fontWeight="400" width="100%">
        Add nutrition per serving
      </Text>
      <Flex mb="32px" maxWidth="100%">
        <Field
          large
          width="100%"
          textPostFix="kcal"
          type="number"
          min={0}
          name="nutrition.calories"
          label="Calories"
          component={FormInput}
          dark
        />

        <Field
          mr="40px"
          ml="40px"
          large
          width="100%"
          textPostFix="g"
          type="number"
          min={0}
          name="nutrition.fat"
          label="Fat"
          component={FormInput}
          dark
        />

        <Field
          large
          width="100%"
          textPostFix="g"
          type="number"
          min={0}
          name="nutrition.saturatedFat"
          label="Saturated Fat"
          component={FormInput}
          dark
        />
      </Flex>
      <Flex mb="32px" maxWidth="100%">
        <Field
          large
          width="100%"
          textPostFix="g"
          type="number"
          min={0}
          name="nutrition.carbohydrates"
          label="Carbohydrates"
          component={FormInput}
          dark
        />

        <Field
          mr="40px"
          ml="40px"
          large
          width="100%"
          textPostFix="g"
          type="number"
          min={0}
          name="nutrition.sugars"
          label="Sugars"
          component={FormInput}
          dark
        />
        <Field
          large
          width="100%"
          textPostFix="g"
          type="number"
          min={0}
          name="nutrition.proteins"
          label="Proteins"
          component={FormInput}
          dark
        />
      </Flex>

      <Flex mb="32px" maxWidth="100%">
        <Field
          large
          width="100%"
          type="number"
          min={0}
          name="nutrition.fibre"
          label="Fibre"
          textPostFix="g"
          component={FormInput}
          dark
        />

        <Field
          mr="40px"
          ml="40px"
          width="100%"
          textPostFix="mg"
          large
          type="number"
          min={0}
          name="nutrition.sodium"
          label="Sodium"
          component={FormInput}
          dark
        />

        <Flex />
      </Flex>
    </>
  )
}

export default Nutrition
