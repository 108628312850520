import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import ModalContainer from '../ui/ModalContainer.js'
import SideNav, { TABS } from '../Components/SideNav.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { useVideos } from '../ContentProvider.js'
import ChatButton from '../Components/ChatButton.js'
import { BigDarkSpinner, BigSpinner } from '../ui/Spinner.js'
import { loadConnectAndInitialize } from '@stripe/connect-js'
import {
  ConnectPayments,
  ConnectPayouts,
  ConnectBalances,
  ConnectComponentsProvider,
  ConnectAccountOnboarding,
  ConnectAccountManagement
} from '@stripe/react-connect-js'

const StripeDash = () => {
  const [isLoading, setIsLoading] = useState(true)
  // We use `useState` to ensure the Connect instance is only initialized once
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const res = await api.get('stripe/check-connect-account-requirements')
      if (!res.data.success) {
        // Handle errors on the client side here
        return undefined
      } else {
        const clientSecret = res.data.payload.clientSecret
        setIsLoading(false)
        return clientSecret
      }
    }

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey:
        'pk_live_51PMDhCHOkMZfJmbYFBRq4eMyeh7LvVyzjXUeQA5LFmsVOoDXlnkH8gJB852WjL7MDipWxrWVV2vauTx8rdZrlX3N005bqTaLIC',
      fetchClientSecret: fetchClientSecret,
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css2?family=Inter'
        }
      ],
      appearance: {
        variables: {
          fontFamily: 'Inter',
          colorPrimary: 'black'
        }
      }
    })
  })

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <Container mb="24px">
        <H3>Payments</H3>
        {isLoading ? (
          <Flex height="300px" alignItems="center" justifyContent="center">
            <BigDarkSpinner />
          </Flex>
        ) : null}
        <ConnectPayments />
      </Container>
      <Container>
        <H3>Balance & Payouts</H3>
        {isLoading ? (
          <Flex height="300px" alignItems="center" justifyContent="center">
            <BigDarkSpinner />
          </Flex>
        ) : null}
        <ConnectPayouts />
      </Container>
    </ConnectComponentsProvider>
  )
}

const PaymentsTab = (props) => {
  let { user, setUser } = useAuth()

  return (
    <Flex flexDirection="column" px="24px">
      {!user.stripeConnectAccountId ? (
        <Flex
          px="20px"
          py="16px"
          borderRadius="16px"
          backgroundColor={colors.green}
          alignItems="center"
          justifyContent="space-between"
          boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        >
          <Text color="white" fontWeight="500">
            Connect your Stripe account first to get started.
          </Text>
          <Link to="/settings">
            <Button label="Connect Stripe" backgroundColor="white" fontColorOveride={colors.green} />
          </Link>
        </Flex>
      ) : (
        <StripeDash />
      )}
    </Flex>
  )
}

export default PaymentsTab
