import React from 'react'
import hexToRgba from 'hex-to-rgba'
import styled from 'styled-components'
import api from '../../api'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import WriteComment from './WriteComment'
import { useState } from 'react'
import { useEffect } from 'react'
import { Box, Flex } from '../../ui/Layout'
import ModalContainer from '../../ui/ModalContainer'
import ImageComponent from '../../ui/Image'
import { Text } from '../../ui/Typography'
import { useAuth } from '../../Auth'

const TextArea = styled.textarea`
  padding: 12px;
  height: 100%;
  width: 100%;
  border: none;
  text-decoration: none;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  resize: none;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-family: ${(props) => props.secondaryFontFamily};
  color: ${(props) => props.secondaryFontColor};
`

const SlideDownContainer = styled(Flex)`
  margin-top: 24px;
  max-height: ${({ isActive }) => (isActive ? '300px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
`

const ImageModal = ({ imageUrl, isOpen, setIsOpen }) => {
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 })
  useEffect(() => {
    if (!imageUrl || !isOpen) return
    try {
      const img = new Image()
      img.onload = function () {
        setImageDimensions({ width: this.width, height: this.height })
      }
      img.src = imageUrl
    } catch (e) {
      console.log(e)
    }
  }, [imageUrl, isOpen])

  const isLandscape = imageDimensions.width > imageDimensions.height
  return (
    <ModalContainer autoWidth={true} autoHeight={true} p="0px" isOpen={isOpen} setIsOpen={setIsOpen}>
      <ImageComponent loading="lazy" width={isLandscape ? '800px' : '400px'} height="100%" src={imageUrl} />
    </ModalContainer>
  )
}

const Comment = ({
  id,
  firstName,
  lastName,
  imageUrl,
  comment,
  isCreator,
  createdAt,
  config,
  contentId,
  contentType,
  parentId,
  setComments,
  openReplyId,
  setOpenReplyId,
  children,
  fetchLikes,
  likes,
  comments
}) => {
  const { user } = useAuth()
  const isReplyOpen = openReplyId === id
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)

  const toggleReply = () => {
    if (isReplyOpen) {
      setOpenReplyId(null)
    } else {
      setOpenReplyId(id)
    }
  }

  const handleCancelReply = () => {
    setOpenReplyId(null)
  }

  const createLike = async () => {
    const response = await api.post('/comments/create-like', { commentId: id, contentId, contentType, isCreator: true })
    await fetchLikes()
  }

  const deleteLike = async () => {
    const response = await api.post('/comments/delete-like', { commentId: id, contentId, contentType, isCreator: true })
    await fetchLikes()
  }

  const isLiked = likes.some(
    (like) => like && like.isCreator && like.commentId === id && like.contentId === contentId && like.contentType === contentType
  )
  const countLikes = likes.filter(
    (like) => like.commentId === id && like.contentId === contentId && like.contentType === contentType
  ).length

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" ml="16px" mlm="8px">
        <Flex mb="16px">
          <Text light fontWeight="600" mr="16px" lineHeight="normal">
            {isCreator ? config.commentsName || user.organisationName : `${firstName} ${lastName}`}
          </Text>
          <Text light opacity="0.5" lineHeight="normal">
            {dayjs(createdAt).fromNow()}
          </Text>
        </Flex>
        <Text whiteSpace="pre-wrap" light lineHeight="150%" mb="16px">
          {comment}
        </Text>
        {imageUrl ? (
          <Box width="100px" height="100px" mb="16px">
            <ImageComponent
              cursor="pointer"
              loading="lazy"
              width="100%"
              objectFit="cover"
              borderRadius="8px"
              height="100%"
              src={imageUrl}
              onClick={() => setIsImageModalOpen(true)}
            />
            <ImageModal imageUrl={imageUrl} isOpen={isImageModalOpen} setIsOpen={setIsImageModalOpen} />
          </Box>
        ) : null}
        <Flex opacity="0.5" alignItems="center">
          <Flex mr="40px" cursor="pointer" width="auto" alignItems="center" onClick={isLiked ? deleteLike : createLike}>
            {isLiked ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 1.314C12.4384 -3.24799 23.5343 4.7355 8 15C-7.53427 4.7355 3.56164 -3.24799 8 1.314Z"
                  fill={config.primaryButtonColor}
                />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 3.24805L7.28325 2.51133C5.5989 0.780067 2.51415 1.37769 1.40036 3.55284C0.876534 4.57583 0.75875 6.05246 1.71429 7.93758C2.63457 9.75313 4.54767 11.9265 8 14.2946C11.4523 11.9265 13.3654 9.75313 14.2857 7.93758C15.2413 6.05246 15.1235 4.57583 14.5996 3.55284C13.4859 1.37769 10.4011 0.780067 8.71675 2.51133L8 3.24805ZM8 15.5C-7.33313 5.36841 3.27876 -2.54087 7.82432 1.64308C7.88395 1.69797 7.94253 1.75493 8 1.814C8.05747 1.75494 8.11605 1.69797 8.17567 1.64309C12.7212 -2.54088 23.3331 5.3684 8 15.5Z"
                  fill={config.secondaryFontColor}
                />
              </svg>
            )}
            <Text ml="8px" light lineHeight="normal">
              {isLiked ? 'Liked' : 'Like'} {countLikes ? `(${countLikes})` : ''}
            </Text>
          </Flex>
          <Flex mr="40px" cursor="pointer" width="auto" alignItems="center" onClick={toggleReply}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.67789 12.394C2.88828 12.6049 2.99363 12.8988 2.96523 13.1954C2.90159 13.8601 2.75502 14.5519 2.56677 15.1945C3.96166 14.8721 4.81358 14.4981 5.20082 14.3019C5.42062 14.1905 5.67381 14.1642 5.91182 14.228C6.56966 14.4044 7.27076 14.5 8 14.5C11.9956 14.5 15 11.6925 15 8.5C15 5.30754 11.9956 2.5 8 2.5C4.0044 2.5 1 5.30754 1 8.5C1 9.96809 1.6173 11.3304 2.67789 12.394ZM2.18489 16.2989C2.17816 16.3003 2.17142 16.3016 2.16466 16.3029C2.07283 16.321 1.97922 16.3388 1.88382 16.3563C1.75 16.3808 1.61264 16.4047 1.47172 16.4277C1.27271 16.4603 1.12037 16.2521 1.19898 16.0664C1.25034 15.9451 1.30097 15.8179 1.3503 15.6861C1.38164 15.6023 1.41245 15.5167 1.44259 15.4294C1.44389 15.4256 1.44518 15.4219 1.44648 15.4181C1.69422 14.6984 1.89596 13.8711 1.96979 13.1001C0.743061 11.8699 0 10.2609 0 8.5C0 4.63401 3.58172 1.5 8 1.5C12.4183 1.5 16 4.63401 16 8.5C16 12.366 12.4183 15.5 8 15.5C7.18324 15.5 6.39508 15.3929 5.65284 15.1939C5.13337 15.4571 4.01434 15.9363 2.18489 16.2989Z"
                fill={config.secondaryFontColor}
              />
            </svg>
            <Text ml="8px" light lineHeight="normal">
              Reply
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <SlideDownContainer isActive={isReplyOpen}>
        <WriteComment
          comments={comments}
          contentId={contentId}
          contentType={contentType}
          parentId={id}
          setComments={setComments}
          config={config}
          focus={isReplyOpen}
          onCancel={handleCancelReply}
        />
      </SlideDownContainer>
      {children}
    </Flex>
  )
}

export default Comment
