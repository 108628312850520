import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { Flex } from './Layout'
import { colors } from './helpers'

const Outer = styled(Flex)`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  border: 2px solid ${(props) => (props.toggled ? props.color : '#CACED6')};
  border-radius: ${(props) => props.width}px;
  align-items: center;
  justify-content: center;
`

const Inner = styled(Flex)`
  width: ${(props) => props.width / 2}px;
  height: ${(props) => props.width / 2}px;
  ${(props) => (props.toggled ? `background-color: ${props.color || 'black'};` : '')}
  transition: background-color 0.3s ease;
  border-radius: ${(props) => props.width}px;
`

const CircleToggle = ({ toggled, width, color }) => {
  return (
    <Outer color={color || colors.green} toggled={toggled} width={width}>
      <Inner color={color || colors.green} toggled={toggled} width={width} />
    </Outer>
  )
}

export default CircleToggle
