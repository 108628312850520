import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import baseUrl from '../baseUrl.js'
import PaymentsTab from './PaymentsTab.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import { Td, Tr, HTr, HeaderText, Table, Th } from '../ui/Table.js'
import { currencySymbols } from '../ui/formatters.js'
import Icon from '../ui/Icon.js'
import { mkConfig, generateCsv, download } from 'export-to-csv'
import CreateSubscriberModal from './CreateSubscriberModal.js'

const csvConfig = mkConfig({ useKeysAsHeaders: true })

const LineClamp = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const PAGESIZE = 50

const ContactsTab = (props) => {
  let { user, setUser } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [allCustomers, setAllCustomers] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [showCreateSubscriberModal, setShowCreateSubscriberModal] = useState(false)

  const fetchCustomers = async () => {
    setIsLoading(true)
    try {
      const res = await api.get(`/customers/get-customers`)
      setAllCustomers(res.data.payload)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchCustomers()
  }, [])

  const totalPages = Math.ceil(allCustomers.length / PAGESIZE)
  const paginatedCustomers = allCustomers.slice((currentPage - 1) * PAGESIZE, currentPage * PAGESIZE)

  const renderPageNumbers = () => {
    const pageNumbers = []
    const maxVisiblePages = 5
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2))
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Text
          key={i}
          onClick={() => setCurrentPage(i)}
          light={i !== currentPage}
          fontWeight={i === currentPage ? '600' : '500'}
          px="8px"
          cursor="pointer"
        >
          {i}
        </Text>
      )
    }
    return pageNumbers
  }

  return (
    <Flex px="24px">
      {isLoading ? (
        <Flex height="300px" alignItems="center" justifyContent="center">
          <BigDarkSpinner />
        </Flex>
      ) : (
        <Flex flexDirection="column" pb="100px">
          <Container p="0px" pt="16px">
            <Flex justifyContent="space-between" px="24px" mb="16px">
              <SmallTitle>Contacts</SmallTitle>
              <Flex width="auto">
                <Text mr="32px" cursor="pointer" fontWeight="500" onClick={() => setShowCreateSubscriberModal(true)}>
                  + Add Contact
                </Text>
                <Flex
                  width="auto"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => {
                    const csv = generateCsv(csvConfig)(
                      allCustomers.map((c) => {
                        return {
                          first_name: c.firstName,
                          last_name: c.lastName,
                          sign_up_date: dayjs(c.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                          email: c.email
                        }
                      })
                    )
                    download(csvConfig)(csv)
                  }}
                >
                  <Icon mr="8px" icon="download-black" width="16px" height="16px" />
                  <Text fontWeight="500">Download CSV</Text>
                </Flex>
              </Flex>
            </Flex>
            <Table>
              <HTr>
                <Th width="220px !important">
                  <HeaderText left>NAME</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>SIGN UP DATE</HeaderText>
                </Th>
                <Th>
                  <HeaderText left>EMAIL</HeaderText>
                </Th>
              </HTr>
              {paginatedCustomers &&
                paginatedCustomers.map((customer) => {
                  return (
                    <Tr key={customer.id} justifyContent="space-between">
                      <Td left>
                        <LineClamp>
                          {customer.firstName} {customer.lastName}
                        </LineClamp>
                      </Td>
                      <Td left lightLight>
                        {dayjs(customer.createdAt).format('MMM DD, YYYY')}
                      </Td>
                      <Td left lightLight>
                        {customer.email}
                      </Td>
                    </Tr>
                  )
                })}
            </Table>
            {!allCustomers?.length ? (
              <>
                <Text light fontSize="16px" pt="120px" mb="8px" fontWeight="500" textAlign="center">
                  No contacts yet
                </Text>
                <Text light fontSize="14px" pb="120px" textAlign="center">
                  You’ll find all of your contacts here including free signups, and previous subscribers
                </Text>
              </>
            ) : null}
            <Flex>
              <Flex mt="16px" mb="16px" justifyContent="space-between">
                <Text fontWeight="300" width="400px" lightLight px="16px" italic>
                  Showing {!allCustomers?.length ? 0 : (currentPage - 1) * PAGESIZE + 1} -{' '}
                  {Math.min(currentPage * PAGESIZE, allCustomers.length)} of {allCustomers.length}
                </Text>
                <Flex cursor="pointer" justifyContent="center" alignItems="center">
                  <Text
                    onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
                    light={currentPage > 1}
                    lightLight={currentPage === 1}
                    fontWeight="500"
                    px="8px"
                    cursor={currentPage > 1 ? 'pointer' : 'default'}
                  >
                    {'<'}
                  </Text>
                  {renderPageNumbers()}
                  <Text
                    onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
                    light={currentPage < totalPages}
                    lightLight={currentPage === totalPages}
                    fontWeight="500"
                    px="8px"
                    cursor={currentPage < totalPages ? 'pointer' : 'default'}
                  >
                    {'>'}
                  </Text>
                </Flex>
                <Flex justifyContent="flex-end" pr="32px" width="400px"></Flex>
              </Flex>
            </Flex>
            <CreateSubscriberModal
              onClose={() => fetchCustomers()}
              isOpen={showCreateSubscriberModal}
              setIsOpen={setShowCreateSubscriberModal}
            />
          </Container>
        </Flex>
      )}
    </Flex>
  )
}

export default ContactsTab
