import React, { useState } from 'react'
import styled from 'styled-components'
import { colors, fontFamily } from './helpers'
import { Box, Flex } from './Layout.js'
import { Text, Label } from './Typography'
import Spinner from './Spinner'
import Select from 'react-select'

import {
  space,
  width,
  color,
  flex,
  order,
  fontSize,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  borders,
  minWidth,
  minHeight,
  borderRadius,
  size,
  height,
  maxWidth,
  textAlign,
  boxShadow,
  borderColor,
  position,
  display,
  top,
  bottom,
  left,
  right,
  zIndex
} from 'styled-system'

const SelectComponent = ({ options, value, onChange, placeholder, isMulti, clearable, label, fullWidth, ...rest }) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #E2E8F0'
      },
      fontSize: '14px',
      borderRadius: '8px',
      cursor: 'pointer'
    }),
    placeholder: (base) => ({
      ...base,
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px'
    }),
    input: (base) => ({
      ...base,
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
      borderRadius: '8px'
    }),
    singleValue: (base) => ({
      ...base,
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px'
    }),
    multiValue: (base) => ({
      ...base,
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px'
    }),
    multiValueLabel: (base) => ({
      ...base,
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px'
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      margin: '12px',
      marginBottom: '4px',
      marginTop: '4px',
      cursor: 'pointer',
      width: 'calc(100% - 24px)',
      borderRadius: '8px',
      backgroundColor: isFocused ? colors.green : 'white',
      color: isFocused ? 'white' : 'inherit',
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
      ':active': {
        backgroundColor: colors.green
      }
    }),
    menu: (base) => ({
      ...base,
      borderRadius: '16px',
      paddingTop: '4px',
      paddingBottom: '4px',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      border: '1px solid #E2E8F0'
    })
  }

  return (
    <Flex height="auto" width={fullWidth ? '100%' : 'auto'} flexDirection="column">
      {label ? <Label>{label}</Label> : null}
      <Select
        isMulti={isMulti}
        placeholder={placeholder}
        styles={customStyles}
        value={options.find((el) => el.value === value)}
        onChange={onChange}
        isClearable={clearable}
        options={options}
        {...rest}
      />
    </Flex>
  )
}

export default SelectComponent

export const SideSelect = (props) => {
  return (
    <Flex
      py="16px"
      borderBottom={props.noBorder ? '' : '1px solid rgba(237, 242, 247, 1)'}
      px="24px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Label mb="0">{props.label}</Label>
      <SelectComponent {...props} width="150px" label={undefined} />
    </Flex>
  )
}
