import { useState } from 'react'
import Button from '../ui/Button'
import Icon from '../ui/Icon'
import Image from '../ui/Image'
import ImageUploadModal from '../ui/ImageUploadModal'
import { Input, TextArea } from '../ui/Input'
import { Box, Flex } from '../ui/Layout'
import { Error, Text } from '../ui/Typography'
import { uniq, update } from 'ramda'
import AddStepsModal from './AddStepsModal'

const RecipeSteps = ({ recipeSteps, setRecipeSteps, errors, values }) => {
  const [showAddStepsModal, setShowAddStepsModal] = useState(false)
  return (
    <>
      <Text mb="8px" color="black" fontSize="18px" fontWeight="600" width="100%">
        Recipe Steps
      </Text>
      <Text mb="24px" light fontSize="14px" fontWeight="400" width="100%">
        Step by step instructions for the recipe
      </Text>
      {recipeSteps.map((step, i) => {
        return (
          <RecipeStep
            errors={errors}
            key={i}
            i={i}
            step={step}
            recipeSteps={recipeSteps}
            setRecipeSteps={setRecipeSteps}
            values={values}
          />
        )
      })}
      <Flex>
        <Button
          mr="8px"
          variant="green"
          label="Add step"
          onClick={() => {
            setRecipeSteps(recipeSteps.concat(recipeSteps && recipeSteps.length ? {} : { time: 0, formattedTime: '00:00' }))
          }}
          zIndex={1}
        />
        <Button
          variant="secondary"
          label="Bulk add steps"
          onClick={() => {
            setShowAddStepsModal(true)
          }}
          zIndex={1}
        />
      </Flex>
      {errors[`steps`] && (
        <Error mt="4px" zIndex={1}>
          {errors[`steps`]}
        </Error>
      )}
      <AddStepsModal isOpen={showAddStepsModal} setIsOpen={setShowAddStepsModal} setSteps={setRecipeSteps} steps={recipeSteps} />
    </>
  )
}

const RecipeStep = ({ recipeSteps, setRecipeSteps, step, i, errors, values }) => {
  const [imageUploadOpen, setImageUploadOpen] = useState(false)
  console.log(step.formattedTime, step.time)
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb="16px">
        <Flex alignItems="center" width="auto">
          <Text color="black" fontSize="14px" fontWeight="600" width="auto" mr="16px">
            Step {i + 1}
          </Text>
          <Icon
            cursor="pointer"
            icon="circle-cross-grey"
            width="16px"
            height="16px"
            onClick={() => {
              setRecipeSteps(recipeSteps.filter((el, ii) => ii !== i))
            }}
          />
        </Flex>
      </Flex>
      <Flex
        mb="32px"
        backgroundColor="#fbfbfb"
        boxShadow="0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);"
        borderRadius="8px"
      >
        <Flex flexDirection="column">
          <TextArea
            noBorder
            onChange={(e) => {
              const value = e.target.value
              const newSteps = update(i, { ...step, text: value }, recipeSteps)
              setRecipeSteps(newSteps)
            }}
            value={step.text}
            placeholder="Enter details"
            name={`step-${i}`}
            width="100%"
            mr="16px"
            mb="8px"
            height="100%"
          />
          {values.hasStepPlayer && values.videoId ? (
            <Flex>
              <Input
                noBorder
                height="24px"
                mb="8px"
                dark
                type="time"
                onChange={(e) => {
                  const value = e.target.value
                  let seconds
                  try {
                    seconds = Number(value.split(':')[0]) * 60 + Number(value.split(':')[1])
                  } catch (e) {}
                  const newSteps = update(i, { ...step, formattedTime: value, time: seconds }, recipeSteps)
                  setRecipeSteps(newSteps)
                }}
                value={step.formattedTime}
                pl="24px !important"
                pr="8px !important"
                placeholder="mm:ss"
              />
            </Flex>
          ) : null}
          {errors[`step-${i}`] && (
            <Error mt="-10px" px="24px" zIndex={1}>
              {errors[`step-${i}`]}
            </Error>
          )}
        </Flex>
        {step.image ? (
          <Box m="8px" width="118px" minWidth="118px" height="118px" onClick={() => setImageUploadOpen(true)} cursor="pointer">
            <Image src={step.image + '?width=400&height=400'} width="118px" height="118px" objectFit="cover" borderRadius="8px" />
          </Box>
        ) : (
          <Flex
            m="8px"
            borderRadius="8px"
            width="118px"
            minWidth="118px"
            height="118px"
            cursor="pointer"
            justifyContent="center"
            alignItems="center"
            border="1px dashed #718096"
            onClick={() => setImageUploadOpen(true)}
          >
            <Icon icon="image-dark-grey" width="20px" height="20px" />
          </Flex>
        )}
        <ImageUploadModal
          isOpen={imageUploadOpen}
          setIsOpen={setImageUploadOpen}
          saveFunction={(urls) => {
            const newSteps = update(i, { ...step, image: urls[0] }, recipeSteps)
            setRecipeSteps(newSteps)
          }}
        />
      </Flex>
    </>
  )
}

export default RecipeSteps
