import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

const Image = styled.img`
  ${space};
  width: ${(props) => (props.width ? props.width : 18)}px;
  ${(props) => (props.minWidth ? `min-width:${props.minWidth}px;` : '')}
  ${(props) => (props.minHeight ? `min-height:${props.minHeight}px;` : '')}
  height: ${(props) => (props.height ? props.height : 18)}px;
  ${(props) => props.cursor && `cursor: ${props.cursor};`}
  ${(props) => props.transform && `transform: ${props.transform};`}
  transition: transform 0.2s ease;
`

const Icon = ({ icon, transform, onClick, width, height, ...rest }) => {
  const iconUrl = `/icons/${icon}.svg`

  if (!iconUrl) {
    return <div />
  }
  return <Image onClick={onClick} src={iconUrl} width={width} height={height} transform={transform} alt={icon} {...rest} />
}

export default Icon
