import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, shorten } from './helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from './Typography.js'
import { Flex, Box, Container } from './Layout.js'
import Icon from './Icon.js'
import { motion, AnimatePresence } from 'framer-motion'
import { RemoveScroll } from 'react-remove-scroll'

const OverlayBackground = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1300000;
`

const SlideOver = styled(motion.div)`
  position: fixed;
  right: 0;
  top: 0;
  background-color: white;
  width: ${(props) => props.maxWidth || '600px'};
  max-width: ${(props) => props.maxWidth || '600px'};
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  z-index: 1300002;
  padding: ${(props) => props.padding || '24px'};
`

const SlideOverContainer = ({ isOpen, setIsOpen, width, children, internalClose, onClose, padding, noAutoClose }) => {
  const close = () => {
    setIsOpen(false)
    onClose && onClose()
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <OverlayBackground
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={noAutoClose ? null : close}
          />
          <RemoveScroll enabled={isOpen} forwardProps>
            <SlideOver
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ type: 'tween', duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
              maxWidth={width || '500px'}
              padding={padding}
            >
              {internalClose ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  bg="white"
                  position="absolute"
                  width="auto"
                  top="24px"
                  left="24px"
                  zIndex="1"
                  cursor="pointer"
                  onClick={close}
                >
                  <Icon width="14px" height="14px" icon="close-cross-grey" />
                </Flex>
              ) : null}
              {children}
            </SlideOver>
          </RemoveScroll>
        </>
      )}
    </AnimatePresence>
  )
}

export default SlideOverContainer
