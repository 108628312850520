import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { colors, formatSeconds } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { useContent, useVideos } from '../ContentProvider.js'
import ModalContainer from '../ui/ModalContainer.js'
import { uniq, difference, intersection } from 'ramda'
import Popover from '../Components/ui/Popover.js'
import { CONTENT_TYPES } from './index.js'

const InputContainer = styled(Flex)`
  border: 1px solid #cbd5e0;
  height: 36px;
  border-radius: 36px;
  background-color: white;
  overflow: hidden;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  width: 250px;
`

const TagContainer = styled(Flex)`
  cursor: pointer;
  width: 118px;
  min-width: 118px;
  justify-content: space-between;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  height: 36px;
  border-radius: 36px;
  background-color: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 16px;
`

const VerticalDivider = styled(Box)`
  border-right: 1px solid rgba(166, 183, 212, 1);
  width: 0px;
  margin-right: 16px;
  margin-left: 8px;
  height: 40px;
`

const InputComponent = styled.input`
  ::-webkit-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  ::-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-ms-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  background: white;
  height: 36px;
  margin: 0px;
  margin-bottom: 0px;
  border-radius: 6px;
  color: white;
  display: inline-block;
  border: none;
  font-size: 14px;
  text-decoration: none;
  color: ${colors.textDark};
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
`

const FullWidthForm = styled.form`
  margin-left: 16px;
  width: 100%;
`
export const FilterButton = styled(Flex)`
  cursor: pointer;
  width: 92px;
  min-width: 84px;
  justify-content: space-between;
  box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  height: 36px;
  border-radius: 36px;
  background-color: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && `border-bottom: 1px solid ${colors.green};`}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive, count }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
      {count ? (
        <Span ml="8px" light>
          ({count})
        </Span>
      ) : null}
    </TabText>
  )
}

const Search = ({
  searchTerm,
  setSearchTerm,
  selectedTags,
  setPage,
  setSelectedTags,
  setShowPublished,
  setContentType,
  contentType,
  showPublished,
  selectAll,
  countPublished,
  countDraft
}) => {
  const [tempSearch, setTempSearch] = useState('')
  const [filterPopoverOpen, setFilterPopoverOpen] = useState(false)
  const { recipes, mealPlans, refresh } = useContent()
  const inputAllTags = uniq(
    [...recipes, ...mealPlans]
      .map((el) => el.tags)
      .flat()
      .filter((el) => el)
      .sort()
  )

  useEffect(() => {
    if (searchTerm !== tempSearch) {
      setTempSearch(searchTerm)
    }
  }, [searchTerm])

  const submit = async (e) => {
    e?.preventDefault()
    setPage(1)
    setSearchTerm(tempSearch)
  }

  return (
    <>
      <Flex mb="24px">
        <Flex borderBottom="1px solid #E2E8F0" justifyContent="space-between">
          <Flex width="auto">
            <Tab title="Published" count={countPublished} isActive={showPublished} onClick={() => setShowPublished(true)} />
            <Tab title="Draft" count={countDraft} isActive={!showPublished} onClick={() => setShowPublished(false)} />
          </Flex>
          <Flex width="auto" alignItems="center">
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="16px"
              height="28px"
              px="12px"
              width="auto"
              mr="8px"
              cursor="pointer"
              onClick={() =>
                contentType === CONTENT_TYPES.RECIPE ? setContentType(CONTENT_TYPES.BOTH) : setContentType(CONTENT_TYPES.RECIPE)
              }
            >
              <Text
                fontWeight={contentType === CONTENT_TYPES.RECIPE ? '600' : '400'}
                color={contentType === CONTENT_TYPES.RECIPE ? colors.green : 'undefined'}
                light
              >
                Recipes
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="16px"
              height="28px"
              px="12px"
              width="auto"
              mr="8px"
              cursor="pointer"
              onClick={() =>
                contentType === CONTENT_TYPES.MEAL_PLAN
                  ? setContentType(CONTENT_TYPES.BOTH)
                  : setContentType(CONTENT_TYPES.MEAL_PLAN)
              }
            >
              <Text
                fontWeight={contentType === CONTENT_TYPES.MEAL_PLAN ? '600' : '400'}
                color={contentType === CONTENT_TYPES.MEAL_PLAN ? colors.green : 'undefined'}
                light
              >
                Plans
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="16px"
              height="28px"
              px="12px"
              width="auto"
              mr="16px"
              cursor="pointer"
              onClick={() =>
                contentType === CONTENT_TYPES.POST ? setContentType(CONTENT_TYPES.BOTH) : setContentType(CONTENT_TYPES.POST)
              }
            >
              <Text
                fontWeight={contentType === CONTENT_TYPES.POST ? '600' : '400'}
                color={contentType === CONTENT_TYPES.POST ? colors.green : 'undefined'}
                light
              >
                Posts
              </Text>
            </Flex>
            <InputContainer>
              <Icon icon="search-grey" width={14} height={14} />
              <FullWidthForm onSubmit={submit}>
                <InputComponent placeholder="Search ..." onChange={(e) => setTempSearch(e.target.value)} value={tempSearch} />
              </FullWidthForm>
              {tempSearch || searchTerm ? (
                <Icon
                  cursor="pointer"
                  icon="circle-cross-grey"
                  width={14}
                  height={14}
                  onClick={() => {
                    setSearchTerm('')
                    setTempSearch('')
                  }}
                />
              ) : null}
            </InputContainer>
            {inputAllTags && inputAllTags.length ? (
              <Popover
                isOpen={filterPopoverOpen}
                onClose={() => setFilterPopoverOpen(false)}
                position="left"
                content={
                  <Box maxHeight="300px" overflowY="auto" width="200px" p="16px" noScrollBar>
                    {inputAllTags
                      .sort((a, b) => a.localeCompare(b))
                      .map((tag) => (
                        <Flex
                          key={tag}
                          alignItems="center"
                          p="8px"
                          cursor="pointer"
                          justifyContent="space-between"
                          onClick={() => {
                            const isSelected = selectedTags.includes(tag)
                            if (isSelected) {
                              setSelectedTags(selectedTags.filter((t) => t !== tag))
                            } else {
                              setSelectedTags([...selectedTags, tag])
                            }
                          }}
                        >
                          <Text fontWeight="400" fontSize="14px">
                            {tag}
                          </Text>
                          {selectedTags?.includes(tag) && <Icon icon="tick-green-2" width={16} height={16} />}
                        </Flex>
                      ))}
                  </Box>
                }
              >
                <Box>
                  <Button
                    ml="16px"
                    onClick={() => setFilterPopoverOpen(!filterPopoverOpen)}
                    variant="secondary"
                    label={'Filter' + (selectedTags && selectedTags.length ? ` (${selectedTags.length})` : '')}
                    height="36px"
                    backgroundColor={'white'}
                    renderRightIcon={() => <Icon icon="filter-green" ml="8px" width={16} height={16} />}
                  />
                </Box>
              </Popover>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default Search
