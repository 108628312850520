import React, { useState, useRef, useEffect } from 'react'
import hexToRgba from 'hex-to-rgba'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout'
import api from '../../api'
import Image from '../../ui/Image'
import { Text } from '../../ui/Typography'
import Button from '../../ui/Button'
import { useInbox } from '../../InboxProvider'
import { useAuth } from '../../Auth'

const TextArea = styled.textarea`
  padding: 12px;
  height: 100%;
  width: 100%;
  border: none;
  text-decoration: none;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  resize: none;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #425466;
    &&::placeholder {
    color:  ${(props) => hexToRgba(props.secondaryFontColor, 0.6)};
    opacity: 1; * Firefox */
  }
`

const SlideDownContainer = styled(Flex)`
  max-height: ${({ isActive }) => (isActive ? '100px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`

const CommentCircle = styled.div`
  width: 40px;
  min-width: 40px;
  margin-right: 16px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => props.primaryButtonColor};
  color: ${(props) => props.primaryButtonFontColor};
  font-family: ${(props) => props.primaryFontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  @media (max-width: 700px) {
    display: none;
  }
`

const ImageContainer = styled(Flex)`
  @media (max-width: 700px) {
    display: none;
  }
`

const MobileImageContainer = styled(Box)`
  width: 24px;
  min-width: 24px;
  display: none;
  @media (max-width: 700px) {
    display: block;
  }
`

const StyledInput = styled.input`
  display: none;
`

const ImageButton = styled(Flex)`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(68, 68, 68, 0.5);
`

const WriteComment = ({ contentId, contentType, comments, parentId, setComments, config, focus, onCancel }) => {
  const { user } = useAuth()
  const { refresh } = useInbox()
  const { refresh: refreshAuth } = useAuth()

  const [comment, setComment] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [isTextAreaActive, setIsTextAreaActive] = useState(false)
  const [files, setFiles] = useState([])
  const [imageUrl, setImageUrl] = useState()
  const parentComment = parentId ? comments.find((comment) => comment.id === parentId) : null

  const [id, setId] = useState()

  useEffect(() => {
    setId(Math.round(Math.random() * 100000))
  }, [])

  const textareaRef = useRef(null)

  useEffect(() => {
    if (focus && textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [focus])

  const createComment = async () => {
    if (!comment) {
      return
    }
    setIsSending(true)
    console.log('CREATE')
    const res = await api.post(`/comments/create`, { contentId, contentType, parentId, comment, imageUrl, isCreator: true })
    console.log('CREATE END')
    console.log('READ')

    await api.post('/organisations/update-inbox-last-read')
    console.log('READ END')
    await refreshAuth()
    await refresh()
    setIsSending(false)
    handleCancel()
  }

  const handleCancel = () => {
    setIsTextAreaActive(false)
    setComment('')
    setImageUrl()
    if (onCancel) onCancel()
  }

  const uploadImage = async () => {
    const filePicker = document.getElementById(`clipara-file-picker-${id}`)
    filePicker.click()
  }

  useEffect(() => {
    const func = async () => {
      if (files && files[0]) {
        const file = files[0]
        let formData = new FormData()
        formData.append('file', file)
        formData.append('name', file.name)
        formData.append('path', 'clubb-comment-images')
        const res = await api.post('/comments/image-upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        const url = res.data.payload.url
        setImageUrl(url)
      }
    }
    func()
  }, [files])

  return (
    <Flex flexDirection="column">
      <Flex>
        <CommentCircle
          position
          primaryButtonColor={config.primaryButtonColor}
          primaryButtonFontColor={config.primaryButtonFontColor}
          primaryFontFamily={config.primaryFontFamily}
          backgroundImage={config.commentsImageUrl}
        >
          {config.commentsImageUrl && comment.isCreator
            ? ''
            : comment.firstName
            ? comment.firstName[0].toUpperCase()
            : user.organisationName
            ? user.organisationName[0].toUpperCase()
            : ''}
        </CommentCircle>

        <Flex
          position="relative"
          height="116px"
          borderRadius="16px"
          border={`1px solid ${hexToRgba(config.primaryFontColor, 0.3)}`}
          bg="white"
          p="8px"
        >
          <TextArea
            ref={textareaRef}
            placeholder={parentId ? `Reply to ${parentComment.firstName || 'comment'}` : ''}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            secondaryFontColor={config.secondaryFontColor}
            secondaryFontFamily={config.secondaryFontFamily}
            onFocus={() => setIsTextAreaActive(true)} // Set active on focus
            onBlur={(e) => {
              if (!e.target.value) setIsTextAreaActive(false) // Set inactive on blur if empty
            }}
          />
          <ImageContainer
            width="100px"
            minWidth="100px"
            height="100px"
            border={imageUrl ? 'none' : '1px dashed #718096'}
            borderRadius="8px"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={uploadImage}
            overflow="hidden"
            position="relative"
          >
            {imageUrl ? (
              <Image width="100px" height="100px" objectFit="cover" src={imageUrl + '?width=300&height=300'} />
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.0015 2H2.00146C1.44918 2 1.00146 2.44772 1.00146 3V12L3.64791 9.64646C3.81644 9.47793 4.0805 9.45178 4.27882 9.58399L6.93776 11.3566L10.6479 7.64646C10.8001 7.49428 11.0326 7.45655 11.2251 7.5528L15.0015 9.50001V3C15.0015 2.44772 14.5537 2 14.0015 2ZM2.00146 1C0.896895 1 0.00146484 1.89543 0.00146484 3V13C0.00146484 14.1046 0.896895 15 2.00146 15H14.0015C15.106 15 16.0015 14.1046 16.0015 13V3C16.0015 1.89543 15.106 1 14.0015 1H2.00146ZM6.00146 5.5C6.00146 6.32843 5.32989 7 4.50146 7C3.67304 7 3.00146 6.32843 3.00146 5.5C3.00146 4.67157 3.67304 4 4.50146 4C5.32989 4 6.00146 4.67157 6.00146 5.5Z"
                  fill="#718096"
                />
              </svg>
            )}
            {imageUrl ? (
              <>
                <ImageButton position="absolute" bottom="2px" left="2px">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.5337 7H15.4662C15.6781 7 15.7939 7.24721 15.6582 7.41005L13.692 9.76953C13.592 9.88947 13.4078 9.88947 13.3079 9.76953L11.3416 7.41005C11.2059 7.24721 11.3217 7 11.5337 7Z"
                      fill="white"
                    />
                    <path
                      d="M0.533681 9H4.46616C4.67812 9 4.79391 8.75279 4.65822 8.58995L2.69198 6.23047C2.59203 6.11053 2.40781 6.11053 2.30787 6.23047L0.341625 8.58995C0.205932 8.75279 0.321722 9 0.533681 9Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.99992 3C6.44743 3 5.06049 3.70697 4.14261 4.81839C3.96677 5.03131 3.65161 5.06137 3.43869 4.88552C3.22578 4.70968 3.19572 4.39453 3.37156 4.18161C4.47126 2.85003 6.13654 2 7.99992 2C10.9416 2 13.3879 4.1165 13.901 6.90967C13.9065 6.9397 13.9118 6.96981 13.9169 7H12.9C12.437 4.71778 10.4185 3 7.99992 3ZM3.09984 9C3.56285 11.2822 5.58129 13 7.99992 13C9.55241 13 10.9394 12.293 11.8572 11.1816C12.0331 10.9687 12.3482 10.9386 12.5611 11.1145C12.7741 11.2903 12.8041 11.6055 12.6283 11.8184C11.5286 13.15 9.8633 14 7.99992 14C5.05822 14 2.6119 11.8835 2.09884 9.09033C2.09332 9.0603 2.08803 9.03019 2.08296 9H3.09984Z"
                      fill="white"
                    />
                  </svg>
                </ImageButton>

                <ImageButton
                  position="absolute"
                  bottom="2px"
                  right="2px"
                  onClick={(e) => {
                    e.stopPropagation()
                    setImageUrl()
                  }}
                >
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.30769 5.86667C5.60508 5.86667 5.84615 6.10545 5.84615 6.4V12.8C5.84615 13.0946 5.60508 13.3333 5.30769 13.3333C5.01031 13.3333 4.76923 13.0946 4.76923 12.8V6.4C4.76923 6.10545 5.01031 5.86667 5.30769 5.86667Z"
                      fill="white"
                    />
                    <path
                      d="M8 5.86667C8.29738 5.86667 8.53846 6.10545 8.53846 6.4V12.8C8.53846 13.0946 8.29738 13.3333 8 13.3333C7.70262 13.3333 7.46154 13.0946 7.46154 12.8V6.4C7.46154 6.10545 7.70262 5.86667 8 5.86667Z"
                      fill="white"
                    />
                    <path
                      d="M11.2308 6.4C11.2308 6.10545 10.9897 5.86667 10.6923 5.86667C10.3949 5.86667 10.1538 6.10545 10.1538 6.4V12.8C10.1538 13.0946 10.3949 13.3333 10.6923 13.3333C10.9897 13.3333 11.2308 13.0946 11.2308 12.8V6.4Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15 3.2C15 3.7891 14.5178 4.26667 13.9231 4.26667H13.3846V13.8667C13.3846 15.0449 12.4203 16 11.2308 16H4.76923C3.57969 16 2.61538 15.0449 2.61538 13.8667V4.26667H2.07692C1.48215 4.26667 1 3.7891 1 3.2V2.13333C1 1.54423 1.48215 1.06667 2.07692 1.06667H5.84615C5.84615 0.477563 6.32831 0 6.92308 0H9.07692C9.67169 0 10.1538 0.477563 10.1538 1.06667H13.9231C14.5178 1.06667 15 1.54423 15 2.13333V3.2ZM3.81942 4.26667L3.69231 4.32962V13.8667C3.69231 14.4558 4.17446 14.9333 4.76923 14.9333H11.2308C11.8255 14.9333 12.3077 14.4558 12.3077 13.8667V4.32962L12.1806 4.26667H3.81942ZM2.07692 3.2V2.13333H13.9231V3.2H2.07692Z"
                      fill="white"
                    />
                  </svg>
                </ImageButton>
              </>
            ) : null}
          </ImageContainer>
          <MobileImageContainer onClick={uploadImage}>
            {imageUrl ? (
              <Image borderRadius="4px" width="24px" height="24px" objectFit="cover" src={imageUrl + '?width=200&height=200'} />
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.0015 2H2.00146C1.44918 2 1.00146 2.44772 1.00146 3V12L3.64791 9.64646C3.81644 9.47793 4.0805 9.45178 4.27882 9.58399L6.93776 11.3566L10.6479 7.64646C10.8001 7.49428 11.0326 7.45655 11.2251 7.5528L15.0015 9.50001V3C15.0015 2.44772 14.5537 2 14.0015 2ZM2.00146 1C0.896895 1 0.00146484 1.89543 0.00146484 3V13C0.00146484 14.1046 0.896895 15 2.00146 15H14.0015C15.106 15 16.0015 14.1046 16.0015 13V3C16.0015 1.89543 15.106 1 14.0015 1H2.00146ZM6.00146 5.5C6.00146 6.32843 5.32989 7 4.50146 7C3.67304 7 3.00146 6.32843 3.00146 5.5C3.00146 4.67157 3.67304 4 4.50146 4C5.32989 4 6.00146 4.67157 6.00146 5.5Z"
                  fill="#718096"
                />
              </svg>
            )}
          </MobileImageContainer>
          <StyledInput
            type="file"
            id={`clipara-file-picker-${id}`}
            name="file-picker"
            accept={'image/jpg, image/png, image/jpeg'}
            multiple={false}
            onChange={(e) => {
              const filePicker = document.getElementById(`clipara-file-picker-${id}`)
              console.log(filePicker.files)
              if (filePicker.files[0]) {
                setFiles(Array.from(filePicker.files))
              }
            }}
          />
        </Flex>
      </Flex>
      <SlideDownContainer isActive={isTextAreaActive || comment} alignItems="center" justifyContent="flex-end" my="16px">
        <Text onClick={handleCancel} cursor="pointer" light fontSize="14px" fontWeight="600" mr="40px">
          Cancel
        </Text>
        <Button
          backgroundColor={config.primaryButtonColor}
          fontSize="14px"
          color={config.primaryButtonFontColor}
          isDisabled={!comment || isSending}
          onClick={createComment}
          isLoading={isSending}
          label="Post"
        />
      </SlideDownContainer>
    </Flex>
  )
}

export default WriteComment
