import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { colors, formatSeconds } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import ModalContainer from '../ui/ModalContainer.js'
import { uniq, difference, intersection } from 'ramda'
import Tooltip from '../ui/Tooltip.js'
import ReactTooltip from 'react-tooltip'
import Search from './Search.js'
import { useModal } from '../Modal.js'
import PopoverDropdown from '../ui/PopoverDropdown.js'
import { useContent } from '../ContentProvider.js'
import MealPlanCard from './MealPlanCard.js'
import PostCard from './PostCard.js'

const ImageContainer = styled(Flex)`
  border-radius: 8px 8px 0px 0px;
`

const VideoImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
`

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 22px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  @media (min-width: 1800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 2200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

const Gradient = styled(Flex)`
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 80%, rgba(0, 0, 0, 0.3) 100%);
`

const GradientReverse = styled(Flex)`
  border-radius: 8px 8px 0px 0px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 80%, rgba(0, 0, 0, 0.3) 100%);
`

const ContainerWithHover = styled(Container)`
  box-shadow: none;
  background-color: transparent;
  border: ${(props) => (props.isSelected ? `4px solid rgba(120, 149, 255, 1)` : '4px solid #F5F8FA')};
  a {
    height: 100%;
  }
  // &&:hover {
  //   .hover {
  //     visibility: visible;
  //   }
  // }
  .hover {
    visibility: hidden;
  }
`

export const EmptyState = ({ user }) => {
  return (
    <Flex pt="24px">
      <Container alignItems="center" p="8px" pb="24px">
        <Flex
          mb="24px"
          justifyContent="center"
          alignItems="center"
          bg={colors.beige}
          width="100%"
          height="187px"
          borderRadius="16px"
        >
          <Icon width="50px" height="50px" icon="list-check-black" />
        </Flex>

        <H3 textAlign="center" mb="24px">
          Create your first recipe
        </H3>
        <Text light textAlign="center" mb="24px">
          No time like the present - let’s get started, {user.firstName}
        </Text>
        <Link to="/recipe">
          <Button width="auto" variant="green" label="Get started" />
        </Link>
      </Container>
    </Flex>
  )
}

const PAGESIZE = 200

export const CONTENT_TYPES = {
  RECIPE: 'RECIPE',
  MEAL_PLAN: 'MEAL_PLAN',
  POST: 'POST',
  BOTH: 'BOTH'
}

const Content = (props) => {
  let { user } = useAuth()
  const { recipes, mealPlans, posts, refresh } = useContent()
  let navigate = useNavigate()

  const [page, setPage] = useState(1)

  const [hasLoaded, setHasLoaded] = useState(false)
  const [searchTerm, setSearchTerm] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showPublished, setShowPublishedState] = useState(
    searchParams.get('showPublished') === 'true' || !searchParams.get('showPublished')
  )
  const [selectedTags, setSelectedTags] = useState([])
  const [contentType, setContentType] = useState(CONTENT_TYPES.BOTH)

  const setShowPublished = (showPublished) => {
    setShowPublishedState(showPublished)
    setSearchParams({ showPublished })
  }

  const content = [...recipes, ...mealPlans, ...posts].sort((a, b) =>
    showPublished ? (a.publishedAt > b.publishedAt ? -1 : 1) : a.createdAt > b.createdAt ? -1 : 1
  )

  useEffect(() => {
    const func = async () => {
      await refresh()
      setHasLoaded(true)
    }
    func()
  }, [])

  const contentToShow = content.filter((v) => {
    // First, filter by search term
    if (searchTerm) {
      try {
        if (!(v.name && v.name.toLowerCase().includes(searchTerm.toLowerCase()))) {
          return false
        }
      } catch (e) {
        console.log(e)
        return false
      }
    }

    // Then, filter by published status
    if (!(showPublished ? v.published : !v.published)) {
      return false
    }

    if (contentType === CONTENT_TYPES.RECIPE && v.type !== CONTENT_TYPES.RECIPE) {
      return false
    }

    if (contentType === CONTENT_TYPES.MEAL_PLAN && v.type !== CONTENT_TYPES.MEAL_PLAN) {
      return false
    }

    if (contentType === CONTENT_TYPES.POST && v.type !== CONTENT_TYPES.POST) {
      return false
    }

    // Finally, filter by selected tags
    if (selectedTags.length > 0) {
      const contentTags = v.tags || []
      return selectedTags.some((tag) => contentTags.includes(tag))
    }

    return true
  })

  return (
    <Flex>
      <Flex position="relative" flexDirection="column" width="100%" p="40px">
        <Flex justifyContent="space-between">
          <H1 mb="32px" width="auto">
            Content
          </H1>

          <Box position="absolute" top="16px" right="16px">
            <PopoverDropdown
              width="170px"
              right
              options={[
                { label: 'Recipe', onClick: () => navigate('/recipe') },
                { label: 'Meal Plan', onClick: () => navigate('/meal-plan') },
                { label: 'Post', onClick: () => navigate('/post/editor') }
              ]}
              renderCustomLauncher={() => (
                <Button
                  renderRightIcon={() => <Icon ml="8px" icon="chevron-down-white" width={10} height={10} />}
                  position="absolute"
                  top="0px"
                  right="0px"
                  label="Create"
                  variant="green"
                />
              )}
            />
          </Box>
        </Flex>
        <Search
          showPublished={showPublished}
          setShowPublished={setShowPublished}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setPage={setPage}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          contentType={contentType}
          setContentType={setContentType}
          countPublished={content.filter((v) => v.published).length}
          countDraft={content.filter((v) => !v.published).length}
        />
        {!hasLoaded && !content.length ? (
          <Flex justifyContent="center" pt="200px">
            <BigDarkSpinner />
          </Flex>
        ) : null}
        {hasLoaded ? (
          <>
            <Grid>
              {!content.length ? <EmptyState user={user} /> : null}
              {contentToShow.slice((page - 1) * PAGESIZE, page * PAGESIZE - 1).map((recipe) => {
                if (recipe.type === 'MEAL_PLAN') {
                  return <MealPlanCard {...recipe} />
                }
                if (recipe.type === 'POST') {
                  return <PostCard {...recipe} />
                }
                const image = recipe.coverImageUrl
                  ? `${recipe.coverImageUrl}?width=500`
                  : recipe.playbackId
                  ? `https://image.mux.com/${recipe.playbackId}/thumbnail.jpg?time=0&width=500`
                  : undefined

                let dateToShow = recipe.createdAt
                const isScheduled = recipe.published && !recipe.publishedAt
                if (isScheduled) {
                  dateToShow = recipe.sendAt
                }
                if (recipe.publishedAt) {
                  dateToShow = recipe.publishedAt
                }

                return (
                  <Box key={recipe.id} minWidth="0px">
                    <Link key={recipe.id} to={`/recipe/${recipe.id}`}>
                      <ContainerWithHover
                        mr="30px"
                        p="0px"
                        height="400px"
                        justifyContent="space-between"
                        position="relative"
                        overflowX="hidden"
                      >
                        <Flex
                          flexDirection="column"
                          height="100%"
                          boxShadow={'0px 0px 1px rgba(12, 26, 75, 0.3), 0px 4px 20px -2px rgba(50, 50, 71, 0.24)'}
                        >
                          <Flex cursor="pointer" noverflow="hidden" height="calc(100% - 100px)" position="relative">
                            <ImageContainer>
                              {image ? (
                                <VideoImage loading="lazy" src={image} />
                              ) : (
                                <Flex height="100%" backgroundColor={'#FDF4E8'} />
                              )}
                            </ImageContainer>

                            <Box position="absolute" top="12px" right="12px">
                              <Icon icon="recipe-white" width={24} height={24} />
                            </Box>

                            <Gradient position="absolute" top="0px" left="0px" height="100%" />
                            <Box position="absolute" bottom="8px" right={'8px'}>
                              <Text color="white" fontSize="12px" fontWeight="600">
                                {recipe.duration && formatSeconds(recipe.duration)}
                              </Text>
                            </Box>
                            <Box position="absolute" bottom="8px" left={'8px'}>
                              <Text color="white" fontSize="12px" fontWeight="600">
                                {isScheduled ? 'Scheduled ' : ''} {dayjs(dateToShow).format('DD MMM')}
                              </Text>
                            </Box>
                            <GradientReverse className="hover" position="absolute" top="0px" left="0px" height="100%" />
                          </Flex>
                          <Flex
                            backgroundColor="white"
                            flexDirection="column"
                            py="8px"
                            pb={'16px'}
                            minHeight={'100px'}
                            height={'100px'}
                            justifyContent="space-between"
                          >
                            <H6 wordBreak="break-all" mb="0px" px={'8px'}>
                              {recipe.name && recipe.name.length > 50 ? recipe.name.slice(0, 50) + '...' : recipe.name}
                            </H6>
                            <Flex overflowX="scroll" pl={'8px'} noScrollBar>
                              {(recipe.tags || []).map((tag) => {
                                return (
                                  <Flex
                                    alignItems="center"
                                    backgroundColor={'rgba(225, 232, 255, 1)'}
                                    width="auto"
                                    height="28px"
                                    mr="8px"
                                    borderRadius="14px"
                                    px="16px"
                                    key={tag}
                                    cursor="pointer"
                                  >
                                    <Text whiteSpace="nowrap" mb="2px" light fontWeight="500">
                                      {tag}
                                    </Text>
                                  </Flex>
                                )
                              })}
                            </Flex>
                          </Flex>
                        </Flex>
                      </ContainerWithHover>
                    </Link>
                  </Box>
                )
              })}
            </Grid>
          </>
        ) : null}

        {contentToShow?.length && contentToShow.length > PAGESIZE ? (
          <Flex mt="32px" mb="100px" justifyContent="space-between">
            <Text fontWeight="500" width="300px" light>
              Showing {(page - 1) * PAGESIZE + 1} -{' '}
              {page * PAGESIZE > contentToShow.length ? contentToShow.length : page * PAGESIZE} of {contentToShow.length}
            </Text>
            <Flex cursor="pointer" justifyContent="center">
              {page - 1 !== 0 ? (
                <Text
                  onClick={() => {
                    setPage(page - 1)
                  }}
                  light
                  fontWeight="500"
                  px="4px"
                >
                  {'<'}
                </Text>
              ) : null}
              {Array.from({ length: Math.ceil(contentToShow.length / PAGESIZE) }, (v, i) => i).map((i) => {
                return (
                  <Text
                    onClick={() => {
                      setPage(i + 1)
                    }}
                    light
                    key={i}
                    fontWeight={i === page - 1 ? '700' : '500'}
                    px="4px"
                  >
                    {i + 1}
                  </Text>
                )
              })}
              {page !== Math.ceil(contentToShow.length / PAGESIZE) ? (
                <Text
                  onClick={() => {
                    setPage(page + 1)
                  }}
                  light
                  fontWeight="500"
                  px="4px"
                >
                  {'>'}
                </Text>
              ) : null}
            </Flex>
            <Box width="300px" />
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default Content
