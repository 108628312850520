export const colors = {
  textLight: '#425466',
  textLightLight: '#7A7A9D',
  textDark: '#27272E',
  textGrey: '#8492A6',
  softBlue: '#E1E8FF',
  backgroundGrey: '#F7FAFC',
  darkBackgroundGrey: '#EEF2F7',
  blue: '#4C6FFF',
  blueLight: '#7895FF',
  error: '#ed4337',
  red: '#CF4655',
  darkGreen: '#21201C',
  primary: '#007D5B',
  secondary: 'transparent',
  green: '#007D5B',
  black: '#111111',
  white: 'white',
  weakGreen: 'rgba(224, 254, 219, 1)',
  pink: '#F4ACC4',
  beige: '#FDF4E8'
}

export const fontFamily = `'Inter', sans-serif;`

export const stringPadLeft = (string, pad, length) => {
  return (new Array(length + 1).join(pad) + string).slice(-length)
}

export const getDisplaySize = (maxWidth, maxHeight, aspectRatioCalc) => {
  const isLandscape = aspectRatioCalc > 1
  const displayWidth = Math.round(isLandscape ? maxWidth : maxHeight * aspectRatioCalc)
  const displayHeight = Math.round(isLandscape ? maxWidth / aspectRatioCalc : maxHeight)
  return { isLandscape, displayWidth, displayHeight }
}

export const getDisplaySizeForceLandscapePortrait = (maxWidth, maxHeight, aspectRatioCalc) => {
  const isLandscape = aspectRatioCalc > 1
  const displayWidth = Math.round(isLandscape ? maxWidth : (maxHeight * 9) / 16)
  const displayHeight = Math.round(isLandscape ? (maxWidth * 9) / 16 : maxHeight)
  return { isLandscape, displayWidth, displayHeight }
}

export const shorten = (string, length) =>
  string ? (string.length > length ? string.slice(0, length - 3) + '...' : string) : string

export const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

export const numberWithCommas = (x) => (x ? Number(x).toLocaleString() : x) //.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')

export const formatSeconds = (seconds) => {
  // Hours, minutes and seconds
  var hrs = ~~(seconds / 3600)
  var mins = ~~((seconds % 3600) / 60)
  var secs = ~~seconds % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = ''

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}

export const formatStepTime = (seconds) => {
  // Hours, minutes and seconds
  var mins = ~~((seconds % 3600) / 60)
  var secs = ~~seconds % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = ''

  ret += '' + (mins > 9 ? '' : '0') + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}

export function capitalizeFirstLetter(string) {
  if (!string) {
    return string
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}
