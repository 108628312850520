import * as React from 'react'
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate, useLocation } from 'react-router-dom'
import axios, { AUTH_COOKIE } from './api'
import { useCookies } from 'react-cookie'
import Spinner from './ui/Spinner'
import { Flex, Box } from './ui/Layout'
import { GradientFont } from './ui/Typography'
import { colors } from './ui/helpers'
import { initChat } from './Components/ChatButton'
import * as Sentry from '@sentry/react'
import Image from './ui/Image'

let AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  let [user, setUser] = React.useState()
  let [isCheckingAuth, setIsCheckingAuth] = React.useState(true)
  const [cookies, setCookie, removeCookie] = useCookies([AUTH_COOKIE])
  let navigate = useNavigate()

  const refresh = async () => {
    const res = await axios.get('/auth')
    if (res.data.success) {
      setUser(res.data.payload)
    }
  }

  React.useEffect(() => {
    const asyncFunc = async () => {
      try {
        const res = await axios.get('/auth')
        if (res.data.success) {
          setUser(res.data.payload)
          initChat(res.data.payload)

          try {
            Sentry.setContext('user', res.data.payload)
          } catch (e) {}
        } else {
          console.log('NO SUCCESS')
          removeCookie(AUTH_COOKIE, {
            path: '/'
          })
          setUser()
        }
      } catch (e) {
        try {
          Sentry.captureException(e)
          console.log(e.message)
          if (e && e.message === 'Request failed with status code 401') {
            removeCookie(AUTH_COOKIE, {
              path: '/'
            })
            setUser()
          }
        } catch (ee) {
          console.log('double error')

          Sentry.captureException(ee)
          setUser()
        }
      }
      setIsCheckingAuth(false)
    }
    if (cookies[AUTH_COOKIE] && !user) {
      asyncFunc()
    } else {
      setIsCheckingAuth(false)
    }
  }, [])

  let value = { user, setUser, refresh }
  if (isCheckingAuth) {
    return (
      <Flex width="100vw" height="100vh" bg={colors.darkGreen} justifyContent="center" alignItems="center" flexDirection="column">
        <Image src="/logoWhite.svg" width="200px" mb="32px" />
        <Spinner width="50px" height="50px" />
      </Flex>
    )
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return React.useContext(AuthContext)
}

export const RequireAuth = ({ children }) => {
  let auth = useAuth()
  let location = useLocation()
  console.log({ auth })
  if (!auth || !auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}
