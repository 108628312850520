import { Formik, Field } from 'formik'
import { Flex } from '../ui/Layout'
import ModalContainer from '../ui/ModalContainer'
import formikFormWrapper from '../formikFormWrapper.js'
import { Input } from '../ui/Input'
import { useState } from 'react'
import Button from '../ui/Button'
import api from '../api'
import { Error, Text } from '../ui/Typography'

const FormInput = formikFormWrapper(Input)

const CreateSubscriberModal = ({ isOpen, setIsOpen, onClose }) => {
  const [error, setError] = useState('')
  return (
    <ModalContainer autoHeight interiorClose width="700px" p={0} isOpen={isOpen} setIsOpen={setIsOpen}>
      <Flex flexDirection="column" alignItems="center" p="24px">
        <Formik
          initialValues={{}}
          validate={(values) => {
            const errors = {}
            if (!values.email) {
              errors.email = 'Required'
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = 'Invalid email address'
            }
            if (!values.password) {
              errors.password = 'Required'
            } else if (values.password.length < 8) {
              errors.password = 'Must be 8 characters or more'
            } else if (values.password.length > 70) {
              errors.password = 'Must be less than 70 characters'
            }
            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setError('')
            setSubmitting(true)
            const res = await api.post('/customers/create-test', values)
            if (res.data.success) {
              onClose()
              setIsOpen(false)
            } else {
              setError(res.data.message)
            }
            setSubmitting(false)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
            /* and other goodies */
          }) => (
            <Flex flexDirection="column">
              <Text fontSize="18px" fontWeight="600">
                Create a Subscriber
              </Text>
              <Text light fontSize="14px" fontWeight="400" mb="32px">
                Subscribers created this way will have full access to your site.
              </Text>
              <Flex mb="24px">
                <Field
                  name="firstName"
                  placeholder="Enter your name"
                  label="First Name"
                  large
                  type="text"
                  component={FormInput}
                  mr="28px"
                />
                <Field
                  name="lastName"
                  large
                  placeholder="Enter your last name"
                  label="Last Name"
                  type="text"
                  component={FormInput}
                />
              </Flex>
              <Field
                name="email"
                label="Email"
                large
                mb="24px"
                placeholder="Type your email address"
                type="email"
                component={FormInput}
              />
              <Field
                name="password"
                label="Password"
                large
                mb="24px"
                placeholder="Type your password"
                type="password"
                component={FormInput}
              />
              <Flex justifyContent="flex-end">
                <Button
                  large
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  variant="green"
                  label="Create"
                  onClick={handleSubmit}
                />
              </Flex>
              {error ? (
                <Error textAlign="right" width="100%" mb="8px">
                  {error}
                </Error>
              ) : null}
            </Flex>
          )}
        </Formik>
      </Flex>
    </ModalContainer>
  )
}

export default CreateSubscriberModal
