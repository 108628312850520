import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate, useLocation } from 'react-router-dom'
import axios from './api.js'
import { useCookies } from 'react-cookie'
import Spinner from './ui/Spinner.js'
import { Flex, Box } from './ui/Layout.js'
import { GradientFont } from './ui/Typography.js'
import { colors } from './ui/helpers.js'
import api from './api.js'
import { useAuth } from './Auth.js'

let ContentContext = React.createContext()

export const ContentProvider = ({ children }) => {
  const [recipes, setRecipesState] = useState([])
  const [mealPlans, setMealPlansState] = useState([])
  const [posts, setPostsState] = useState([])
  let { user, setUser } = useAuth()

  const refresh = async (count) => {
    const res = await api.get('/content/get')
    if (res.data.success) {
      setRecipesState(res.data.payload.recipes)
      setMealPlansState(res.data.payload.mealPlans)
      setPostsState(res.data.payload.posts)
    }
  }

  useEffect(() => {
    if (user?.organisationId) {
      refresh()
    }
  }, [user])

  const props = { recipes, mealPlans, posts, refresh }
  return <ContentContext.Provider value={props}>{children}</ContentContext.Provider>
}

export const useContent = () => {
  return React.useContext(ContentContext)
}
