import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, LineClamp, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import { Input, TextArea } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { useModal } from '../Modal.js'
const edjsHTML = require('editorjs-html')
const edjsParser = edjsHTML()
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import EditorLink from '@editorjs/link'
import EditorImage from '@editorjs/image'
import Paragraph from '@editorjs/paragraph'
import DragDrop from 'editorjs-drag-drop'
import AnyButton from 'editorjs-button'
import { Formik, Field } from 'formik'
import { useTheme } from '../ThemeProvider.js'
import ImageUploadModal, { getHeightAndWidthFromDataUrl } from '../ui/ImageUploadModal.js'
import formikFormWrapper from '../formikFormWrapper.js'
import TagsModal from '../ui/TagsModal.js'
import CustomButton, { CustomButtonStyles } from './CustomButton.js'
import SlideUpModal from '../Components/SlideUpModal.js'
import PreviewModal from './PreviewModal.js'
import initEditor from './editorConfig.js'
import CircleToggle from '../ui/CircleToggle.js'
import DateTimePicker from '../ui/DateTimePicker.js'
import { motion, AnimatePresence } from 'framer-motion'
import { debounce } from 'lodash' // Add this import
import HorizontalStepper from './HorizontalStepper.js'

const FormInput = formikFormWrapper(Input)
const FormTextArea = formikFormWrapper(TextArea)

const Posts = (props) => {
  let { user } = useAuth()
  const navigate = useNavigate()

  let { postId } = useParams()
  const ejInstance = useRef()
  const { theme } = useTheme()
  const [inputPost, setInputPost] = useState()

  const [isLoading, setIsLoading] = useState(true)
  const [thumbnailImageUploadOpen, setThumbnailImageUploadOpen] = useState(false)

  const [showTagsModal, setShowTagsModal] = useState(false)

  const [previewModalOpen, setPreviewModalOpen] = useState(false)

  const refresh = async () => {
    const res = await api.get(`/content/get-post?id=${postId}`)
    setInputPost(res.data.payload)
    setIsLoading(false)
  }

  useEffect(() => {
    if (postId) {
      refresh()
    } else {
      setIsLoading(false)
    }
  }, [])

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center" flexDirection="column" backgroundColor="white">
      {isLoading ? (
        <Flex height="500px" justifyContent="center" alignItems="center">
          <BigDarkSpinner />
        </Flex>
      ) : (
        <Formik
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={inputPost ? inputPost : {}}
          validate={(values) => {
            let errors = {}
            return errors
          }}
          onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
            setSubmitting(true)
            const res = await api.post('/content/post-update', values)
            resetForm({ values })
            setSubmitting(false)
          }}
        >
          {({
            values,
            dirty,
            touched,
            resetForm,
            setSubmitting,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            validateForm,
            errors
          }) => {
            const publishPost = async () => {
              setSubmitting(true)
              const res = await api.post('/content/publish-post', {
                postId
              })
              navigate(`/post/${postId}`)
              setSubmitting(false)
            }

            return (
              <>
                <Flex
                  minHeight="65px"
                  height="65px"
                  alignItems="center"
                  justifyContent="space-between"
                  backgroundColor="white"
                  borderBottom="2px solid #EEF2F7"
                  px="24px"
                  position="fixed"
                  top="0px"
                  zIndex={100}
                >
                  <Link to={values.published ? `/post/${postId}` : `/post/schedule/${postId}`}>
                    <Flex cursor="pointer" width="auto">
                      <Icon mt="4px" mr="13px" width="18px" height="18px" icon="chevron-left-grey" />
                      <Text lightLight fontSize="14px">
                        Back
                      </Text>
                    </Flex>
                  </Link>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    top="20px"
                    left="calc(50% - 100px)"
                    width="200px"
                  >
                    <Box
                      width="8px"
                      height="8px"
                      mr="8px"
                      borderRadius="8px"
                      backgroundColor={values.published ? 'rgba(102, 203, 159, 1)' : 'rgba(247, 147, 111, 1)'}
                    />
                    <Text light fontWeight="500">
                      {values.published ? 'Published' : 'Draft'}
                    </Text>
                  </Flex>
                  <Flex width="auto">
                    <HorizontalStepper postId={postId} isValid />
                    <Flex width="auto" alignItems="center">
                      {errors && Object.keys(errors).length > 0 ? <Error mr="16px">Please check form errors</Error> : null}
                    </Flex>
                    <Link to={`/post/settings/${postId}`}>
                      <Button variant={'secondary'} label="Back" mr="8px" />
                    </Link>
                    <Button isSubmitting={isSubmitting} variant={'green'} label="Publish" onClick={publishPost} />
                  </Flex>
                </Flex>
                <Flex
                  mt="65px"
                  height="calc(100vh - 65px)"
                  width="100%"
                  alignItems="flex-start"
                  justifyContent="center"
                  bg="#f7fafc"
                  pt="24px"
                >
                  <Flex width="640px" flexDirection="column">
                    <Text fontSize="20px" fontWeight="700" lineHeight="136%" mb="16px">
                      Review and publish
                    </Text>
                    <Flex
                      width="640px"
                      flexDirection="column"
                      bg="white"
                      borderRadius="16px"
                      p="24px"
                      mb="24px"
                      boxShadow="0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)"
                      position="relative"
                    >
                      <Link to={`/post/editor/${postId}`}>
                        <Box position="absolute" bottom="24px" right="24px">
                          <Icon icon="edit-grey" width="16px" height="16px" />
                        </Box>
                      </Link>
                      <Text fontSize="18px" fontWeight="600" lineHeight="normal" mb="24px">
                        Post
                      </Text>
                      <Text mb="4px" fontWeight="700">
                        Title
                      </Text>
                      <Text light fontSize="16px">
                        {values.title}
                      </Text>
                    </Flex>
                    <Flex
                      mb="24px"
                      width="640px"
                      flexDirection="column"
                      bg="white"
                      borderRadius="16px"
                      p="24px"
                      boxShadow="0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)"
                      position="relative"
                    >
                      <Link to={`/post/schedule/${postId}`}>
                        <Box position="absolute" bottom="24px" right="24px">
                          <Icon icon="edit-grey" width="18px" height="18px" />
                        </Box>
                      </Link>
                      <Text fontSize="18px" fontWeight="600" lineHeight="normal" mb="24px">
                        Publishing
                      </Text>
                      <Text mb="4px" fontWeight="700">
                        Available on
                      </Text>
                      <Text light fontSize="16px" mb="32px">
                        {values.publishTo === 'WEB' ? 'Website' : values.publishTo === 'EMAIL' ? 'Email' : 'Email and web'}
                      </Text>
                      <Text mb="4px" fontWeight="700">
                        Post is
                      </Text>
                      <Text light fontSize="16px">
                        {values.contentFor === 'PAID' ? 'Paid' : 'Free'}
                      </Text>
                    </Flex>
                    {values.publishTo !== 'WEB' ? (
                      <Flex
                        mb="24px"
                        width="640px"
                        flexDirection="column"
                        bg="white"
                        borderRadius="16px"
                        p="24px"
                        boxShadow="0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)"
                        position="relative"
                      >
                        <Link to={`/post/settings/${postId}`}>
                          <Box position="absolute" bottom="24px" right="24px">
                            <Icon icon="edit-grey" width="18px" height="18px" />
                          </Box>
                        </Link>
                        <Text fontSize="18px" fontWeight="600" lineHeight="normal" mb="24px">
                          Email
                        </Text>
                        <Text mb="4px" fontWeight="700">
                          Sending to
                        </Text>
                        <Text light fontSize="16px" mb="32px">
                          {values.sendTo === 'PAID'
                            ? 'Paid subscribers'
                            : values.sendTo === 'EVERYONE'
                            ? 'Paid subscribers and free subscribers'
                            : values.sendTo === 'FREE'
                            ? 'Free subscribers'
                            : values.publishTo === 'WEB'
                            ? "Don't notify"
                            : ''}
                        </Text>
                        <Text mb="4px" fontWeight="700">
                          Subject line
                        </Text>
                        <Text light fontSize="16px">
                          {values.emailSubject || values.title}
                        </Text>
                      </Flex>
                    ) : null}
                  </Flex>
                </Flex>
              </>
            )
          }}
        </Formik>
      )}
    </Flex>
  )
}

export default Posts
