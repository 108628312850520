import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { colors } from './helpers'
import { Text, Error } from './Typography'
import { Flex, Box } from './Layout'
import Icon from './Icon'
import { toast } from 'react-toastify'
import copy from 'copy-to-clipboard'

export const selectText = (id) => {
  var sel, range
  var el = document.getElementById(id) //get element id
  if (window.getSelection && document.createRange) {
    //Browser compatibility
    sel = window.getSelection()
    if (sel.toString() == '') {
      //no text selection
      window.setTimeout(function () {
        range = document.createRange() //range object
        range.selectNodeContents(el) //sets Range
        sel.removeAllRanges() //remove all ranges from selection
        sel.addRange(range) //add Range to a Selection.
      }, 1)
    }
  } else if (document.selection) {
    //older ie
    sel = document.selection.createRange()
    if (sel.text == '') {
      //no text selection
      range = document.body.createTextRange() //Creates TextRange object
      range.moveToElementText(el) //sets Range
      range.select() //make selection.
    }
  }
}

const WhiteSpaceText = styled(Text)`
  white-space: pre-wrap;
  word-break: break-all;
`
export const copyOnClick = ({ id, code, toastMessage }) => {
  selectText(id)
  copy(code)
}

export default ({ code, id = 'code-snippet', small, width }) => {
  return (
    <Flex
      cursor="pointer"
      position="relative"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        copyOnClick({ id, code })
      }}
      width={width || '600px'}
      bg="#FBFBFB"
      p={small ? '12px' : '24px'}
      borderRadius="8px"
    >
      <Box position="absolute" top="12px" right="16px">
        <Icon onClick={copyOnClick} width={16} height={16} icon="copy-grey" />
      </Box>
      <WhiteSpaceText id={id}>{code}</WhiteSpaceText>
    </Flex>
  )
}
