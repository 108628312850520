import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { colors } from './helpers'
import { Label, Error } from './Typography'
import { Flex, Box } from './Layout'
import { HexColorPicker } from 'react-colorful'
import {
  space,
  width,
  color,
  flex,
  order,
  fontSize,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  borders,
  minWidth,
  minHeight,
  borderRadius,
  size,
  height,
  maxWidth,
  textAlign,
  boxShadow,
  borderColor,
  position,
  display,
  top,
  bottom,
  left,
  right,
  zIndex
} from 'styled-system'
import { Formik, Field } from 'formik'
import formikFormWrapper from '../formikFormWrapper.js'
import { Input, SideInput } from './Input.js'

const FormInput = formikFormWrapper(Input)

const ColorDisplay = styled(Box)`
  width: 44px;
  height: 44px;
  background-color: ${(props) => props.color};
  border-radius: 8px;
  border: 1px solid #a6b7d4;
  cursor: pointer;
`

const ColorPickerContainer = styled(Box)`
  position: absolute;
  right: 17px;
  bottom: 25px;
  z-index: 100;
`

const useClickOutside = (ref, handler) => {
  useEffect(() => {
    let startedInside = false
    let startedWhenMounted = false

    const listener = (event) => {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside || !startedWhenMounted) return
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return

      handler(event)
    }

    const validateEventStart = (event) => {
      startedWhenMounted = ref.current
      startedInside = ref.current && ref.current.contains(event.target)
    }

    document.addEventListener('mousedown', validateEventStart)
    document.addEventListener('touchstart', validateEventStart)
    document.addEventListener('click', listener)

    return () => {
      document.removeEventListener('mousedown', validateEventStart)
      document.removeEventListener('touchstart', validateEventStart)
      document.removeEventListener('click', listener)
    }
  }, [ref, handler])
}

const ColorPicker = ({ onChange, color, ...rest }) => {
  const popover = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const close = useCallback(() => setIsOpen(false), [])
  useClickOutside(popover, close)
  return (
    <Box position="relative">
      <ColorDisplay onClick={() => setIsOpen(!isOpen)} color={color} {...rest} />
      {isOpen ? (
        <ColorPickerContainer ref={popover}>
          <HexColorPicker color={color} onChange={onChange} />
        </ColorPickerContainer>
      ) : null}
    </Box>
  )
}

export default ColorPicker

export const ColorPickerElement = (props) => {
  return (
    <Flex flexDirection="column" flex>
      <Label mb="8px">{props.label}</Label>
      <Flex width="auto" alignItems="center">
        <ColorPicker
          mr="12px"
          color={props.value || '#333333'}
          onChange={(selected) => props.setFieldValue(props.name, selected)}
        />
        <Field large name={props.name} placeholder="#000000" dark component={FormInput} />
      </Flex>
    </Flex>
  )
}
