import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useParams, Navigate } from 'react-router-dom'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, LineClamp, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import { Input, TextArea } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { useModal } from '../Modal.js'
const edjsHTML = require('editorjs-html')
const edjsParser = edjsHTML()
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import EditorLink from '@editorjs/link'
import EditorImage from '@editorjs/image'
import Paragraph from '@editorjs/paragraph'
import DragDrop from 'editorjs-drag-drop'
import AnyButton from 'editorjs-button'
import { Formik, Field } from 'formik'
import { useTheme } from '../ThemeProvider.js'
import ImageUploadModal, { getHeightAndWidthFromDataUrl } from '../ui/ImageUploadModal.js'
import formikFormWrapper from '../formikFormWrapper.js'
import TagsModal from '../ui/TagsModal.js'
import CustomButton, { CustomButtonStyles } from './CustomButton.js'
import SlideUpModal from '../Components/SlideUpModal.js'
import PreviewModal from './PreviewModal.js'
import initEditor from './editorConfig.js'
import CircleToggle from '../ui/CircleToggle.js'
import DateTimePicker from '../ui/DateTimePicker.js'
import { motion, AnimatePresence } from 'framer-motion'
import { debounce } from 'lodash'
import { EditorJSStyles } from './Editor.js'
import dayjs from 'dayjs'
import { colors } from '../ui/helpers.js'
import Popover from '../Components/ui/Popover.js'
import { useContent } from '../ContentProvider.js'

const EditorStyler = styled(Flex)`
  .ce-block__content {
    max-width: 100%;
  }
`

const FormInput = formikFormWrapper(Input)
const FormTextArea = formikFormWrapper(TextArea)

const Posts = (props) => {
  let { user } = useAuth()
  const navigate = useNavigate()
  const { refresh: contentRefresh } = useContent()

  let { postId } = useParams()
  const ejInstance = useRef()
  const { theme } = useTheme()
  const [inputPost, setInputPost] = useState()

  const [isLoading, setIsLoading] = useState(true)
  const [thumbnailImageUploadOpen, setThumbnailImageUploadOpen] = useState(false)

  const [showTagsModal, setShowTagsModal] = useState(false)

  const [stats, setStats] = useState(null)

  const [previewModalOpen, setPreviewModalOpen] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const refresh = async () => {
    const res = await api.get(`/content/get-post?id=${postId}`)
    setInputPost(res.data.payload)
    setIsLoading(false)
  }

  useEffect(() => {
    if (postId) {
      refresh()
    } else {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    const getStats = async () => {
      const res = await api.get(`/notifications/get-post-stats?postId=${postId}`)
      setStats(res.data.payload)
    }
    getStats()
  }, [])

  console.log({ stats })

  useEffect(() => {
    if (inputPost && inputPost.id && !inputPost.published) {
      return navigate(`/post/editor/${inputPost.id}`)
    }
  }, [inputPost && inputPost.id])

  const deletePost = async () => {
    await api.post('/content/delete-post', { id: postId })
    await contentRefresh()
    navigate('/content')
  }

  const unpublishPost = async () => {
    await api.post('/content/unpublish-post', { id: postId })
    await refresh()
    await contentRefresh()
    navigate(`/post/editor/${postId}`)
  }

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center" flexDirection="column" backgroundColor="white">
      {isLoading ? (
        <Flex height="500px" justifyContent="center" alignItems="center">
          <BigDarkSpinner />
        </Flex>
      ) : (
        <Formik
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={inputPost ? inputPost : {}}
          validate={(values) => {
            let errors = {}
            return errors
          }}
          onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
            setSubmitting(true)
            const res = await api.post('/content/post-update', values)
            resetForm({ values })
            setSubmitting(false)
          }}
        >
          {({
            values,
            dirty,
            touched,
            resetForm,
            setSubmitting,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            validateForm,
            errors
          }) => {
            useEffect(() => {
              if (!ejInstance.current) {
                ejInstance.current = initEditor({
                  holder: 'editorjs',
                  content: values.content,
                  onReady: () => {
                    new DragDrop(ejInstance.current)
                  },
                  onChange: async (content) => {
                    console.log('content', content)
                    setFieldValue('content', content)
                    const edjsParser = edjsHTML()
                    console.log(edjsParser.parse(content))
                  },
                  readOnly: true
                })
              }

              return () => {
                ejInstance?.current?.destroy()
                ejInstance.current = null
              }
            }, [])

            const isScheduled = values.published && dayjs(values.sendAt).isAfter(dayjs()) && !values.publishedAt

            return (
              <>
                <Flex
                  minHeight="65px"
                  height="65px"
                  maxHeight="65px"
                  alignItems="center"
                  justifyContent="space-between"
                  backgroundColor="white"
                  borderBottom="2px solid #EEF2F7"
                  px="24px"
                  position="fixed"
                  top="0px"
                  zIndex={100}
                >
                  <Link to={`/content?showPublished=${values.published}`}>
                    <Flex cursor="pointer" width="auto">
                      <Icon mt="4px" mr="13px" width="16px" height="16px" icon="chevron-left-grey" />
                      <Text lightLight fontSize="14px">
                        Back
                      </Text>
                    </Flex>
                  </Link>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    top="20px"
                    left="calc(50% - 100px)"
                    width="200px"
                  >
                    <Box
                      width="8px"
                      height="8px"
                      mr="8px"
                      borderRadius="8px"
                      backgroundColor={values.published ? 'rgba(102, 203, 159, 1)' : 'rgba(247, 147, 111, 1)'}
                    />
                    <Text light fontWeight="500">
                      {values.published ? 'Published' : 'Draft'}
                    </Text>
                  </Flex>
                  <Flex width="auto">
                    {values.publishTo !== 'EMAIL' ? (
                      <Button
                        renderLeftIcon={() => <Icon icon="box-arrow-white" width="12px" height="12px" />}
                        variant={'green'}
                        label="Open"
                        onClick={() => {
                          window.open(
                            process.env.NODE_ENV === 'development'
                              ? `http://localhost:3000/post/${values.id}?isPreview=true`
                              : `https://${user.subdomain}.theclubb.co/post/${values.id}?isPreview=true`,
                            '_blank'
                          )
                        }}
                      />
                    ) : null}
                  </Flex>
                </Flex>
                <Flex width="100%" alignItems="flex-start" justifyContent="center" bg="#f7fafc" pt="24px" px="30px" mt="65px">
                  <Flex
                    mr="20px"
                    minHeight="calc(100vh - 65px - 24px - 24px)"
                    width="100%"
                    flexDirection="column"
                    bg="white"
                    borderRadius="16px"
                    p="32px"
                    boxShadow="0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)"
                  >
                    <Text fontSize="36px" fontWeight="700" lineHeight="136%" mb="10px">
                      {values.title}
                    </Text>
                    <Text light fontSize="18px" lineHeight="30px" mb="16px">
                      {values.subtitle}
                    </Text>
                    <Text light>By {values.author}</Text>
                    <Flex mt="40px" mb="40px" borderBottom="1px solid #EDF2F7" />

                    <EditorStyler>
                      <EditorJSStyles
                        position="relative"
                        primaryButtonColor={theme.primaryButtonColor}
                        primaryButtonTextColor={theme.primaryButtonTextColor}
                        width="100%"
                        height="100%"
                        id="editorjs"
                      ></EditorJSStyles>
                    </EditorStyler>
                  </Flex>

                  <Flex
                    // position="sticky"
                    // top="89px"
                    width="600px"
                    minWidth="600px"
                    minHeight="calc(100vh - 65px - 24px - 24px)"
                    flexDirection="column"
                    bg="white"
                    borderRadius="16px"
                    p="24px"
                    boxShadow="0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)"
                  >
                    <Text fontSize="20px" fontWeight="600" lineHeight="normal" mb="16px">
                      Details
                    </Text>
                    <Text>Last edited: {dayjs(values.updatedAt).format('MMM D, YYYY h:mm A')}</Text>
                    {!isScheduled ? (
                      <Text mb="16px">
                        Published to{' '}
                        {values.publishTo === 'EMAIL_WEB' ? (
                          <>
                            <Span fontWeight="600" color={colors.green}>
                              Email
                            </Span>{' '}
                            and{' '}
                            <Span fontWeight="600" color={colors.green}>
                              Website
                            </Span>
                          </>
                        ) : values.publishTo === 'EMAIL' ? (
                          <Span fontWeight="600" color={colors.green}>
                            Email
                          </Span>
                        ) : (
                          <Span fontWeight="600" color={colors.green}>
                            Website
                          </Span>
                        )}
                      </Text>
                    ) : (
                      <Text mb="16px" color="#D17757">
                        Scheduled for {dayjs(values.sendAt).format('MMMM D, YYYY h:mm A')}
                      </Text>
                    )}
                    <Flex alignItems="center">
                      <Link to={`/post/editor/${postId}`}>
                        <Button
                          renderLeftIcon={() => <Icon icon="pencil-square-green" width="12px" height="12px" />}
                          variant="secondary"
                          label="Edit"
                          mr="8px"
                        />
                      </Link>
                      {isScheduled ? (
                        <>
                          <Button
                            variant="secondary"
                            label="Amend schedule"
                            mr="8px"
                            onClick={async () => {
                              await api.post('/content/cancel-scheduled-post', {
                                contentId: postId,
                                type: 'POST'
                              })
                              navigate(`/post/schedule/${postId}`)
                            }}
                          />
                          <Button
                            variant="secondary"
                            label="Cancel"
                            onClick={async () => {
                              await api.post('/content/cancel-scheduled-post', {
                                contentId: postId,
                                type: 'POST'
                              })
                              navigate(`/post/editor/${postId}`)
                            }}
                          />
                        </>
                      ) : null}
                      <Popover
                        position="left"
                        isOpen={isPopoverOpen}
                        onClose={() => setIsPopoverOpen(false)}
                        content={
                          <Flex flexDirection="column" p="16px" width="180px">
                            <Flex
                              mb="24px"
                              alignItems="center"
                              cursor="pointer"
                              onClick={async () => {
                                await unpublishPost()
                                setIsPopoverOpen(false)
                              }}
                            >
                              <Icon icon="arrow-counterclockwise-red" mr="16px" width="16px" height="16px" />
                              <Text fontWeight="500" color={colors.red}>
                                Unpublish
                              </Text>
                            </Flex>
                            <Flex
                              alignItems="center"
                              cursor="pointer"
                              onClick={async () => {
                                await deletePost()
                                setIsPopoverOpen(false)
                              }}
                            >
                              <Icon icon="bin-red" mr="16px" width="16px" height="16px" />
                              <Text fontWeight="500" color={colors.red}>
                                Delete
                              </Text>
                            </Flex>
                          </Flex>
                        }
                      >
                        <Flex width="32px" height="32px" alignItems="center" justifyContent="center">
                          <Icon
                            icon="horizontal-three-dots-grey"
                            width="16px"
                            height="16px"
                            cursor="pointer"
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                          />
                        </Flex>
                      </Popover>
                    </Flex>

                    {!isScheduled && stats && values.publishTo !== 'WEB' ? (
                      <>
                        <Text fontSize="18px" fontWeight="600" lineHeight="normal" mt="40px" mb="16px">
                          Performance
                        </Text>
                        <Flex
                          flexDirection="column"
                          borderRadius="16px"
                          p="16px 20px"
                          height="100px"
                          border="1px solid #EDF2F7"
                          justifyContent="center"
                          mb="16px"
                        >
                          <Text light lineHeight="24px" mb="8px">
                            Sends
                          </Text>
                          <Text fontSize="18px" fontWeight="600" lineHeight="normal">
                            {stats.sends}
                          </Text>
                        </Flex>
                        <Flex
                          flexDirection="column"
                          borderRadius="16px"
                          p="16px 20px"
                          height="100px"
                          border="1px solid #EDF2F7"
                          justifyContent="center"
                          mb="16px"
                        >
                          <Text light lineHeight="24px" mb="8px">
                            Open Rate
                          </Text>
                          <Text fontSize="18px" fontWeight="600" lineHeight="normal">
                            {stats.opens > 0 ? Math.round((stats.opens / stats.sends) * 100) : 0}%
                          </Text>
                        </Flex>
                        <Flex
                          flexDirection="column"
                          borderRadius="16px"
                          p="16px 20px"
                          height="100px"
                          border="1px solid #EDF2F7"
                          justifyContent="center"
                        >
                          <Text light lineHeight="24px" mb="8px">
                            Clicks
                          </Text>
                          <Text fontSize="18px" fontWeight="600" lineHeight="normal">
                            {stats.clicks > 0 ? Math.round((stats.clicks / stats.sends) * 100) : 0}%
                          </Text>
                        </Flex>
                      </>
                    ) : null}
                  </Flex>
                </Flex>
              </>
            )
          }}
        </Formik>
      )}
    </Flex>
  )
}

export default Posts
