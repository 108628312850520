import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, shorten } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'

const ModalContainerComponent = styled(Flex)`
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor || 'rgba(0, 0, 0, 0.2)'};
  z-index: 1400001;
`
const ModalContainer = ({
  isOpen,
  setIsOpen,
  width,
  height,
  p,
  children,
  interiorClose,
  overflowY,
  onClose,
  autoHeight,
  backgroundColor
}) => {
  if (!isOpen) {
    return null
  }
  const close = () => {
    setIsOpen(false)
    onClose && onClose()
  }
  return (
    <ModalContainerComponent backgroundColor={backgroundColor} p={p} onClick={close}>
      {!interiorClose ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          bg="white"
          boxShadow="0px 4px 20px -2px rgba(50, 50, 71, 0.08);"
          width="40px"
          height="40px"
          borderRadius="20px"
          position="absolute"
          top="16px"
          right="16px"
          zIndex="1"
          cursor="pointer"
          onClick={close}
        >
          <Icon width="14px" height="14px" icon="close-cross-grey" />
        </Flex>
      ) : null}
      <Container
        onClick={(e) => e.stopPropagation()}
        alignItems="center"
        width="100%"
        maxWidth={width || '900px'}
        height={autoHeight ? 'auto' : '100%'}
        maxHeight={height || '800px'}
        overflowY={overflowY || 'scroll'}
        position="relative"
        p={p}
      >
        {interiorClose ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            position="absolute"
            width="auto"
            top="24px"
            right="24px"
            zIndex="10"
            cursor="pointer"
            onClick={close}
          >
            <Icon width="14px" height="14px" icon="close-cross-grey" />
          </Flex>
        ) : null}
        {children}
      </Container>
    </ModalContainerComponent>
  )
}

export default ModalContainer
