import React from 'react'
import { colors } from '../ui/helpers'

export const CustomButtonStyles = `.custom-button-wrapper {
  display: inline-block;
}

.custom-button {
  position: relative;
  display: inline-block;
}

.custom-button-popover {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  display: none;
  margin-bottom: 5px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 1000;
}

.custom-button-url-input {
  width: 100%;
  padding: 5px;
  border: none;
  background: transparent;
}

.custom-button-wrapper {
  width: 100%;
  margin-bottom: 16px;  
  margin-top: 16px;
}

.custom-button__link {
  display: inline-block;
  padding: 12px 20px;
  background-color: ${colors.green};
  border-radius: 40px;
  color: white;
  text-decoration: none;
  min-width: 100px;
  text-align: center;
  font-weight: 700; 
}

.custom-button__link:empty:before {
  content: attr(placeholder);
  color: rgba(255, 255, 255, 0.7);
}

.custom-button__link:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}

.custom-button-url-input {
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.custom-button {
  display: inline-block;
  flex-direction: column;
  align-items: center;
}

.custom-button-url-container {
  display: none;
  margin-top: 5px;
}

.custom-button-url-label {
    margin-top: 16px;
  display: block;
  margin-bottom: 0px;
  font-size: 12px;
  color: #666;
}

/* Removed hover-based display rule */

.custom-button-settings {
  display: flex;
  align-items: center;
}

.custom-button-settings__label {
  padding-left: 8px;
  font-size: 14px;
  color: black;
  line-height: 20px;
  font-weight: 700;
  margin-right: 10px;
}

.invalid-url {
  border-color: red !important;
}
`

class CustomButton {
  constructor({ data, api, config, readOnly }) {
    this.api = api
    this.data = {
      label: data.label || '',
      alignment: data.alignment || 'left',
      url: data.url || ''
    }
    this.wrapper = undefined
    this.readOnly = readOnly
  }

  static get toolbox() {
    return {
      title: 'Custom Button',
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
    }
  }

  static get isReadOnlySupported() {
    return true
  }

  render() {
    this.wrapper = document.createElement('div')
    this.wrapper.classList.add('custom-button-wrapper')

    const buttonContainer = document.createElement('div')
    buttonContainer.classList.add('custom-button')

    const button = document.createElement('a')
    button.contentEditable = !this.readOnly
    button.classList.add('custom-button__link')
    button.innerHTML = this.data.label || ''
    button.placeholder = 'Type button label...'
    button.href = this.data.url
    button.target = '_blank' // Add this line
    button.rel = 'noopener noreferrer' // Add this line for security

    const urlContainer = document.createElement('div')
    urlContainer.classList.add('custom-button-url-container')

    const urlLabel = document.createElement('label')
    urlLabel.textContent = 'Button URL:'
    urlLabel.classList.add('custom-button-url-label')

    const urlInput = document.createElement('input')
    urlInput.type = 'text'
    urlInput.placeholder = 'Enter button URL'
    urlInput.value = this.data.url || ''
    urlInput.classList.add('custom-button-url-input')
    urlInput.disabled = this.readOnly

    urlInput.addEventListener('input', (event) => {
      this._updateUrl(event.target.value)
    })

    urlInput.addEventListener('blur', (event) => {
      this._validateUrl(event.target.value)
    })

    urlContainer.appendChild(urlLabel)
    urlContainer.appendChild(urlInput)

    buttonContainer.appendChild(button)
    buttonContainer.appendChild(urlContainer)
    this.wrapper.appendChild(buttonContainer)

    // Replace focus and blur events with conditional checks
    if (!this.readOnly) {
      button.addEventListener('focus', () => {
        urlContainer.style.display = 'block'
      })

      button.addEventListener('blur', (event) => {
        // Delay hiding the URL container to allow clicking on it
        setTimeout(() => {
          if (!urlContainer.contains(document.activeElement)) {
            urlContainer.style.display = 'none'
          }
        }, 0)
      })
    }

    this.wrapper.style.textAlign = this.data.alignment
    return this.wrapper
  }

  _showPopover() {
    this.popover.style.display = 'block'
  }

  _hidePopover() {
    this.popover.style.display = 'none'
  }

  _handleMouseLeave(event) {
    // Check if the mouse is moving to the popover
    if (!this.popover.contains(event.relatedTarget)) {
      this._hidePopover()
    }
  }

  _handleClickOutside(event) {
    if (!this.wrapper.contains(event.target)) {
      this._hidePopover()
    }
  }

  renderSettings() {
    if (this.readOnly) {
      return null
    }

    const wrapper = document.createElement('div')
    wrapper.classList.add('custom-button-settings')

    const label = document.createElement('span')
    label.classList.add('custom-button-settings__label')
    label.textContent = 'Alignment:'
    wrapper.appendChild(label)

    const alignments = [
      {
        name: 'left',
        icon: `<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 3.50024C2.25 3.2241 2.47386 3.00024 2.75 3.00024H13.75C14.0261 3.00024 14.25 3.2241 14.25 3.50024C14.25 3.77639 14.0261 4.00024 13.75 4.00024H2.75C2.47386 4.00024 2.25 3.77639 2.25 3.50024ZM2.89645 5.64669C3.09171 5.45143 3.40829 5.45143 3.60355 5.64669L5.60355 7.64669C5.79882 7.84195 5.79882 8.15854 5.60355 8.3538L3.60355 10.3538C3.40829 10.5491 3.09171 10.5491 2.89645 10.3538C2.70118 10.1585 2.70118 9.84195 2.89645 9.64669L4.54289 8.00024L2.89645 6.3538C2.70118 6.15854 2.70118 5.84195 2.89645 5.64669ZM7.25 6.50024C7.25 6.2241 7.47386 6.00024 7.75 6.00024H13.75C14.0261 6.00024 14.25 6.2241 14.25 6.50024C14.25 6.77639 14.0261 7.00024 13.75 7.00024H7.75C7.47386 7.00024 7.25 6.77639 7.25 6.50024ZM7.25 9.50024C7.25 9.2241 7.47386 9.00024 7.75 9.00024H13.75C14.0261 9.00024 14.25 9.2241 14.25 9.50024C14.25 9.77639 14.0261 10.0002 13.75 10.0002H7.75C7.47386 10.0002 7.25 9.77639 7.25 9.50024ZM2.25 12.5002C2.25 12.2241 2.47386 12.0002 2.75 12.0002H13.75C14.0261 12.0002 14.25 12.2241 14.25 12.5002C14.25 12.7764 14.0261 13.0002 13.75 13.0002H2.75C2.47386 13.0002 2.25 12.7764 2.25 12.5002Z" fill="black"/>
</svg>`
      },
      {
        name: 'center',
        icon: `<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 12.5002C2.25 12.2241 2.47386 12.0002 2.75 12.0002H13.75C14.0261 12.0002 14.25 12.2241 14.25 12.5002C14.25 12.7764 14.0261 13.0002 13.75 13.0002H2.75C2.47386 13.0002 2.25 12.7764 2.25 12.5002Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 9.50024C2.25 9.2241 2.47386 9.00024 2.75 9.00024H13.75C14.0261 9.00024 14.25 9.2241 14.25 9.50024C14.25 9.77639 14.0261 10.0002 13.75 10.0002H2.75C2.47386 10.0002 2.25 9.77639 2.25 9.50024Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 6.50024C2.25 6.2241 2.47386 6.00024 2.75 6.00024H13.75C14.0261 6.00024 14.25 6.2241 14.25 6.50024C14.25 6.77639 14.0261 7.00024 13.75 7.00024H2.75C2.47386 7.00024 2.25 6.77639 2.25 6.50024Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 3.50024C2.25 3.2241 2.47386 3.00024 2.75 3.00024H13.75C14.0261 3.00024 14.25 3.2241 14.25 3.50024C14.25 3.77639 14.0261 4.00024 13.75 4.00024H2.75C2.47386 4.00024 2.25 3.77639 2.25 3.50024Z" fill="black"/>
</svg>
`
      },
      {
        name: 'right',
        icon: `<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 3.50024C2.25 3.2241 2.47386 3.00024 2.75 3.00024H13.75C14.0261 3.00024 14.25 3.2241 14.25 3.50024C14.25 3.77639 14.0261 4.00024 13.75 4.00024H2.75C2.47386 4.00024 2.25 3.77639 2.25 3.50024ZM12.8964 5.64669C13.0917 5.45143 13.4083 5.45143 13.6036 5.64669C13.7988 5.84195 13.7988 6.15854 13.6036 6.3538L11.9571 8.00024L13.6036 9.64669C13.7988 9.84195 13.7988 10.1585 13.6036 10.3538C13.4083 10.5491 13.0917 10.5491 12.8964 10.3538L10.8964 8.3538C10.7012 8.15854 10.7012 7.84195 10.8964 7.64669L12.8964 5.64669ZM2.25 6.50024C2.25 6.2241 2.47386 6.00024 2.75 6.00024H8.75C9.02614 6.00024 9.25 6.2241 9.25 6.50024C9.25 6.77639 9.02614 7.00024 8.75 7.00024H2.75C2.47386 7.00024 2.25 6.77639 2.25 6.50024ZM2.25 9.50024C2.25 9.2241 2.47386 9.00024 2.75 9.00024H8.75C9.02614 9.00024 9.25 9.2241 9.25 9.50024C9.25 9.77639 9.02614 10.0002 8.75 10.0002H2.75C2.47386 10.0002 2.25 9.77639 2.25 9.50024ZM2.25 12.5002C2.25 12.2241 2.47386 12.0002 2.75 12.0002H13.75C14.0261 12.0002 14.25 12.2241 14.25 12.5002C14.25 12.7764 14.0261 13.0002 13.75 13.0002H2.75C2.47386 13.0002 2.25 12.7764 2.25 12.5002Z" fill="black"/>
</svg>
`
      }
    ]

    alignments.forEach((alignment) => {
      const button = document.createElement('button')
      button.classList.add(this.api.styles.settingsButton)
      button.classList.toggle(this.api.styles.settingsButtonActive, this.data.alignment === alignment.name)
      button.innerHTML = alignment.icon
      button.type = 'button'

      button.addEventListener('click', () => {
        this._toggleAlignment(alignment.name)
        this._updateActiveButton(wrapper, button)
      })

      wrapper.appendChild(button)
    })

    return wrapper
  }

  _toggleAlignment(alignment) {
    this.data.alignment = alignment
    this.wrapper.style.textAlign = alignment
  }

  _updateActiveButton(wrapper, activeButton) {
    const buttons = wrapper.querySelectorAll(`.${this.api.styles.settingsButton}`)
    buttons.forEach((button) => {
      button.classList.remove(this.api.styles.settingsButtonActive)
    })
    activeButton.classList.add(this.api.styles.settingsButtonActive)
  }

  _updateUrl(url) {
    this.data.url = url
    const buttonLink = this.wrapper.querySelector('.custom-button__link')
    buttonLink.href = url
    buttonLink.target = '_blank' // Add this line
    buttonLink.rel = 'noopener noreferrer' // Add this line for security
  }

  _validateUrl(url) {
    // Updated URL pattern to enforce http:// or https:// at the beginning
    const urlPattern = /^(https?:\/\/)([\w-]+\.)+[\w-]+(\/[\w@.-]*)*\/?$/
    const urlInput = this.wrapper.querySelector('.custom-button-url-input')

    if (!url) {
      urlInput.setCustomValidity('')
      urlInput.classList.remove('invalid-url')
      return
    }

    if (!urlPattern.test(url)) {
      urlInput.setCustomValidity('Please enter a valid URL starting with http:// or https://')
      urlInput.classList.add('invalid-url')
    } else {
      urlInput.setCustomValidity('')
      urlInput.classList.remove('invalid-url')
    }

    urlInput.reportValidity()
  }

  save(blockContent) {
    const button = blockContent.querySelector('.custom-button__link')
    const urlInput = blockContent.querySelector('.custom-button-url-input')
    return {
      label: button.innerHTML,
      alignment: this.data.alignment,
      url: urlInput.value
    }
  }

  validate(savedData) {
    if (!savedData.label.trim()) {
      return false
    }

    // Update the URL pattern here as well
    const urlPattern = /^(https?:\/\/)([\w-]+\.)+[\w-]+(\/[\w@.-]*)*\/?$/
    return !savedData.url || urlPattern.test(savedData.url)
  }
}

export default CustomButton
